import React, { useEffect, useState } from "react";
import { Row, Col, Typography, Button, List, Form, InputNumber, Tooltip, Modal, notification } from "antd";
import { EditOutlined, DeleteOutlined, CloseOutlined, SaveOutlined, ArrowRightOutlined, ExclamationCircleOutlined } from "@ant-design/icons";

import { useStateValue } from "../../state";
import { manutencaoActions } from "../../actions";
import { FormatNumber } from "../../ValueObjects";
import { FormGW, InputPreco } from "../../components";
import { removeDadosNota } from "../../services/auth";
import { arredondar, isObjetoDiffVazio } from "../../services/funcoes";
import { Link, useNavigate } from "react-router-dom";

export default function TabelaItem({ itensSelecionados, setItensSelecionados, dadosCliente, dadosVendedor, serieFiscal }) {

    const [{ manutencao, dadosPdv }, dispatch] = useStateValue();
    const [form] = Form.useForm();
    const [valoresTotais, setValoresTotais] = useState({});
    const [editarItem, setEditarItem] = useState(null);
    const [descontoItem, setDescontoItem] = useState(false);
    const [acrescimoItem, setAcrescimoItem] = useState(false);
    const [listaOrdenada, setListaOrdenada] = useState([]);
    const navigation = useNavigate();
    
    const handleKeyPress = (event) => {
        if (event.key === 'PageDown') {
            verificaDados();
        }
    } 

    useEffect(() => {
        window.addEventListener('keydown', handleKeyPress);
        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        }
    }, [itensSelecionados, dadosCliente, dadosVendedor, valoresTotais])

    useEffect(() => {
        let valorTot = 0, valorTotSub = 0, valorTotalDesc = 0, valorTotalAcre = 0, totalDescontoItens = 0, totalAcescimoItens = 0, key = 0;
        itensSelecionados.forEach((item) => {
            item.key = key++;
            valorTot = arredondar(valorTot) + arredondar(item.valorTotalItem);
            valorTotSub = arredondar(valorTotSub) + arredondar(item.subTotalItem);
            totalAcescimoItens += (!!item.valorAcrescimo ? arredondar(item.valorAcrescimo) : !!item.nfi_valoroutros ? item.nfi_valoroutros : 0);
            totalDescontoItens += (!!item.valorDesconto ? arredondar(item.valorDesconto) : !!item.nfi_valordesconto ? item.nfi_valordesconto : 0);
            valorTotalDesc += (!!item.valorDesconto ? parseFloat(item.valorDesconto) : !!item.percentualDesconto ? parseFloat(((item.nfi_valorunitario * item.percentualDesconto) / 100).toFixed(2)) : 0);
            valorTotalAcre += !!item.valorAcrescimo ? parseFloat(item.valorAcrescimo) : !!item.percentualAcrescimo ? parseFloat((item.nfi_valorunitario * item.percentualAcrescimo) / 100).toFixed(2) : 0;
        })
        let totais = {
            valorTotal: arredondar(valorTot),
            valorTotalSub: arredondar(valorTotSub),
            valorTotalDesconto: arredondar(valorTotalDesc),
            valorTotalAcrescimo: arredondar(valorTotalAcre),
            totalDescItens: arredondar(totalDescontoItens),
            totalAcresItens: arredondar(totalAcescimoItens)
        };
        setValoresTotais(totais)
        setListaOrdenada([...itensSelecionados].sort((a, b) => b.nItem - a.nItem))
    }, [itensSelecionados])

    function editar(item) {
        let listaTemp = [...itensSelecionados];
        let itemTemp = { ...item };
        itemTemp.editandoItem = !itemTemp.editandoItem;
        listaTemp[item.key] = itemTemp;
        setItensSelecionados(listaTemp);
        if (itemTemp.editandoItem) {
            form.setFieldsValue({ [`pro_descricao${item.key}`]: item.pro_descricao, [`quantidade${item.key}`]: item.quantidade, [`pro_ean${item.key}`]: item.pro_ean, [`valorAcrescimo${item.key}`]: item.nfi_valoroutros , [`percentualAcrescimo${item.key}`]: item.percentualAcrescimo  },);
            form.setFieldsValue({ [`percentualDesconto${item.key}`]: item.percentualDesconto, [`valorDesconto${item.key}`]: item.valorDesconto, [`valorTotal${item.key}`]: item.valorTotalItem, [`valorUnitario${item.key}`]: item.valorUnitario, [`valorTotalSub${item.key}`]: item.subTotalItem });
        }
    };

    function removerItens(campo) {
        let msg = (campo === 'CANCELAR') ? 'Deseja cancelar a venda?' : 'Deseja remover todos os itens?';
        Modal.confirm({
            title: msg,
            icon: <ExclamationCircleOutlined />,
            okText: 'Remover',
            cancelText: 'Cancelar',
            okType: 'danger',
            centered: true,
            onOk() {
                setItensSelecionados([]);
                removeDadosNota();
                window.produtoVenda = [];
            }
        })
    }

    function excluirItem(record) {
        let listaTemp = [...itensSelecionados];
        listaTemp = listaTemp.filter((pro) => (pro.nItem !== record.nItem));
        setItensSelecionados(listaTemp);
    }

    function verificaDados() {
        if (!!itensSelecionados && itensSelecionados.length > 0) {
            itensSelecionados.forEach((item) => {
                item.editandoItem = false;
            })
            dispatch({ type: manutencaoActions.CHANGE, data: { dados: { cliente: dadosCliente, vendedor: dadosVendedor, itens: itensSelecionados, totais: valoresTotais } } })
            navigation("../finalizarVenda");
        } else {
            notification.warning({ message: 'Aviso!', description: 'Nenhum item foi selecionado!' });
        }
    }

    function calculaDesconto(campo, item) {
        let percentualVendedor = !!manutencao.dados && !!manutencao.dados.vendedorDados && !!manutencao.dados.vendedorDados.ven_maximodesconto ? manutencao.dados.vendedorDados.ven_maximodesconto : null;
        let valor = !!form.getFieldValue(`valorDesconto${item}`) ? form.getFieldValue(`valorDesconto${item}`) : 0;
        let perc = !!form.getFieldValue(`percentualDesconto${item}`) ? form.getFieldValue(`percentualDesconto${item}`) : 0;
        let tot = form.getFieldValue(`valorTotalSub${item}`);
        let result = 0;
        if (!!campo) {
            if (campo === 'PERC') {
                result = (tot * perc) / 100;
                if (percentualVendedor != null) {
                    if (perc > percentualVendedor) {
                        notification.warning({ message: 'Aviso!', description: 'Desconto maior do que o permitido ao vendedor!' });
                        form.setFieldsValue({ [`valorDesconto${item}`]: 0 });
                        form.setFieldsValue({ [`percentualDesconto${item}`]: 0 });
                        form.setFieldsValue({ [`valorTotal${item}`]: tot });
                        return false
                    }
                    form.setFieldsValue({ [`valorDesconto${item}`]: result.toFixed(2) });
                }
                form.setFieldsValue({ [`valorDesconto${item}`]: result.toFixed(2) });
            } else if (campo === 'ATT') {
                result = (tot * perc) / 100;
                form.setFieldsValue({ [`valorDesconto${item}`]: result.toFixed(2) });
                valor = (!!form.getFieldValue(`valorDesconto${item}`) ? form.getFieldValue(`valorDesconto${item}`) : 0)
                result = (!!tot > 0 ? (valor * 100) / tot : 0);
                form.setFieldsValue({ [`percentualDesconto${item}`]: result.toFixed(2) });
            } else {
                if (valor > tot) {
                    notification.warning({ message: 'Aviso!', description: 'Valor de desconto maior do que valor dos itens!' });
                    form.setFieldsValue({ [`valorDesconto${item}`]: tot });
                } else {
                    result = (valor * 100) / tot;
                    if (percentualVendedor != null) {
                        if (result > percentualVendedor) {
                            notification.warning({ message: 'Aviso!', description: 'Desconto maior do que o permitido ao vendedor!' });
                            form.setFieldsValue({ [`valorDesconto${item}`]: 0 });
                            form.setFieldsValue({ [`percentualDesconto${item}`]: 0 });
                            form.setFieldsValue({ [`valorTotal${item}`]: tot });
                            return false;
                        }
                        form.setFieldsValue({ [`percentualDesconto${item}`]: result.toFixed(2) });
                    } else {
                        form.setFieldsValue({ [`percentualDesconto${item}`]: result.toFixed(2) });
                    }
                }
            }
            calculaTotalItemDescAcr(item);
        } else {
            form.setFieldsValue({ [`valorDesconto${item}`]: 0 });
            form.setFieldsValue({ [`percentualDesconto${item}`]: 0 });
            calculaTotalItemDescAcr(item);
        }
    }

    function calculaAcrescimo(campo, key) {
        let valor = (!!form.getFieldValue(`valorAcrescimo${key}`) ? parseFloat(form.getFieldValue(`valorAcrescimo${key}`)) : 0);
        let perc = (!!form.getFieldValue(`percentualAcrescimo${key}`) ? form.getFieldValue(`percentualAcrescimo${key}`) : 0);
        let tot = (!!form.getFieldValue(`valorTotalSub${key}`) ? parseFloat(form.getFieldValue(`valorTotalSub${key}`)) : 0);
        let result = 0;
        if (!!campo) {
            if (campo === 'PERC') {
                result = (tot * perc) / 100;
                form.setFieldsValue({ [`valorAcrescimo${key}`]: result.toFixed(2) });
            } else if (campo === 'ATT') {
                result = (tot * perc) / 100;
                form.setFieldsValue({ [`valorAcrescimo${key}`]: result.toFixed(2) });
                valor = !!form.getFieldValue(`valorAcrescimo${key}`) ? form.getFieldValue(`valorAcrescimo${key}`) : 0;
                result = (tot > 0 ? (valor * 100) / tot : 0);
                form.setFieldsValue({ [`percentualAcrescimo${key}`]: result.toFixed(2) });
            } else {
                result = (valor * 100) / tot;
                form.setFieldsValue({ [`percentualAcrescimo${key}`]: result.toFixed(2) });
            }
            calculaTotalItemDescAcr(key);
        }
    }

    function calculaTotalItemDescAcr(key) {
        let valoresTemp = {};
        valoresTemp.valorTotalSub = !!form.getFieldValue(`valorTotalSub${key}`) ? form.getFieldValue(`valorTotalSub${key}`) : 0;
        valoresTemp.valorAcrescimo = !!form.getFieldValue(`valorAcrescimo${key}`) ? form.getFieldValue(`valorAcrescimo${key}`) : 0;
        valoresTemp.valorDesconto = !!form.getFieldValue(`valorDesconto${key}`) ? form.getFieldValue(`valorDesconto${key}`) : 0;
        valoresTemp.valorTotal = parseFloat(valoresTemp.valorTotalSub) + parseFloat(valoresTemp.valorAcrescimo) - parseFloat(valoresTemp.valorDesconto);
        form.setFieldsValue({ [`valorTotal${key}`]: parseFloat(valoresTemp.valorTotal).toFixed(2), [`pfp_valor${key}`]: parseFloat(valoresTemp.valorTotal).toFixed(2) });
    }

    function salvarItem(item) {
        let listaTemp = [...itensSelecionados];
        let itemTemp = { ...item };
        itemTemp.editandoItem = !itemTemp.editandoItem;
        itemTemp.nfi_valoracrescimo = parseFloat(form.getFieldValue(`valorAcrescimo${item.key}`)).toFixed(2);
        itemTemp.valorAcrescimo = parseFloat(form.getFieldValue(`valorAcrescimo${item.key}`)).toFixed(2);
        itemTemp.nfi_valordesconto = parseFloat(form.getFieldValue(`valorDesconto${item.key}`)).toFixed(2);
        itemTemp.percentualDesconto = parseFloat(form.getFieldValue(`percentualDesconto${item.key}`)).toFixed(2);
        itemTemp.valorDesconto = parseFloat(form.getFieldValue(`valorDesconto${item.key}`)).toFixed(2);
        itemTemp.subTotalItem = parseFloat(form.getFieldValue(`valorTotalSub${item.key}`)).toFixed(2);
        itemTemp.quantidade = form.getFieldValue(`quantidade${item.key}`);
        itemTemp.valorUnitario = parseFloat(form.getFieldValue(`valorUnitario${item.key}`)).toFixed(2);
        itemTemp.valorTotalItem = parseFloat(form.getFieldValue(`valorTotal${item.key}`)).toFixed(2);
        itemTemp.lpi_valorvenda = parseFloat(form.getFieldValue(`valorUnitario${item.key}`)).toFixed(2);
        listaTemp[item.key] = itemTemp;
        setItensSelecionados(listaTemp);
        setEditarItem(!editarItem);
    }

    function alteraQuantidade(key) {
        let listaTemp = [...itensSelecionados];
        let itemTemp = listaTemp[key];
        itemTemp.quantidade = form.getFieldValue(`quantidade${key}`)
        itemTemp.nfi_qtde = form.getFieldValue(`quantidade${key}`)
        itemTemp.valorTotalItem = itemTemp.quantidade * form.getFieldValue(`valorUnitario${key}`);
        itemTemp.valorTotalSub = itemTemp.quantidade * form.getFieldValue(`valorUnitario${key}`);
        itemTemp.nfi_valorunitario = arredondar(form.getFieldValue(`valorUnitario${key}`));
        itemTemp.valorUnitario = arredondar(form.getFieldValue(`valorUnitario${key}`));
        listaTemp[key] = itemTemp;
        setItensSelecionados(listaTemp);
        form.setFieldsValue({ [`valorTotal${key}`]: itemTemp.valorTotalItem, [`valorTotalSub${key}`]: itemTemp.valorTotalSub });
    }

    return (
        <div className="lista-itens hv-Custom">
            <div className="col-secondary">
                <Row align="middle" gutter={[8, 0]}>
                    <Col xs={15} sm={15} md={16} lg={16} xl={17}>
                        <Row align="middle" gutter={[8, 0]}>
                            <Col span={24} className="over-text">
                                Nº Cupom: <b>{serieFiscal?.ser_ultimonumemitido + 1 || dadosPdv.pdv?.pdv_nroultimadav}</b>
                            </Col>
                            <Col span={24}>
                                <Typography.Title level={3} className="over-text">
                                    ITENS ADICIONADOS
                                </Typography.Title>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={9} sm={9} md={8} lg={8} xl={7} className="text-right">
                        <Row align="middle" gutter={[8, 0]}>
                            <Col xs={16} sm={16} md={16} lg={16} xl={18}>
                                <Typography.Title level={3} className="over-text">
                                    {itensSelecionados.length} <br />
                                    <small>Item</small>
                                </Typography.Title>
                            </Col>
                            <Col xs={8} sm={8} md={8} lg={8} xl={6}>
                                <Tooltip title="Excluir todos os Itens" placement="left">
                                    <Button onClick={() => removerItens('EXCLUIR')} type="text" block icon={<DeleteOutlined />} size="large" className="btn f-35" />
                                </Tooltip>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
            <List itemLayout="vertical"
                dataSource={listaOrdenada}
                renderItem={(item) => (
                    <List.Item key={item.nItem}>
                        <Row align="middle" gutter={[8, 16]} className="w-100">
                            <Col span={24}>
                                <Row align="middle" gutter={[8, 0]}>
                                    <Col xs={20} sm={19} md={20} lg={20} xl={21} xxl={22}>
                                        <Row align="middle" gutter={[8, 0]}>
                                            <Col xs={4} sm={4} md={4} lg={4} xl={3}>
                                                <img src={require("../../assets/img-image.png")} className="image" />
                                            </Col>
                                            <Col xs={16} sm={16} md={16} lg={16} xl={16}>
                                                <Row>
                                                    <Col span={24} className="over-text">
                                                        {item.quantidade} x {item.nfi_ean ? item.nfi_ean : item.pro_codigo}
                                                    </Col>
                                                    <Col span={24} className="over-text">
                                                        <b>{item.pro_descricao}</b>
                                                    </Col>
                                                    <Col span={24} className="over-text">
                                                        <b>{item.grade}</b>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xs={5} sm={5} md={5} lg={5} xl={5}>
                                                {!!item.valorDesconto && (parseFloat(item.valorDesconto) !== 0) &&
                                                    <Col className="over-text text-right f-16 c-red">
                                                        <s><b>{FormatNumber(item.subTotalItem, true)}</b></s>
                                                    </Col>
                                                }
                                                <Col className="over-text text-right f-16">
                                                    <b>{FormatNumber(item.valorTotalItem, true)}</b>
                                                </Col>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={4} sm={5} md={4} lg={4} xl={3} xxl={2}>
                                        <Row justify="center" gutter={[8, 0]}>
                                            <Col>
                                                <Tooltip title={!!editarItem ? 'Fechar Edição' : 'Editar Item'} placement="top">
                                                    <Button onClick={() => { setEditarItem(!editarItem); editar(item) }} type="text" block className="btn f-19">
                                                        {!!item.editandoItem ? <CloseOutlined /> : <EditOutlined />}
                                                    </Button>
                                                </Tooltip>
                                            </Col>
                                            <Col>
                                                <Tooltip title="Remover este Item" placement="left">
                                                    <Button type="text" block icon={<DeleteOutlined />} className="btn f-19" onClick={() => excluirItem(item)} />
                                                </Tooltip>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        {item.editandoItem &&
                            <FormGW name={`formValores`} form={form} >
                                <Row align="middle" gutter={[8, 8]} className="w-100">
                                    <Col span={24}>
                                        <Row gutter={[8, 0]}>
                                            <Col xs={6} sm={6} md={6} lg={6} xl={5} xxl={4}>
                                                <Form.Item name={`quantidade${item.key}`}>
                                                    <InputNumber controls={false} size="large" min={0} onChange={() => alteraQuantidade(item.key)} />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={18} sm={18} md={18} lg={18} xl={19} xxl={20}>
                                                <Row justify="start">
                                                    <Col span={24} className="over-text">
                                                        <Form.Item name={`pro_ean${item.key}`} hidden />
                                                        <small className="f-11">{item.pro_ean}</small>
                                                    </Col>
                                                    <Col span={24} className="over-text">
                                                        <Form.Item name={`pro_descricao${item.key}`} hidden />
                                                        <small className="f-11">{item.pro_descricao}</small>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row align="middle" gutter={[8, 0]}>
                                            <Col xs={21} sm={21} md={21} lg={22} xl={22} className="text-right">
                                                <Row justify="end" gutter={[8, 0]}>
                                                    <Col xs={24} sm={12} md={16} lg={16} xl={18} xxl={20}>
                                                        Valor Unitário (R$):
                                                    </Col>
                                                    <Col xs={24} sm={12} md={8} lg={8} xl={6} xxl={4}>
                                                        <InputPreco name={`valorUnitario${item.key}`} onBlur={() => alteraQuantidade(item.key)} />
                                                    </Col>
                                                </Row>
                                                <Row justify="end" gutter={[8, 0]}>
                                                    <Col xs={24} sm={12} md={16} lg={16} xl={18} xxl={20}>
                                                        Subtotal (R$):
                                                    </Col>
                                                    <Col xs={24} sm={12} md={8} lg={8} xl={6} xxl={4}>
                                                        <InputPreco name={`valorTotalSub${item.key}`} disabled />
                                                    </Col>
                                                </Row>
                                                <Row justify="end" gutter={[5, 0]}>
                                                    <Col xs={24} sm={18} md={16} lg={16} xl={18} xxl={20}>
                                                        <Form.Item>
                                                            <Row justify="end" align="middle" gutter={[5, 0]}>
                                                                <Col>
                                                                    Desconto em:
                                                                </Col>
                                                                <Col>
                                                                    <Button className={!descontoItem ? 'ant-btn-primary' : ''} onClick={() => setDescontoItem(false)}>
                                                                        R$
                                                                    </Button>
                                                                    <Button className={descontoItem ? 'ant-btn-primary' : ''} onClick={() => setDescontoItem(true)}>
                                                                        %
                                                                    </Button>
                                                                </Col>
                                                            </Row>
                                                        </Form.Item>
                                                    </Col>
                                                    {!descontoItem &&
                                                        <Col xs={24} sm={6} md={8} lg={8} xl={6} xxl={4}>
                                                            <InputPreco disabled={!isObjetoDiffVazio(dadosVendedor)} name={`valorDesconto${item.key}`} onBlur={() => calculaDesconto('VALOR', item.key)} />
                                                        </Col>}
                                                    {descontoItem &&
                                                        <Col xs={24} sm={6} md={8} lg={8} xl={6} xxl={4}>
                                                            <Form.Item name={`percentualDesconto${item.key}`}>
                                                                <InputNumber
                                                                    min={0}
                                                                    max={100}
                                                                    controls={false}
                                                                    defaultValue={0}
                                                                    formatter={value => `${value}%`}
                                                                    parser={value => value.replace('%', '')}
                                                                    disabled={!isObjetoDiffVazio(dadosVendedor)}
                                                                    onBlur={() => calculaDesconto('PERC', item.key)}
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                    }
                                                </Row>
                                                <Row justify="end" gutter={[5, 0]}>
                                                    <Col xs={24} sm={18} md={16} lg={16} xl={18} xxl={20}>
                                                        <Form.Item>
                                                            <Row justify="end" align="middle" gutter={[5, 0]}>
                                                                <Col>
                                                                    Acréscimo em:
                                                                </Col>
                                                                <Col>
                                                                    <Button className={!acrescimoItem ? 'ant-btn-primary' : ''} onClick={() => setAcrescimoItem(false)}>
                                                                        R$
                                                                    </Button>
                                                                    <Button className={acrescimoItem ? 'ant-btn-primary' : ''} onClick={() => setAcrescimoItem(true)}>
                                                                        %
                                                                    </Button>
                                                                </Col>
                                                            </Row>
                                                        </Form.Item>
                                                    </Col>
                                                    {!!acrescimoItem === false &&
                                                        <Col xs={24} sm={6} md={8} lg={8} xl={6} xxl={4}>
                                                            <InputPreco name={`valorAcrescimo${item.key}`} onBlur={() => calculaAcrescimo('VALOR', item.key)} />
                                                        </Col>
                                                    }
                                                    {!!acrescimoItem === true &&
                                                        <Col xs={24} sm={6} md={8} lg={8} xl={6} xxl={4}>
                                                            <Form.Item name={`percentualAcrescimo${item.key}`}>
                                                                <InputNumber
                                                                    min={0}
                                                                    max={100}
                                                                    controls={false}
                                                                    defaultValue={0}
                                                                    formatter={value => `${value}%`}
                                                                    parser={value => value.replace('%', '')}
                                                                    onBlur={() => calculaAcrescimo('PERC', item.key)} />

                                                            </Form.Item>
                                                        </Col>
                                                    }
                                                </Row>
                                                <Row justify="end" gutter={[8, 0]}>
                                                    <Col xs={24} sm={12} md={16} lg={16} xl={18} xxl={20}>
                                                        Valor Total (R$):
                                                    </Col>
                                                    <Col xs={24} sm={12} md={8} lg={8} xl={6} xxl={4}>
                                                        <InputPreco name={`valorTotal${item.key}`} disabled />
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xs={3} sm={3} md={3} lg={2} xl={2} className="text-right">
                                                <Tooltip title="Salvar Modificações deste Item" placement="left">
                                                    <Button type="text" block icon={<SaveOutlined />} size="large" className="btn f-19" onClick={() => salvarItem(item)} />
                                                </Tooltip>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </FormGW>
                        }
                    </List.Item>
                )} />
            <div className="footerItems">
                < div className="col-info p-10" >
                    <Row align="middle" gutter={[16, 0]}>
                        <Col span={12}>
                            <Row align="middle">
                                <Col span={24} className="over-text">
                                    SubTotal (R$): <b className="f-18"> {FormatNumber(valoresTotais.valorTotalSub, true)} </b>
                                </Col>
                                <Col span={24} className="over-text">
                                    Descontos (R$): <b className="f-18">{FormatNumber(valoresTotais.valorTotalDesconto, true)} </b>
                                </Col>
                                <Col span={24} className="over-text">
                                    Acréscimos (R$): <b className="f-18">{FormatNumber(valoresTotais.valorTotalAcrescimo, true)} </b>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={12}>
                            <Row align="middle" className="text-right">
                                <Col span={24}>
                                    Total a Pagar (R$):
                                </Col>
                                <Col span={24} className="over-text">
                                    <b className="f-32"> {FormatNumber(valoresTotais.valorTotal, true)} </b>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
                <div className="col-info-pag">
                    <Row align="middle" justify="space-between">
                        <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                            <Button type="text" size="large" onClick={() => removerItens('CANCELAR')}>
                                <b><CloseOutlined /> CANCELAR VENDA</b>
                            </Button>
                        </Col>
                        <Col sm={24} md={24} lg={24} xl={12} xxl={12} className="text-right">
                            <Button type="text" size="large" onClick={() => verificaDados()}>
                                <span style={{fontSize: '10px'}}>(PageDown)</span>&nbsp;<b>FINALIZAR VENDA <ArrowRightOutlined /></b>
                            </Button>
                            {/* <Link to="../finalizarVenda">
                                <Button type="text" size="large" onClick={() => verificaDados()}>
                                    <b>FINALIZAR VENDA <ArrowRightOutlined /></b>
                                </Button>
                            </Link> */}
                        </Col>
                    </Row>
                </div>
            </div>
        </div >
    );
}