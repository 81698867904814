import React, { useEffect, useRef, useState } from "react";
import { Col, Form, Row, Button, Table, InputNumber, Select, notification, Tag, Modal, Descriptions, Input } from "antd";
import { CheckOutlined, DeleteOutlined, EditOutlined, ExclamationCircleOutlined, PlusOutlined } from "@ant-design/icons";
import moment from 'moment';

import api from "../../services/api";
import { useStateValue } from "../../state";
import { FormatNumber } from "../../ValueObjects";
import { dadosPdvActions, listaSelectPaginadoActions, manutencaoActions } from "../../actions";
import { Data, InputPreco, SelectPaginacao, SelectPaginado } from "../../components";
import { arredondar, carregarDadosPdv, isObjetoDiffVazio, validarNumeros } from "../../services/funcoes";
import { ModalBandeiraCartao, ModalContaCorrente, ModalParcelasPendentes, ModalCheque, ModalAntecipacao, ModalSenhaGerencial, } from "../../components/modals";
import { removeDadosNota } from "../../services/auth";

export default function FormasPagamento({ totais, dadosCliente, setListaPagamentos, formulario, pagamentoTotal, setPagamentoTotal, abreModalAntecipacao,
    setAbreModalAntecipacao, dadosAntecipacao, setDadosAntecipacao, setPossuiAntecipacao, cliente }) {

    const [{ manutencao, dadosPdv }, dispatch] = useStateValue();
    const [listaFormaPagamento, setListaFormaPagamento] = useState(!!manutencao.dados && !!manutencao.dados.faturamento && manutencao.dados.faturamento.length > 0 ? manutencao.dados.faturamento : [])
    const [condicaoPagamento, setCondicaoPagamento] = useState([]);
    const [valorJaPago, setValorJaPago] = useState(false);
    const [editando, setEditando] = useState(false);
    const [semCondicao, setSemCondicao] = useState(false);
    const [ehCheque, setEhCheque] = useState(false);
    const [ehValePresente, setEhValePresente] = useState(false);
    const [openModalCartao, setOpenModalCartao] = useState(false);
    const [dadosParcelas, setDadosParcelas] = useState([]);
    const [abreModalLiberacao, setAbreModalLiberacao] = useState(false);
    const [abreModalParcPendentes, setAbreModalParcPendentes] = useState(false);
    const [parcelasPessoasAtraso, setParcelasPessoasAtraso] = useState([]);
    const [executarParcelas, setExecutarParcelas] = useState(false);
    const [formaPagamentoAntecipacao, setFormaPagamentoAntecipacao] = useState({});
    const [exibirModalCheque, setExibirModalCheque] = useState(false);
    const [abreModalContaCorrente, setAbreModalContaCorrente] = useState(false);
    const [contaCorrente, setContaCorrente] = useState({});
    const [listaContaCorrente, setListaContaCorrente] = useState([]);
    const [tipoContaCorrente, setTipoContaCorrente] = useState("1");
    const [dadosCheque, setDadosCheque] = useState([]);
    const [valorRestante, setValorRestante] = useState(0);
    const [editandoParcelas, setEditandoParcelas] = useState(false);
    const [renderizar, setRenderizar] = useState(false);
    const [selectOpen, setSelectOpen] = useState(false);
    const [infoCheque, setInfoCheque] = useState([]);
    const adicionarButtonRef = useRef(null);

    const pagamentosCodigos = {
        dinheiro: 1,
        cheque: 2,
        cartaoCredito: 3,
        cartaoDebito: 4,
        crediarioLoja: 5,
        valeAlimentacao: 10,
        valeRefeicao: 11,
        valePresente: 12,
        valeCombustivel: 13,
        duplicataMercantil: 14,
        boleto: 15,
        depositoBancario: 16,
        pixDinamico: 17,
        transfBancaria: 18,
        programaFidelidade: 19,
        pixEstatico: 20,
        creditoEmLoja: 21,
        falhaDeHardware: 22,
        semPagamento: 90,
        outro: 99
    };
    function fecharModalOK() {
        setOpenModalCartao(false);
    };

    useEffect(() => {
        var _dadosNotaF5 = localStorage.getItem("@GestorWeb-DadosNota");
        if (_dadosNotaF5 != null && _dadosNotaF5 != 'null') {
            verificaPdv(() => setarDadosF5(_dadosNotaF5));
        }
    }, [])

    function setarDadosF5(_dadosNotaF5) {
        //var decodado = decodeBase64(_dadosNotaF5);
        var _json = JSON.parse(_dadosNotaF5);
        setListaFormaPagamento(_json?.notaFiscalFaturamento ?? (_json?.faturamento ?? []));
    }

    const fecharModalCartao = () => {
        let dados = [...listaFormaPagamento];
        let index = listaFormaPagamento.length - 1;
        removerFormaPagamento(dados[index]);
        setOpenModalCartao(false);
    };

    function onCloseModalContaCorrente() {
        let ultimaForma = listaFormaPagamento[listaFormaPagamento.length - 1];
        ultimaForma.ctc_id = contaCorrente.ctc_id;
        ultimaForma.ctc_descricao = contaCorrente.ctc_descricao;
        setListaFormaPagamento(listaFormaPagamento);
        setAbreModalContaCorrente(false);
    }

    useEffect(() => {
        valorRestantePgto([...listaFormaPagamento]);
    }, [totais])

    useEffect(() => {
        let valorPago = 0;
        if (listaFormaPagamento.length === 0) {
            if (isObjetoDiffVazio(totais)) {
                totais.restaPagar = 0;
            }
        }
        listaFormaPagamento.forEach((lista) => {
            valorPago += parseFloat(lista.pfp_valor);
        });
        if (!!formulario.getFieldValue().fpg_id) {
            listarCondicao(formulario.getFieldValue().fpg_id, true);
        }
        valorRestantePgto([...listaFormaPagamento]);
        setExecutarParcelas(true);
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: { ...manutencao.dados, ...{ faturamento: listaFormaPagamento } } } });
        if (!!manutencao.dados && !!manutencao.dados.totais) {
            if (valorPago === parseFloat(manutencao.dados.totais.valorTotal)) { //|| valorRestante === 0) {
                setPagamentoTotal(true);
            } else {
                setPagamentoTotal(false);
            }
            let dadosTemp = manutencao.dados.totais;
            dadosTemp.valorPago = valorPago;
            dispatch({ type: manutencaoActions, data: { dados: { ...manutencao.dados, ... { totais: dadosTemp } } } });
        }
        if (!!listaFormaPagamento[listaFormaPagamento.length - 1]
            && (listaFormaPagamento[listaFormaPagamento.length - 1].fpg_tipopagamento === pagamentosCodigos.depositoBancario
                || listaFormaPagamento[listaFormaPagamento.length - 1].fpg_tipopagamento === pagamentosCodigos.transfBancaria
                || listaFormaPagamento[listaFormaPagamento.length - 1].fpg_tipopagamento === pagamentosCodigos.pixDinamico
                || listaFormaPagamento[listaFormaPagamento.length - 1].fpg_tipopagamento === pagamentosCodigos.pixEstatico
            )
            && !listaFormaPagamento[listaFormaPagamento.length - 1].ctc_id
        ) {
            setTipoContaCorrente("2");
            setAbreModalContaCorrente(true);
        }
        if (!!listaFormaPagamento[listaFormaPagamento.length - 1]
            && !!!dadosPdv?.pdv?.ctc_id
            && (listaFormaPagamento[listaFormaPagamento.length - 1].fpg_tipopagamento === pagamentosCodigos.cheque
                || listaFormaPagamento[listaFormaPagamento.length - 1].fpg_tipopagamento === pagamentosCodigos.dinheiro)
            && !listaFormaPagamento[listaFormaPagamento.length - 1].ctc_id
        ) {
            setTipoContaCorrente("1");
            setAbreModalContaCorrente(true);
        }
        setEhValePresente(false);
        setValorJaPago(valorPago);
        setListaPagamentos(listaFormaPagamento);
    }, [listaFormaPagamento]);

    useEffect(() => {
        if (infoCheque.length > 0) {
            listaFormaPagamento[0].cheques = infoCheque;
            dispatch({ type: manutencaoActions.CHANGE, data: { dados: { ...manutencao.dados, ...{ faturamento: listaFormaPagamento } } } });
        }
    }, [infoCheque])

    useEffect(() => {
        if (parseFloat(valorRestante) > 0 && !!manutencao.dados && !!manutencao.dados.cliente && isObjetoDiffVazio(manutencao.dados.cliente) && (!!!manutencao.dados.antecipacao && !!dadosAntecipacao && dadosAntecipacao.length === 0)) {
            verificarAntecipacoes();
            setPagamentoTotal(false);
        }
    }, [valorRestante, cliente]);

    useEffect(() => {
        if (!!manutencao.dados && !!manutencao.dados.faturamento && manutencao.dados.faturamento.length > 0 && executarParcelas) {
            let dados = manutencao.dados.faturamento;
            let index = dados.length - 1;
            setExecutarParcelas(false);
            if (dados[index].fpg_tipopagamento === pagamentosCodigos.crediarioLoja) {
                validarJaPossuiCrediario(() => {
                    popularParcelas();
                });
            } else if ((((dados[index].fpg_tipopagamento === pagamentosCodigos.cartaoCredito && dadosParcelas.length === 0)
                || dados[index].fpg_tipopagamento === pagamentosCodigos.cartaoDebito)) && !openModalCartao) {
                abrirModalCartao(dados[index]);
            } else if (dados[index].fpg_tipopagamento === pagamentosCodigos.cheque) {
                abrirModalCheque(dados[index]);
            }
        } else if (!!manutencao.dados && !!manutencao.dados.antecipacao) {
            retornaFormaPagamentoAntecipacao();
        }
    }, [manutencao.dados]);

    useEffect(() => {
        if (!!formulario.getFieldValue().fpg_id) {
            let dadosFormaPagamento = carregarDadosFormaPgto(formulario.getFieldValue().fpg_id);
            dispatch({ type: manutencaoActions.CHANGE, data: { dados: { ...manutencao.dados, ...{ formaPagamento: dadosFormaPagamento } } } });
        }
    }, [formulario.getFieldValue().fpg_id]);

    useEffect(() => {
        if (isObjetoDiffVazio(formaPagamentoAntecipacao)) {
            adicionarFormaPagamento(false, true);
        }
    }, [formaPagamentoAntecipacao]);

    useEffect(() => {
        setRenderizar(true);
    }, [editandoParcelas]);

    useEffect(() => {
        if (renderizar) {
            setRenderizar(false);
        }
    }, [renderizar]);

    useEffect(() => {
        if (!!!dadosPdv.pdv) {
            verificaPdv();
        }
    }, [])

    async function verificaPdv(callback) {
        let res = await carregarDadosPdv();
        dispatch({ type: dadosPdvActions.CHANGE, data: { pdv: res } });
        if (!!callback) {
            callback();
        }
    }

    const formaPgtoAntecipacao = {
        fpg_id: 10758,
        ctc_descricao: null,
        ctc_id: null,
        dbl_extensaoarquivoremessa: null,
        dbl_nomearquivoremessa: null,
        dbl_padraoarquivoremessa: null,
        fpg_ativo: true,
        fpg_descontopadrao: null,
        fpg_descricao: "ANTECIPACAO",
        fpg_emiteboleto: false,
        fpg_imprimecarne: false,
        fpg_padraosistema: true,
        fpg_principal: true,
        parcelas: [],
        cheques: [],
        antecipacoes: !!manutencao.dados && !!manutencao.dados.antecipacao ? manutencao.dados.antecipacao : [],
        fpg_tipopagamento: pagamentosCodigos.antecipacao,//A prazo
    };

    function onCloseModalLiberacao(condicao) {
        setAbreModalLiberacao(false);
        if (condicao) {
            setarComCondicao();
        } else {
            formulario.setFieldsValue({ fpg_id: undefined });
        }
    };

    function onCloseModalParcPendentes() {
        setAbreModalParcPendentes(false);
    };

    function onCloseModalParcPendentesOk() {
        setAbreModalParcPendentes(false);
        adicionarFormaPagamento(true);
    }

    function onCloseModalCheque() {
        setExibirModalCheque(false);
        setListaFormaPagamento([]);
    };

    async function carregarDadosFormaPgto(id) {
        if (!!id) {
            let forma = (await api.get(`FormaPagamento/Get?id=${id}`)).data;
            return forma;
        }
        return null;
    }

    function valorRestantePgto(dadosTemp) {
        let restante = 0;
        let valorPago = 0;
        dadosTemp.forEach((dados) => {
            valorPago += parseFloat(dados.pfp_valor);
        });
        restante = parseFloat(totais.valorTotal) - valorPago;
        if (restante < 0) {
            restante = 0;
        }
        formulario.setFieldsValue({ pfp_valor: restante.toFixed(2) });
        setValorRestante(parseFloat(restante.toFixed(2)));
    };

    function listarCondicao(fpg_id, dadosIniciais = false) {
        if (!dadosIniciais) {
            formulario.setFieldsValue({ cpg_id: null });
        }
        if (!!fpg_id) {
            api.get(`CondicaoPagamento/Listar?Ativo=true&codigoFormaPagamento=${fpg_id}&PageSize=500`).then(
                (res) => {
                    if (res.status === 200) {
                        setCondicaoPagamento(res.data.items);
                        if (res.data.items.length > 0) {
                            setarComCondicao();
                        } else {
                            setarValor();
                        }
                    }
                }
            ).catch(
                (erro) => {
                    console.log(erro);
                }
            );
        }
    };

    function retornaFormaPagamentoAntecipacao() {
        api.get(`FormaPagamento/RetornaFormaPagamentoAntecipacao?Ativo=true&FormasPagtoQuitaAntecipacao=true`).then(
            res => {
                setFormaPagamentoAntecipacao(res.data.items[0]);
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        );
    };

    function validarJaPossuiCrediario(callback) {
        let dados = [...listaFormaPagamento];
        if (!dados.find((formaPgto => (formaPgto.fpg_tipopagamento === pagamentosCodigos.crediarioLoja && formaPgto.parcelas.length > 0)))) {
            if (callback) {
                callback();
            }
        }
    };

    async function adicionarFormaPagamento(liberadoCredito = false, antecipacao = false) {
        let dadosTemp = [...listaFormaPagamento];
        let pagamentoCartao = false;
        let dadosFormaPagamento = await carregarDadosFormaPgto(formulario.getFieldValue().fpg_id);
        if ((parseFloat(formulario.getFieldValue().pfp_valor) === 0)
            && dadosFormaPagamento?.fpg_tipopagamento !== (pagamentosCodigos.dinheiro || pagamentosCodigos.cheque || pagamentosCodigos.pixDinamico ||  pagamentosCodigos.pixEstatico)) {
            notification.warning({ message: 'Aviso!', description: 'Valor de Pagamento Inválido!' });
            return false;
        }
        if (ehCheque && formulario.getFieldValue("numeroCheques") === 0) {
            notification.warning({ message: 'Aviso!', description: 'Informe o número de cheques!' });
            return false;
        }
        if (ehValePresente && !!!formulario.getFieldValue().codigoValePresente) {
            notification.warning({ description: 'Favor informar um vale presente válido!', message: 'Aviso' });
            return;
        }
        if (antecipacao) {
            if (!dadosTemp.find(formasPgto => formasPgto.fpg_tipopagamento === pagamentosCodigos.antecipacao && formasPgto.fpg_quitacaoantecipacao)) {
                dadosTemp.push({
                    fpg_id: formaPagamentoAntecipacao.fpg_id,
                    fpg_descricao: formaPagamentoAntecipacao.fpg_descricao,
                    cpg_descricao: '',
                    cpg_id: null,
                    condicaoPagamento: {},
                    formaPagamento: formaPgtoAntecipacao,
                    pfp_valor: parseFloat(manutencao.dados.antecipacao[0].valorAntecipacao.toFixed(2)),
                    nfp_valortroco: 0,
                    parcelas: [],
                    descontoPadrao: {},
                    ctc_id: formaPagamentoAntecipacao.ctc_id,
                    fpg_tipopagamento: pagamentosCodigos.antecipacao,
                    fpg_quitacaoantecipacao: formaPagamentoAntecipacao.fpg_quitacaoantecipacao,
                    antecipacoes: isObjetoDiffVazio(manutencao.dados.antecipacao) ? manutencao.dados.antecipacao : []
                });
                setListaFormaPagamento(dadosTemp);
                setFormaPagamentoAntecipacao({});
                valorRestantePgto(dadosTemp);
            }
        } else {
            if (!!formulario.getFieldValue().fpg_id && (semCondicao || (condicaoPagamento.length === 0 || !!formulario.getFieldValue().cpg_id))) {
                // if (listaFormaPagamento.find((formas) => (
                //     formas.fpg_id === formulario.getFieldValue().fpg_id && (!semCondicao ? formas.cpg_id === formulario.getFieldValue().cpg_id : true)
                // ))) {
                //     let dados = listaFormaPagamento.find((formas) => (
                //         formas.fpg_id === formulario.getFieldValue().fpg_id && (!semCondicao ? formas.cpg_id === formulario.getFieldValue().cpg_id : true)
                //     ));
                //     dados.pfp_valor = parseFloat(formulario.getFieldValue().pfp_valor);
                //     validarContaCorrente(dadosTemp, () => {
                //         if (!pagamentoCartao) {
                //             valorRestantePgto(dadosTemp);
                //         }
                //     });
                //     formulario.setFieldsValue({ fpg_id: null });
                //     formulario.setFieldsValue({ cpg_id: null });
                //     formulario.setFieldsValue({ fpg_tipopagamento: null });
                //     formulario.setFieldsValue({ ctc_id: null });
                //     formulario.setFieldsValue({ codigoValePresente: null });
                //     formulario.setFieldsValue({ numeroCheques: null });
                // } else {
                verificarRestricoesCredito(dadosFormaPagamento, liberadoCredito, () => {
                    incluirNovaFormaPagamento(dadosTemp);
                });
                //}
            } else {
                notification.warning({ message: 'Aviso', description: 'Informe a forma e a condicao de pagamento!' });
            }
        }
    };


    async function salvarEditFormaPgto() {
        let listaForma = [...listaFormaPagamento];
        let dadosFormaPagamento = await carregarDadosFormaPgto(formulario.getFieldValue().fpg_id);
        let idx = formulario.getFieldValue().idxForma;

        let formaEditando = listaForma[idx];
        let valorTemp = valorJaPago - formaEditando.pfp_valor;
        if (ehValePresente && !!!formulario.getFieldValue().codigoValePresente) {
            notification.warning({ description: 'Favor informar um vale presente válido!', message: 'Aviso' });
            return;
        }
        let valorTroco = 0;
        if (parseFloat(parseFloat(formulario.getFieldValue().pfp_valor) + parseFloat(valorJaPago)).toFixed(2) > parseFloat(formulario.getFieldValue().valorTotal)) {
            valorTroco = parseFloat((parseFloat(formulario.getFieldValue().pfp_valor) + parseFloat(valorJaPago) - parseFloat(formulario.getFieldValue().valorTotal)).toFixed(2));
        }
        if (semCondicao) {
            formaEditando.fpg_id = formulario.getFieldValue().fpg_id;
            formaEditando.fpg_descricao = dadosFormaPagamento.fpg_descricao;
            formaEditando.cpg_descricao = '';
            formaEditando.cpg_id = null;
            formaEditando.condicaoPagamento = {};
            formaEditando.formaPagamento = !!dadosFormaPagamento && JSON.stringify(dadosFormaPagamento) !== '{}' ? dadosFormaPagamento : {};
            formaEditando.pfp_valor = formulario.getFieldValue().pfp_valor;
            formaEditando.nfp_valortroco = valorTroco;
            formaEditando.nfp_codigovalepresente = formulario.getFieldValue().codigoValePresente;
            formaEditando.parcelas = [];
            formaEditando.numeroCheques = null;
            formaEditando.descontoPadrao = {};
            formaEditando.ctc_id = dadosFormaPagamento.ctc_id;
            formaEditando.fpg_tipopagamento = dadosFormaPagamento.fpg_tipopagamento;
            formaEditando.fpg_tipointegracao = dadosFormaPagamento.fpg_tipointegracao;
            validarContaCorrente(listaForma, () => {
                valorRestantePgto(listaForma);
            });
        } else {
            let dadosCondicoesPagamento = condicaoPagamento.filter((condicao) => { return condicao.cpg_id === formulario.getFieldValue().cpg_id; })[0];

            formaEditando.fpg_id = formulario.getFieldValue().fpg_id;
            formaEditando.fpg_descricao = dadosFormaPagamento.fpg_descricao;
            formaEditando.cpg_descricao = dadosCondicoesPagamento?.cpg_descricao ?? '';
            formaEditando.cpg_id = formulario.getFieldValue().cpg_id ?? null;
            formaEditando.condicaoPagamento = !!dadosCondicoesPagamento && JSON.stringify(dadosCondicoesPagamento) !== '{}' ? dadosCondicoesPagamento : {};
            formaEditando.formaPagamento = !!dadosFormaPagamento && JSON.stringify(dadosFormaPagamento) !== '{}' ? dadosFormaPagamento : {};
            formaEditando.pfp_valor = formulario.getFieldValue().pfp_valor;
            formaEditando.nfp_valortroco = valorTroco;
            formaEditando.nfp_codigovalepresente = formulario.getFieldValue().codigoValePresente;
            formaEditando.parcelas = [];
            formaEditando.numeroCheques = formulario.getFieldValue().numeroCheques;
            formaEditando.descontoPadrao = {};
            formaEditando.ctc_id = dadosFormaPagamento.ctc_id;
            formaEditando.fpg_tipopagamento = dadosFormaPagamento.fpg_tipopagamento;
            formaEditando.fpg_tipointegracao = dadosFormaPagamento.fpg_tipointegracao;

            validarContaCorrente(listaForma, () => {
                valorRestantePgto(listaForma);
            });
        }

        formulario.setFieldsValue({ fpg_id: null });
        formulario.setFieldsValue({ cpg_id: null });
        formulario.setFieldsValue({ fpg_tipopagamento: null });
        formulario.setFieldsValue({ ctc_id: null });
        formulario.setFieldsValue({ codigoValePresente: null });
        formulario.setFieldsValue({ numeroCheques: null });
        formulario.setFieldsValue({ idxForma: null });
        setEditando(false);
    }

    async function incluirNovaFormaPagamento(listaForma) {
        let dadosFormaPagamento = await carregarDadosFormaPgto(formulario.getFieldValue().fpg_id);
        let valorTroco = 0;
        if (parseFloat(parseFloat(formulario.getFieldValue().pfp_valor) + parseFloat(valorJaPago)).toFixed(2) > parseFloat(formulario.getFieldValue().valorTotal)) {
            valorTroco = parseFloat((parseFloat(formulario.getFieldValue().pfp_valor) + parseFloat(valorJaPago) - parseFloat(formulario.getFieldValue().valorTotal)).toFixed(2));
        }

        if (semCondicao) {
            listaForma.push({
                fpg_id: formulario.getFieldValue().fpg_id,
                fpg_descricao: dadosFormaPagamento.fpg_descricao,
                cpg_descricao: '',
                cpg_id: null,
                condicaoPagamento: {},
                formaPagamento: !!dadosFormaPagamento && JSON.stringify(dadosFormaPagamento) !== '{}' ? dadosFormaPagamento : {},
                pfp_valor: formulario.getFieldValue().pfp_valor,
                nfp_valortroco: valorTroco,
                nfp_codigovalepresente: formulario.getFieldValue().codigoValePresente,
                parcelas: [],
                numeroCheques: formulario.getFieldValue().numeroCheques,
                integrapgtook: false,
                descontoPadrao: {},
                ctc_id: dadosFormaPagamento.ctc_id,
                fpg_tipopagamento: dadosFormaPagamento.fpg_tipopagamento,
                fpg_tipointegracao: dadosFormaPagamento.fpg_tipointegracao,
            });
            validarContaCorrente(listaForma, () => {
                valorRestantePgto(listaForma);
            });
        } else {
            let dadosCondicoesPagamento = condicaoPagamento.filter((condicao) => { return condicao.cpg_id === formulario.getFieldValue().cpg_id; })[0];
            listaForma.push({
                fpg_id: formulario.getFieldValue().fpg_id,
                fpg_descricao: dadosFormaPagamento.fpg_descricao,
                cpg_descricao: !!dadosCondicoesPagamento && dadosCondicoesPagamento.cpg_descricao ? dadosCondicoesPagamento.cpg_descricao : '',
                cpg_id: !!formulario.getFieldValue().cpg_id ? formulario.getFieldValue().cpg_id : null,
                condicaoPagamento: !!dadosCondicoesPagamento && JSON.stringify(dadosCondicoesPagamento) !== '{}' ? dadosCondicoesPagamento : {},
                formaPagamento: !!dadosFormaPagamento && JSON.stringify(dadosFormaPagamento) !== '{}' ? dadosFormaPagamento : {},
                pfp_valor: formulario.getFieldValue().pfp_valor,
                nfp_valortroco: valorTroco,
                nfp_codigovalepresente: formulario.getFieldValue().codigoValePresente,
                parcelas: [],
                numeroCheques: null,
                integrapgtook: false,
                descontoPadrao: {},
                ctc_id: dadosFormaPagamento.ctc_id,
                fpg_tipopagamento: dadosFormaPagamento.fpg_tipopagamento,
                fpg_tipointegracao: dadosFormaPagamento.fpg_tipointegracao,
            });
            validarContaCorrente(listaForma, () => {
                valorRestantePgto(listaForma);
            });
        }

        formulario.setFieldsValue({ fpg_id: null });
        formulario.setFieldsValue({ cpg_id: null });
        formulario.setFieldsValue({ fpg_tipopagamento: null });
        formulario.setFieldsValue({ ctc_id: null });
        formulario.setFieldsValue({ codigoValePresente: null });
        formulario.setFieldsValue({ numeroCheques: null });
    };

    function validarContaCorrente(dados, callback) {
        let ultimaForma = dados[dados.length - 1];
        if (!!!ultimaForma.ctc_id && (ultimaForma.fpg_tipopagamento === pagamentosCodigos.cheque 
            || ultimaForma.fpg_tipopagamento === pagamentosCodigos.dinheiro
            || ultimaForma.fpg_tipopagamento === pagamentosCodigos.depositoBancario 
            || ultimaForma.fpg_tipopagamento === pagamentosCodigos.pixDinamico
            || ultimaForma.fpg_tipopagamento === pagamentosCodigos.pixEstatico
            || ultimaForma.fpg_tipopagamento === pagamentosCodigos.transfBancaria)) {
            api.get(`ContaCorrente/Buscar?codigoContaCorrente=${dadosPdv.pdv?.ctc_id}`).then(
                res => {
                }
            )
        }
        var dadosNota = { ...manutencao.dados };
        dadosNota.faturamento = [...dados];
        setListaFormaPagamento(dados);
        if (!!callback) {
            callback();
        }
    };

    function verificarRestricoesCredito(dadosFormaPagamento, liberado, callback) {
        let parcelasAtraso = [];
        let limiteCreditoDisp = {};
        let cliente = !!isObjetoDiffVazio(dadosCliente) ? dadosCliente : manutencao.dados.cliente;
        if (liberado) {
            callback();
        } else if (parseInt(dadosFormaPagamento.fpg_tipopagamento) === pagamentosCodigos.crediarioLoja) {
            if (cliente.pes_nome === 'CONSUMIDOR FINAL' || !!cliente.cpfCnpj) {
                Modal.confirm({
                    title: `Aviso`,
                    icon: <ExclamationCircleOutlined />,
                    content: `Para compras parceladas, por favor informar um cliente cadastrado!`,
                    okText: `Ok`
                })
                return false;
            }
            api.get(`Parcela/ListarParcelasClientesEmAtrazo?IdPessoa=${cliente.pes_id}`).then(
                parcelasPessoa => {
                    let dados = [...parcelasPessoa.data?.items];
                    api.get(`Cliente/LimiteCreditoDisponivel/${cliente.pes_id}`).then(
                        limiteCredito => {
                            parcelasAtraso = dados;
                            limiteCreditoDisp = limiteCredito.data;
                            dados.forEach((parcelas) => { parcelas.limiteCredito = limiteCreditoDisp.valorTotal });
                            setParcelasPessoasAtraso(dados);
                            if (!!!parcelasAtraso) {
                                notification.warning({ description: 'Não foi possível verificar as parcelas em atraso deste cliente. É recomendável que você fale com seu supervisor/gerente antes de prosseguir com a venda!', message: 'Aviso' });
                            } else if (parcelasAtraso.length > 0) {
                                setAbreModalParcPendentes(true);
                            } else if (!!!limiteCreditoDisp) {
                                notification.warning({ description: 'Não foi possível verificar o limite de crédito disponível para realizar a venda no crediário. É recomendável que você fale com seu supervisor/gerente antes de prosseguir com a venda!', message: 'Aviso' });
                            } else if (limiteCreditoDisp.valorTotal !== 0 && limiteCreditoDisp.valorTotal < parseFloat(valorJaPago)) {
                                Modal.confirm({
                                    title: `Aviso`,
                                    icon: <ExclamationCircleOutlined />,
                                    content: `O limite de crédito ainda disponível para compras à prazo deste cliente é: ${parseFloat(limiteCreditoDisp.valorTotal).toFixed(2).toString()}. Para continuar com a venda à prazo você deve solicitar autorização da operação com seu supervisor/gerente.`,
                                    okText: 'Solicitar Autorização',
                                    cancelText: 'OK',
                                    onOk() {
                                        setAbreModalLiberacao(true);
                                    }
                                });
                            } else {
                                callback();
                            }
                        }
                    ).catch(
                        erro => {
                            console.log(erro);
                        }
                    )
                }
            ).catch(
                erro => {
                    console.log(erro);
                }
            );
        } else {
            callback();
        }
    };

    function abrirModalCartao(dados) {
        if (dados.parcelas <= 0) {
            if (dados.fpg_tipopagamento === pagamentosCodigos.cartaoCredito) {
                popularParcelas(() => {
                    if (dados.fpg_tipointegracao == null)
                        setOpenModalCartao(true);
                });
            } else if (dados.fpg_tipopagamento === pagamentosCodigos.cartaoDebito) {
                popularParcelas(() => {
                    if (dados.fpg_tipointegracao == null)
                        setOpenModalCartao(true);
                });
            }
        }
    };

    function abrirModalCheque(dados) {
        setDadosCheque(dados);
        setExibirModalCheque(!exibirModalCheque);
    };

    function popularParcelas(callback) {
        let dados = [...listaFormaPagamento];
        let index = manutencao.dados.faturamento.length - 1;
        api.get(`CondicaoPagamento/ListarParcelamento?codigoCondicaoPagamento=${manutencao.dados.faturamento[index].cpg_id}`).then(
            res => {
                if (!!res.data && res.data.length > 0) {
                    let resto = 0;
                    let somaParcelas = 0;
                    let listaParcelas = [];
                    let indexPosicao = manutencao.dados.faturamento.length - 1;
                    res.data.forEach((parcela) => {
                        let dataAtual = new Date();
                        let parc = {};
                        let dataVencimento = new Date(dataAtual.setDate(dataAtual.getDate() + parcela.pcl_numerodias));
                        parc.fpg_id = manutencao.dados.faturamento[indexPosicao].fpg_id;
                        parc.fpg_descricao = manutencao.dados.faturamento[indexPosicao].fpg_descricao;
                        parc.fpg_tipopagamento = manutencao.dados.faturamento[indexPosicao].fpg_tipopagamento;
                        parc.cpg_id = manutencao.dados.faturamento[indexPosicao].cpg_id;
                        parc.pcl_numerodias = parcela.pcl_numerodias;
                        parc.pcl_percparcela = parcela.pcl_percparcela;
                        parc.pcl_numeroparcela = parcela.pcl_numeroparcela;
                        parc.prc_numeroparcela = parcela.pcl_numeroparcela;
                        parc.dataPagamento = dataVencimento;
                        parc.prc_datavencimento = moment(dataVencimento);
                        parc.dataVencimento = moment(dataVencimento);
                        let valorParcela = ((parcela.pcl_percparcela / 100) * parseFloat(manutencao.dados.faturamento[index].pfp_valor)).toFixed(2);
                        parc.valorParcela = parseFloat(valorParcela);
                        parc.prc_valor = parseFloat(valorParcela);
                        somaParcelas += parc.valorParcela;
                        listaParcelas.push(parc);
                    });
                    somaParcelas = somaParcelas.toFixed(2);
                    somaParcelas = parseFloat(somaParcelas);
                    resto = parseFloat(manutencao.dados.faturamento[index].pfp_valor) - somaParcelas;
                    if (resto != 0) {
                        let numeroUltimaParcela = res.data.length - 1;
                        let valParcela = listaParcelas[numeroUltimaParcela].valorParcela;
                        let totalParcela = (valParcela + resto).toFixed(2);
                        totalParcela = parseFloat(totalParcela);
                        listaParcelas[numeroUltimaParcela].prc_valor = totalParcela;
                        listaParcelas[numeroUltimaParcela].valorParcela = totalParcela;
                    }
                    dados[index].parcelas = listaParcelas;
                    if (manutencao.dados.faturamento[indexPosicao].fpg_tipopagamento !== pagamentosCodigos.crediarioLoja) {
                        let creditoDebito = manutencao.dados.faturamento[indexPosicao].fpg_tipopagamento === pagamentosCodigos.cartaoCredito;
                        dispatch({ type: manutencaoActions.CHANGE, data: { dados: { ...manutencao.dados, ... { credito: creditoDebito } } } });
                    }
                    setListaFormaPagamento(dados);
                }
                if (callback) {
                    callback();
                }
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        );
    };

    function popularParcelasDebito(callback) {
        if (listaFormaPagamento?.length > 0) {
            let dados = [...listaFormaPagamento];
            let index = manutencao.dados.faturamento.length - 1;
            let listaParcelas = [];
            let indexPosicao = manutencao.dados.faturamento.length - 1;
            let parc = {};
            parc.fpg_id = manutencao.dados.faturamento[indexPosicao].fpg_id;
            parc.fpg_descricao = manutencao.dados.faturamento[indexPosicao].fpg_descricao;
            parc.fpg_tipopagamento = manutencao.dados.faturamento[indexPosicao].fpg_tipopagamento;
            parc.cpg_id = manutencao.dados.faturamento[indexPosicao].cpg_id;
            parc.pcl_percparcela = 100;
            parc.pcl_numeroparcela = 1;
            parc.prc_numeroparcela = 1;
            let valorParcela = ((parc.pcl_percparcela / 100) * parseFloat(manutencao.dados.faturamento[index].pfp_valor)).toFixed(2);
            parc.valorParcela = parseFloat(valorParcela);
            parc.prc_valor = parseFloat(valorParcela);
            listaParcelas.push(parc);
            dados[index].parcelas = listaParcelas;
            dispatch({ type: manutencaoActions.CHANGE, data: { dados: { ...manutencao.dados, ... { credito: false } } } });
            setListaFormaPagamento(dados);
            if (callback) {
                callback();
            }
        }
    };

    function pagamentoCartao(dados, dadosCartao) {
        let dataAtual = new Date();
        let listaFormasPagamento = [...manutencao.dados.faturamento];
        let indexPosicao = listaFormasPagamento.length - 1;
        let recebimentoCartao = [];
        let retorno = {};
        if (dadosCartao.tipoParcelamento === 0) {
            let numeroDias = !!dados.credito ? dadosCartao.bandeira.bce_nrodiasrecebimentocredito : dadosCartao.bandeira.bce_nrodiasrecebimentodebito;
            listaFormasPagamento[indexPosicao].parcelas.forEach((parcela) => {
                retorno.tipoParcelamento = dadosCartao.tipoParcelamento;
                retorno.bce_id = dadosCartao.bandeira.bce_id;
                retorno.rcc_parcelarecebimento = parcela.pcl_numeroparcela;
                retorno.rcc_valor = parcela.prc_valor;
                retorno.rcc_nsu = dadosCartao.nsu;
                retorno.rcc_previsaorecebimento = new Date(dataAtual.setDate(dataAtual.getDate() + numeroDias));
                recebimentoCartao.push(retorno);
            });
        } else {
            retorno.tipoParcelamento = dadosCartao.tipoParcelamento;
            retorno.bce_id = dadosCartao.bandeira.bce_id;
            retorno.rcc_parcelarecebimento = 1;
            retorno.rcc_valor = listaFormasPagamento[indexPosicao].valorPago;
            retorno.rcc_nsu = dadosCartao.nsu;
            retorno.rcc_previsaorecebimento = new Date(dataAtual.setDate(dataAtual.getDate() + parseInt(dadosCartao.bandeira.bce_nrodiasrecebimentocredito)));
            recebimentoCartao.push(retorno);
        }
        if (listaFormasPagamento[indexPosicao].fpg_tipopagamento === pagamentosCodigos.cartaoDebito) {
            let formaPagamento = listaFormaPagamento[indexPosicao];
            formaPagamento.parcelas.forEach(parcela => {
                parcela.pcl_numerodias = dadosCartao.bandeira.bce_nrodiasrecebimentodebito;
                let dataVencimento = new Date(dataAtual.setDate(dataAtual.getDate() + parseInt(parcela.pcl_numerodias)));
                parcela.dataPagamento = dataVencimento;
                parcela.prc_datavencimento = moment(dataVencimento);
                parcela.dataVencimento = moment(dataVencimento);
            });
            setListaFormaPagamento(listaFormaPagamento);
        }
        listaFormaPagamento[indexPosicao].recebimentoCartao = recebimentoCartao;
        listaFormaPagamento[indexPosicao].bce_id = recebimentoCartao[0].bce_id;
        dispatch({
            type: manutencaoActions.CHANGE, data: {
                dados: { ...manutencao.dados, ...manutencao.dados.faturamento, ...{ recebimentoCartao: recebimentoCartao } }
            }
        });
        setOpenModalCartao(false);
    };

    function salvarFormaPagamento(record) {
        let formasPgto = [...listaFormaPagamento];
        let formaPgto = formasPgto.find((forma) => (forma.fpg_id === record.fpg_id));
        formaPgto.parcelas.forEach((item, i) => {
            i++;
            item.prc_datavencimento = formulario.getFieldValue(`dataVencimento${i}`);
            item.dataVencimento = formulario.getFieldValue(`dataVencimento${i}`);
            item.valorParcela = parseFloat(formulario.getFieldValue(`valorParcela${i}`));
            item.prc_valor = parseFloat(formulario.getFieldValue(`valorParcela${i}`));
        });
        setListaFormaPagamento(formasPgto);
        setEditandoParcelas(false);
    }

    async function editarFormasPagamento(forma) {
        let dados = await carregarDadosFormaPgto(forma.fpg_id);
        setPagamentoTotal(false);
        setEditando(true);
        let lista = [];
        lista.push({ name: "fpg_id", campo: "Filtro", value: forma.fpg_id });
        dispatch({ type: listaSelectPaginadoActions.CHANGE, data: { itens: lista } });
        formulario.setFieldsValue({ fpg_id: forma.fpg_id });
        if (dados.fpg_tipopagamento === pagamentosCodigos.dinheiro || dados.fpg_tipopagamento === pagamentosCodigos.cheque || dados.fpg_tipopagamento === pagamentosCodigos.valePresente || dados.fpg_tipopagamento === pagamentosCodigos.crediarioLoja) {
            setSemCondicao(true);
            if (dados.fpg_tipopagamento === pagamentosCodigos.cheque) {
                setEhCheque(true);
            } else if (dados.fpg_tipopagamento === pagamentosCodigos.valePresente) {
                setEhValePresente(true);
            } else if (dados.fpg_tipopagamento === pagamentosCodigos.crediarioLoja) {
                setEditandoParcelas(true);
            }
        } else {
            formulario.setFieldsValue({ cpg_id: forma.cpg_id });
        }
        formulario.setFieldsValue({ pfp_valor: forma.pfp_valor });
    };

    function onChangeValorParc(numeroParc, qtdTotParcelas) {
        let valorJaUtilizado = 0;
        let valorRestante = 0;
        let valorRestanteTot = 0;
        let valorParcelas = 0;
        let valorTotal = parseFloat(formulario.getFieldValue().valorTotal);
        let dados = formulario.getFieldValue();
        if (numeroParc === qtdTotParcelas) {
            return;
        }
        for (let i = 1; i <= numeroParc; i++) {
            valorJaUtilizado += parseFloat(dados[`valorParcela${i}`]);
        }
        valorRestante = valorTotal - valorJaUtilizado;
        valorParcelas = parseFloat((valorRestante / (qtdTotParcelas - numeroParc)).toFixed(2));
        for (let i = qtdTotParcelas; i > numeroParc; i--) {
            valorRestanteTot += valorParcelas;
            formulario.setFieldsValue({ [`valorParcela${i}`]: valorParcelas });
        }
        let valorSobra = valorTotal - (valorJaUtilizado + valorRestanteTot);
        formulario.setFieldsValue({ [`valorParcela${qtdTotParcelas}`]: parseFloat((valorParcelas + valorSobra).toFixed(2)) });
    };

    function removerFormaPagamento(forma) {
        let dadosTemp = [...listaFormaPagamento];
        if (dadosParcelas.length > 0 && forma.fpg_tipopagamento === dadosParcelas[0].fpg_tipopagamento) {
            setDadosParcelas([]);
        } else if (forma.fpg_tipopagamento === pagamentosCodigos.antecipacao) {
            dispatch({ type: manutencaoActions.CHANGE, data: { dados: { ...manutencao.dados, ...{ faturamento: manutencao.dados.faturamento.filter((dadosFat) => dadosFat.fpg_tipopagamento !== forma.fpg_tipopagamento), antecipacao: null } } } });
        } else if (forma.fpg_tipopagamento === pagamentosCodigos.cartaoCredito || forma.fpg_tipopagamento === pagamentosCodigos.cartaoDebito) {
            dispatch({ type: manutencaoActions.CHANGE, data: { dados: { ...manutencao.dados, ... { credito: null } } } });
        }
        dadosTemp = dadosTemp.filter((dados) => dados !== forma);
        valorRestantePgto(dadosTemp);
        setListaFormaPagamento(dadosTemp);
        removeDadosNota();
    };

    async function validacaoFormasPagamento(registro) {
        if (!!registro) {
            let dados = (await api.get(`FormaPagamento/Get?id=${registro}`)).data;
            setSemCondicao(false);
            setEhCheque(false);
            setEhValePresente(false);
            switch (dados.fpg_tipopagamento) {
                case pagamentosCodigos.dinheiro:
                    setSemCondicao(true);
                    setarValor();
                    break;
                case pagamentosCodigos.cheque:
                    setEhCheque(true)
                    setSemCondicao(true);
                    setarCheque();
                    break;
                case pagamentosCodigos.valePresente:
                    setEhValePresente(true);
                    setSemCondicao(true);
                    setarVale();
                    break;
                case pagamentosCodigos.boleto:
                    break;
                case pagamentosCodigos.crediarioLoja:
                    let cliente = !!isObjetoDiffVazio(dadosCliente) ? dadosCliente : manutencao.dados.cliente;
                    if (!!cliente && cliente.cli_vendasomenteavista) {
                        Modal.confirm({
                            title: 'Aviso!',
                            icon: <ExclamationCircleOutlined />,
                            content: 'Venda a prazo não autorizada!',
                            okText: 'Solicitar Autorização',
                            cancelText: 'OK',
                            onOk() {
                                setAbreModalLiberacao(true);
                            }, onCancel() {
                                formulario.setFieldsValue({ fpg_id: null });
                            }

                        })
                    }
                    break;
            }
        }
    };

    const clickValor = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            adicionarButtonRef.current.focus();
        }
    }

    function setarValor() {
        setTimeout(() => {
            try {
                let element = document.getElementById("valorPmto");
                if (element) {
                    element.focus();
                    element.select();
                }
            } catch (error) {
                console.log('error :>> ', error);
            }
        }, 100)
    }

    function setarComCondicao() {
        setTimeout(() => {
            try {
                let element = document.getElementById("condicao");
                if (element) {
                    element.focus();
                    setSelectOpen(true);
                }
            } catch (error) {

            }
        })
    }

    function setarVale() {
        setTimeout(() => {
            try {
                let element = document.getElementById("valePresente");
                if (element) {
                    element.focus();
                }
            } catch (error) {
                console.log('error :>> ', error);
            }
        }, 150)
    }

    function setarCheque() {
        setTimeout(() => {
            try {
                let element = document.getElementById("cheque");
                if (element) {
                    element.focus();
                    element.select();
                }
            } catch (error) {
                console.log('error :>> ', error);
            }
        });
    }

    function buscarValePresente(codVale) {
        api.get(`AntecipacaoClienteFornecedor/BuscarValePresente?codigoVale=${codVale.currentTarget.value}`).then(
            (res) => {
                if (res.status === 200) {
                    let saldoVale = res.data;
                    let valorTotal = parseFloat(formulario.getFieldValue().valorTotal);
                    if (saldoVale === 0) {
                        formulario.setFieldsValue({ fpg_id: null });
                        formulario.setFieldsValue({ cpg_id: null });
                        formulario.setFieldsValue({ codigoValePresente: null });
                        notification.warning("Não existe saldo para o vale presente informado!");
                    } else {
                        if (valorTotal > saldoVale) {
                            formulario.setFieldsValue({ pfp_valor: saldoVale });
                        } else {
                            formulario.setFieldsValue({ pfp_valor: valorTotal });
                        }
                    }
                }
            }
        ).catch(
            (erro) => {
                notification.warning({ message: "Aviso", description: "Vale presente não encontrado!" });
                formulario.setFieldsValue({ codigoValePresente: null });
            }
        );
    };

    async function verificarAntecipacoes() {
        let cliente = !!isObjetoDiffVazio(dadosCliente) ? dadosCliente : manutencao.dados.cliente;
        if (!!cliente.pes_id) {
            let retorno = await api.get(`AntecipacaoClienteFornecedor/ListaAntecipacaoPessoa?IdPessoa=${cliente.pes_id}`);
            if (retorno.status === 200) {
                if (!!retorno.data && retorno.data.length > 0) {
                    let seq = 0;
                    retorno.data.forEach((ant) => {
                        ant.key = seq;
                        seq++;
                    });
                    setPossuiAntecipacao(true);
                    setDadosAntecipacao(retorno.data);
                    Modal.confirm({
                        title: 'O Cliente possui créditos/antecipações',
                        icon: <ExclamationCircleOutlined />,
                        content: `Este cliente possui créditos/antecipações, deseja utilizar elas para esta venda?`,
                        okText: 'Utilizar Crédito/antecipação',
                        cancelText: 'Não utilizar',
                        onOk() {
                            setAbreModalAntecipacao(true);
                        }, onCancel() {
                            formulario.setFieldsValue({ fpg_id: null });
                            formulario.setFieldsValue({ cpg_id: null });
                            formulario.setFieldsValue({ fpg_tipopagamento: null });
                            formulario.setFieldsValue({ ctc_id: null });
                            formulario.setFieldsValue({ codigoValePresente: null });
                            formulario.setFieldsValue({ numeroCheques: null });
                        }
                    });
                }
            }
        }
    };

    function onCloseModalAntecipacao() {
        //setDadosAntecipacao([]);
        setAbreModalAntecipacao(false);
    }

    async function ajustarDatasParcelas(record, idx) {
        let listaTemp = [...listaFormaPagamento];
        for (let i = 0; i < listaTemp.length; i++) {
            let definicaoParcelamento = (await api.get(`CondicaoPagamento/ListarParcelamento?codigoCondicaoPagamento=${record.cpg_id}`)).data;
            if (!!listaTemp[i].parcelas && listaTemp[i].parcelas.length > 0) {
                listaTemp[i].parcelas[idx].prc_datavencimento = moment(new Date(formulario.getFieldValue(`dataVencimento${idx + 1}`)));
                for (let a = idx; a < listaTemp[i].parcelas.length; a++) {
                    if (record.parcelas[a + 1] != null) {
                        let qtdeDias = 0;
                        if (a > 0) {
                            qtdeDias = definicaoParcelamento[a].pcl_numerodias - definicaoParcelamento[a - 1].pcl_numerodias;
                        } else {
                            qtdeDias = definicaoParcelamento[a].pcl_numerodias;
                        }
                        let dataFormulario = new Date(formulario.getFieldValue(`dataVencimento${a + 1}`));
                        let dataVenc = moment(new Date(dataFormulario.setDate(dataFormulario.getDate() + qtdeDias)));
                        formulario.setFieldsValue({ [`dataVencimento${a + 2}`]: dataVenc });
                        listaTemp[i].parcelas[a + 1].prc_datavencimento = moment(new Date(new Date(listaTemp[i].parcelas[a].prc_datavencimento).setDate(new Date(listaTemp[i].parcelas[a].prc_datavencimento).getDate() + qtdeDias)));
                    }
                }
            }
        }
        setListaFormaPagamento(listaTemp);
    }

    const expandedRowRender = (record, indice) => {
        return <Table columns={[
            {
                title: 'Nº',
                width: 75,
                render: ({ pcl_numeroparcela }) => (
                    <Tag color="processing" className="w-100">
                        <b>{pcl_numeroparcela}</b>
                    </Tag>
                ),
            }, {
                title: 'Data de Pagamento',
                shouldCellUpdate: (() => { return renderizar }),
                render: ({ dataVencimento, pcl_numeroparcela }, a, idx) => (
                    <div>
                        {editandoParcelas ? <Data initialValue={dataVencimento} name={`dataVencimento${pcl_numeroparcela}`} onBlur={() => ajustarDatasParcelas(record, idx, indice)} /> : moment(dataVencimento).format('DD/MM/YYYY')}
                    </div>
                ),
            }, {
                title: 'Valor (R$)',
                align: 'right',
                shouldCellUpdate: (() => { return renderizar }),
                render: ({ valorParcela, pcl_numeroparcela }) => (
                    <div>
                        {editandoParcelas ? <InputPreco name={`valorParcela${pcl_numeroparcela}`} initialValue={valorParcela} disabled={pcl_numeroparcela === record.parcelas.length} onBlur={() => { onChangeValorParc(pcl_numeroparcela, record.parcelas.length) }} /> : <b>{FormatNumber(valorParcela, true)}</b>}
                    </div>
                ),
            }
        ]} dataSource={record.parcelas} pagination={false} />
    };

    const rowExpandable = record => { return (record.fpg_tipopagamento === pagamentosCodigos.cartaoDebito || record.fpg_tipopagamento === pagamentosCodigos.cartaoCredito || record.fpg_tipopagamento === pagamentosCodigos.crediarioLoja) && !record.fpg_quitacaoantecipacao };

    return (
        <div className="m-t-16">
            {!pagamentoTotal &&
                <Row gutter={[8, 0]}>
                    <Col xs={24} sm={8} md={7} lg={6} xl={5} xxl={5}>
                        <Form.Item label="Forma de Pagamento" name="fpg_id" rules={[{ required: true, message: 'Informe a forma de pagamento' }]}>
                            <SelectPaginacao url="FormaPagamento/Listar?Ativo=true&Order=+fpg_descricao" 
                                placeholder="Selecione Forma de Pagamento" 
                                form={formulario} 
                                nameValue="fpg_id"
                                onChangeFunction={(registro) => { listarCondicao(registro); validacaoFormasPagamento(registro) }} 
                                nameLabel={"fpg_descricao"} 
                                focus={true} 
                                defaultOpen={true}
                                conteudo={
                                    fp => (<Select.Option value={fp.fpg_id} key={fp.key} label={fp.fpg_descricao}>{fp.fpg_descricao}</Select.Option>)
                                } />
                        </Form.Item>
                    </Col>
                    {!semCondicao &&
                        <Col xs={24} sm={8} md={7} lg={6} xl={5} xxl={5}>
                            <Form.Item label="Condição de Pagamento" name="cpg_id" rules={[{ required: true, message: 'Informe a condicao de pagamento' }]}>
                                <Select 
                                    placeholder="Selecione Condição de Pagamento" 
                                    id="condicao" 
                                    disabled={(condicaoPagamento.length > 0 ? false : true)} 
                                    open={selectOpen}
                                    onDropdownVisibleChange={(open => setSelectOpen(open))}
                                    onSelect={setarValor}
                                    >
                                    {condicaoPagamento.map((condicao) => (
                                        <Select.Option value={condicao.cpg_id} key={condicao.cpg_id} label={condicao.cpg_descricao}>{condicao.cpg_descricao}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    }
                    {ehCheque && <Col xs={24} sm={8} md={7} lg={6} xl={5} xxl={5}>
                        <Form.Item name="numeroCheques" label="Nº Cheques" initialValue={0}>
                            <InputNumber
                                step={1}
                                min={0}
                                placeholder="Informe o Nº de Cheques"
                                id="cheque"
                            />
                        </Form.Item>
                    </Col>}
                    {ehValePresente && <Col xs={24} sm={8} md={7} lg={6} xl={5} xxl={5}>
                        <Form.Item name="codigoValePresente" label="Código do Vale Presente">
                            <Input
                                placeholder="Informe o Código do Vale Presente"
                                onBlur={(codVale) => { buscarValePresente(codVale) }}
                                id="valePresente"
                            />
                        </Form.Item>
                    </Col>}
                    <Col xs={24} sm={8} md={6} lg={6} xl={5} xxl={5} onKeyDown={clickValor}>
                        <InputPreco name={'pfp_valor'} id="valorPmto" label={'Valor do Pagamento'}/>
                    </Col>
                    <Form.Item hidden name="idxForma">
                        <Input />
                    </Form.Item>
                    <Col xs={24} sm={24} md={4} lg={6} xl={3} xxl={2} className="t-mob">
                        <Row align="middle" justify="end" gutter={[8, 0]}>
                            <Col xs={24} sm={8} md={24} lg={24} xl={24}>
                                <Button icon={<PlusOutlined />} type="primary" onClick={() => {
                                    if (editando) {
                                        salvarEditFormaPgto();
                                    } else {
                                        adicionarFormaPagamento();
                                    }
                                }} block ref={adicionarButtonRef}>
                                    Adicionar
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            }
            {!!manutencao.dados && !!manutencao.dados.antecipacao && <Row gutter={[0, 8]}>
                <Col>
                    <Descriptions>
                        <Descriptions.Item>
                            Valor antecipação utilizada: {FormatNumber(manutencao.dados.antecipacao[0].valorAntecipacao, true)}
                        </Descriptions.Item>
                    </Descriptions>
                </Col>
            </Row>}
            <Row gutter={[0, 24]} className="m-b-5">
                <Col span={24}>
                    <div className="tabela mt-dif">
                        {listaFormaPagamento.length > 0 && <Table className="components-table-demo-nested" pagination={false} columns={[
                            {
                                title: 'Formas de Pagamento',
                                align: 'left',
                                render: ({ fpg_descricao, cpg_descricao }) => (
                                    <div>
                                        <b>
                                            {!!cpg_descricao ? fpg_descricao + ' / ' + cpg_descricao : fpg_descricao}
                                        </b>
                                    </div>
                                ),
                            },
                            {
                                title: 'Valor (R$)',
                                align: 'right',
                                render: ({ pfp_valor }) => (
                                    <div>
                                        <b>{FormatNumber(pfp_valor, true)}</b>
                                    </div>
                                ),
                            },
                            {
                                title: 'Ações',
                                dataIndex: '',
                                key: 'x',
                                align: 'center',
                                width: 65,
                                render: (record, idk, index) => (
                                    <div>
                                        <Row align="middle" justify="center" gutter={[5, 0]}>
                                            {record.fpg_tipopagamento !== pagamentosCodigos.antecipacao && !record.fpg_quitacaoantecipacao && <Col>
                                                {!editandoParcelas &&
                                                    <Button icon={<EditOutlined />}
                                                        onClick={() => {
                                                            formulario.setFieldsValue({ idxForma: index });
                                                            editarFormasPagamento(record);
                                                        }} />
                                                }
                                                {editandoParcelas &&
                                                    <Button icon={<CheckOutlined />}
                                                        onClick={() => {
                                                            salvarFormaPagamento(record);
                                                        }} />
                                                }
                                            </Col>}
                                            <Col>
                                                <Button icon={<DeleteOutlined />} onClick={() => {
                                                    setEditandoParcelas(false);
                                                    removerFormaPagamento(record);
                                                }} />
                                            </Col>
                                        </Row>
                                    </div>
                                ),
                            },
                        ]}
                            expandable={{ expandedRowRender, rowExpandable }}
                            footer={() => {
                                let totalPagamento = 0;
                                listaFormaPagamento.map((dados) => {
                                    totalPagamento += arredondar(dados.pfp_valor);
                                    if (isObjetoDiffVazio(totais)) {
                                        totais.restaPagar = arredondar(totais.valorTotal - totalPagamento);
                                    }
                                });
                                totais.valorPago = arredondar(totalPagamento);
                                return (
                                    <Row>
                                        <Col span={24}>
                                            <Row align="middle" justify="end" gutter={[8, 0]}>
                                                <Col className="f-14">
                                                    Total dos Pagamentos (R$):
                                                </Col>
                                                <Col>
                                                    <Form.Item name="pfp_valor">
                                                        <strong className="f-21">{FormatNumber(totalPagamento, true)}</strong>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>)
                            }} dataSource={listaFormaPagamento}
                        />}
                    </div>
                    <ModalBandeiraCartao exibirModal={openModalCartao} fecharModal={fecharModalCartao} fecharModalOK={fecharModalOK} retornoModal={pagamentoCartao} />
                    <ModalSenhaGerencial exibirModalSenhaGerencial={abreModalLiberacao} fecharModalSenhaGerencial={onCloseModalLiberacao} fecharModalOk={fecharModalOK} formulario={formulario} />
                    <ModalParcelasPendentes dadosParcelas={parcelasPessoasAtraso} exibirModal={abreModalParcPendentes} fecharModal={onCloseModalParcPendentes} fecharModalOk={onCloseModalParcPendentesOk} />
                    {<ModalContaCorrente
                        exibirModalContaCorrente={abreModalContaCorrente}
                        fecharModalContaCorrenteOk={() => { onCloseModalContaCorrente() }}
                        fecharModalContaCorrente={() => { setAbreModalContaCorrente(false); }}
                        contaCorrente={{ contaCorrente, setContaCorrente }}
                        listaContaCorrente={{ listaContaCorrente, setListaContaCorrente }}
                        tipoConta={tipoContaCorrente}
                        setTipoConta={setTipoContaCorrente}
                    />
                    }
                    <ModalCheque form={formulario} exibirModal={exibirModalCheque} setExibirModal={setExibirModalCheque} fecharModal={onCloseModalCheque} dadosCheque={dadosCheque} dadosCliente={cliente} setInfoCheque={setInfoCheque} />
                    <ModalAntecipacao formAnterior={formulario} exibirModalAntecipacao={abreModalAntecipacao} fecharModalAntecipacao={onCloseModalAntecipacao} dadosAntecipacao={dadosAntecipacao} />
                </Col>
            </Row>
        </div>
    );
};