import React, { useState, useEffect } from "react";
import { Row, Col, Form, Modal, Typography, Input, Button, Table, Divider } from "antd";
import { ExclamationCircleOutlined, SearchOutlined, ArrowLeftOutlined, PlusOutlined, ArrowRightOutlined, CheckOutlined, CloseOutlined } from "@ant-design/icons";
import CpfCnpj from "@react-br-forms/cpf-cnpj-mask";

import api from "../../../services/api";
import { useStateValue } from '../../../state';
import { FormatFone, MaskFormat } from "../../../ValueObjects";
import { drawerActions, listagemActions, manutencaoActions } from "../../../actions";
import TabelaDados from "../../tabelaDados";
import { isObjetoDiffVazio, retornaSoNumeros, validarCpf, validarCnpj } from "../../../services/funcoes";
import DrawerCliente from "../../drawerCliente";

export default function ModalBuscaCliente({ formulario, setDadosCliente, exibirModalBuscaCliente, fecharModalBuscaCliente }) {

    const [form] = Form.useForm();
    const [{ manutencao }, dispatch] = useStateValue();
    const [consultaCliente, setConsultaCliente] = useState(false);
    const [cpfCnpj, setCpfCnpj] = useState(null);
    const [editando, setEditando] = useState(false);
    const [mask, setMask] = useState("");
    const onCloseModal = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                form.resetFields();
                setConsultaCliente(false);
                fecharModalBuscaCliente();
            }
        });
    };

    async function adicionarCliente(dados) {
        let cpfCnpj = !!dados.pef_cpf ? dados.pef_cpf : !!dados.pej_cnpj ? dados.pej_cnpj : dados.pes_idestrangeiro;
        let cliente = {}
        if (!!dados.pes_id) {
            cliente = await api.get(`Cliente/BuscarByIdPessoa/${dados.pes_id}`);
        } else if (!!cpfCnpj) {
            cliente = await api.get(`Cliente/BuscarClienteCpfCnpj/${retornaSoNumeros(cpfCnpj)}`);
        } else {
            return;
        }
        let mensagem = (!!cliente.data.enderecos && cliente.data.enderecos.length === 0) ? 'Endereco' : (!!cliente.data.telefones && cliente.data.telefones.length === 0) ? 'Telefone' : '';
        if ((!!cliente.data.enderecos && cliente.data.enderecos.length !== 0) || (!!cliente.data.telefones && cliente.data.telefones.length !== 0)) {
            dispatch({ type: manutencaoActions.CHANGE, data: { dados: { ...manutencao.dados, ...{ cliente: cliente.data } } } });
            setDadosCliente(cliente.data);
            form.resetFields();
            setConsultaCliente(false);
            fecharModalBuscaCliente();
        } else {
            Modal.confirm({
                title: 'Aviso!',
                content: `${mensagem} do cliente não cadastrado! \n Deseja ajustar?`,
                okText: 'Ajustar',
                cancelText: 'Não ajustar',
                onCancel() {
                    dispatch({ type: manutencaoActions.CHANGE, data: { dados: { ...manutencao.dados, ...{ cliente: cliente.data } } } });
                    setDadosCliente(cliente.data);
                },
                onOk() {
                    dispatch({ type: drawerActions.CHANGE, data: { showDrawer: true } });
                    dispatch({ type: manutencaoActions.CHANGE, data: { dados: dados } });
                }
            })
            form.resetFields();
            setConsultaCliente(false);
            fecharModalBuscaCliente();
        }
    }

    async function buscarCliente() {
        let dados = form.getFieldsValue();
        if (!!dados.cpfCnpj) {
            dados.cpfCnpj = retornaSoNumeros(dados.cpfCnpj);//dados.cpfCnpj.replaceAll('-', '').replaceAll('.', '').replaceAll('/', '');
            let _cpfCnpjValid = false;
            if (dados.cpfCnpj.length === 11) _cpfCnpjValid = validarCpf(dados.cpfCnpj)
            if (dados.cpfCnpj.length === 14) _cpfCnpjValid = validarCnpj(dados.cpfCnpj)
            if (_cpfCnpjValid) {
                api.get(`Cliente/BuscarClienteCpfCnpj/${dados.cpfCnpj}`).then(
                    res => {
                        if (!!res.data) {
                            dispatch({ type: listagemActions.CHANGE, data: { campoFiltro: 'Filtro', filtro: `${dados.cpfCnpj}`, ordem: '+pes_nome' } });
                            setConsultaCliente(true);
                        }
                    }
                )
            }
            else {
                form.setFieldsValue({ cpfCnpj: '' });
                Modal.warning({
                    title: 'Aviso!',
                    content: `CPF/CNPJ inválido ! `,
                    onOk() {
                        form.setFieldsValue({ cpfCnpj: '' });
                    }
                })
            }
        } else {
            if (!!dados.pes_nome) {
                dispatch({ type: listagemActions.CHANGE, data: { campoFiltro: 'Filtro', filtro: `${dados.pes_nome}`, ordem: '+pes_nome' } });
                setConsultaCliente(true);
            } else {
                // dispatch({ type: listagemActions.CHANGE, data: { campoFiltro: 'Filtro', filtro: '', ordem: '+pes_nome' } });
                setConsultaCliente(false);
            }
        }
    }

    function adicionarSemPesquisa() {
        let dadosTemp = {
            pes_nome: !!form.getFieldValue().pes_nome ? form.getFieldValue().pes_nome : 'CONSUMIDOR FINAL',
            cpfCnpj: !!form.getFieldValue().cpfCnpj ? form.getFieldValue().cpfCnpj : ''
        }
        setDadosCliente(dadosTemp);
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: { ...manutencao.dados, ...{ cliente: dadosTemp } } } });
        form.resetFields();
        fecharModalBuscaCliente();
    }

    useEffect(() => {
        setTimeout(() => {
            try {
                let element = document.getElementById("cpf");
                if (element) {
                    element.focus();
                }
            } catch (error) {
                console.log('error :>> ', error);
            }
        });
    }, [exibirModalBuscaCliente]);

    useEffect(() => {
        if (manutencao.voltaVenda) {
            if (!!manutencao.dados && !!manutencao.dados.clienteDados) {
                adicionarCliente(manutencao.dados.clienteDados);
            }
        }
    }, [manutencao.voltaVenda])

    return (
        <Modal centered
            title="Cliente"
            open={exibirModalBuscaCliente}
            getContainer={false}
            onCancel={onCloseModal}
            onOk={() => {
                adicionarSemPesquisa();
            }}
            okText={
                <>
                    <CheckOutlined /> Ok
                </>
            }
            cancelText={
                <>
                    <CloseOutlined /> Cancelar
                </>
            }
            maskClosable={false}
            width={720}
        >
            <div className="col-modal">
                {!consultaCliente === true &&
                    <>
                        <Row>
                            <Col span={24}>
                                <Typography.Text>
                                    Informe os dados abaixo, para selecionar um cliente.
                                </Typography.Text>
                            </Col>
                        </Row>
                        <Form layout="vertical" name="buscarCliente" form={form}>
                            <Row className="m-t-8" justify="space-between" gutter={[8, 0]}>
                                <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={24}>
                                    <Form.Item label="CPF/CNPJ" name="cpfCnpj">
                                        <CpfCnpj
                                            className='inputCpfCnpj'
                                            placeholder="Informe o CPF ou CNPJ"
                                            id="cpf"
                                            value={cpfCnpj}
                                            onBlur={() => { buscarCliente() }}
                                            onChange={(event, type) => {
                                                setCpfCnpj(event.target.value);
                                                setMask(type === "CPF");
                                            }} />
                                    </Form.Item>
                                </Col>
                                <Col xxl={12} xl={12} lg={12} md={12} sm={11} xs={24}>
                                    <Form.Item label="Nome" name="pes_nome">
                                        <Input placeholder="Informe o Nome" onPressEnter={() => { buscarCliente() }} />
                                    </Form.Item>
                                </Col>
                                <Col xxl={5} xl={5} lg={5} md={5} sm={6} xs={24} className="t-mob-573">
                                    <Button icon={<SearchOutlined />} onClick={() => { buscarCliente() }} block> Buscar Cliente </Button>
                                </Col>
                            </Row>
                        </Form>
                    </>
                }
                {!consultaCliente === false &&
                    <>
                        <Form layout="vertical" name="manutencaoBuscarCliente" form={formulario}>
                            <Row justify="space-between" gutter={[8, 0]}>
                                <Col>
                                    <Button icon={<ArrowLeftOutlined />} onClick={() => setConsultaCliente(false)}> Voltar </Button>
                                </Col>
                                <Col>
                                    <Button type="primary" icon={<PlusOutlined />} onClick={() => {
                                        setConsultaCliente(false);
                                        fecharModalBuscaCliente();
                                        dispatch({ type: drawerActions.CHANGE, data: { showDrawer: true } });
                                    }}>
                                        Cadastrar Cliente
                                    </Button>
                                </Col>
                            </Row>
                            <Row align="middle" className="tabela">
                                <TabelaDados url="Cliente/Listar" colunas={
                                    [
                                        {
                                            title: 'CPF/CNPJ',
                                            width: '25%',
                                            render: ({ pej_cnpj, pef_cpf }) => (
                                                <div>
                                                    <Row align="left" justify="left">
                                                        <Col>
                                                            {!!pef_cpf ? MaskFormat(pef_cpf, 'Não Informado', true) : !!pej_cnpj ? MaskFormat(pej_cnpj, 'Não Informado', true) : 'Não Informado'}
                                                        </Col>
                                                    </Row>
                                                </div>
                                            ),
                                        },
                                        {
                                            title: 'Cliente',
                                            width: '55%',
                                            render: ({ pes_nome }) => (
                                                <div>
                                                    <Row>
                                                        <Col>
                                                            {pes_nome}
                                                        </Col>
                                                    </Row>
                                                </div>
                                            ),
                                        },
                                        {
                                            title: 'Ações',
                                            dataIndex: '',
                                            align: 'right',
                                            key: 'x',
                                            width: '20%',
                                            render: (record) => (
                                                <div>
                                                    <Button onClick={() => adicionarCliente(record)} type="primary">
                                                        Selecionar <ArrowRightOutlined />
                                                    </Button>
                                                </div>
                                            ),
                                        },
                                    ]} scroll={{ y: 400 }}
                                />
                            </Row>
                        </Form>
                    </>
                }
            </div>
            <DrawerCliente editando={editando} setEditando={setEditando} adicionarCliente={adicionarCliente}/>
        </Modal>
    );
}