import React, { useState, useEffect } from "react";
import { Row, Col, Button, Table, Form, Checkbox, Dropdown, Menu, Tag, Tooltip, Select, Modal, notification, InputNumber } from "antd";
import { ArrowLeftOutlined, EyeOutlined, DollarCircleOutlined, FileDoneOutlined, CloseOutlined, SaveOutlined, MoreOutlined, CheckCircleOutlined, DollarOutlined, SyncOutlined, ReloadOutlined, EditOutlined, DeleteOutlined, ArrowUpOutlined, ArrowDownOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import moment from 'moment';

import api from "../../services/api";
import { useStateValue } from "../../state";
import { FormatNumber } from "../../ValueObjects";
import DrawerReparcelamento from "./drawerReparcelamento";
import { getCnpjEmpresa, getLocal, getNomeUsuario } from "../../services/auth";
import { Editar, Excluir, InputPreco, Data } from "../../components";
import DrawerDetalheNotaFiscal from "./drawerDetalheNotaFiscal";
import { dadosPdvActions, drawerActions, manutencaoActions } from "../../actions";
import { ModalBandeiraCartao, ModalCheque, ModalEditarParcela } from "../../components/modals";
import { compararDatas, diferencaDatas, isObjetoDiffVazio, info, novaAbaNavegador, carregarDadosPdv } from "../../services/funcoes";
import apiPayer from "../../services/apiPayer";

export default function LocalizarParcela({ setListaParcelasCliente, setDadosCliente, dadosCliente, setShowSearchBar, listaParcelasPeriodo, exibirParcelasPeriodo }) {

    const [formulario] = Form.useForm();
    const [{ manutencao, dadosPdv }, dispatch] = useStateValue();
    const [listaParcelas, setListaParcelas] = useState([]);
    const [listaParcelasReceber, setListaParcelasReceber] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [listaCondicaoPagamento, setListaCondicaoPagamento] = useState([]);
    const [listaContaCorrente, setListaContaCorrente] = useState([]);
    const [listaContaGerencialAgrupador, setListaContaGerencialAgrupador] = useState([]);
    const [listaContaGerencial, setListaContaGerencial] = useState([]);
    const [listaParcelasCartao, setListaParcelasCartao] = useState([]);
    const [listaTotalizadores, setListaTotalizadores] = useState({});
    const [dadosLinhaEditar, setDadosLinhaEditar] = useState([]);
    const [openModalEditarParcela, setOpenModalEditarParcela] = useState(false);
    const [openModalBandeiraCartao, setOpenModalBandeiraCartao] = useState(false);
    const [totalSelecionados, setTotalSelecionados] = useState([]);
    const [dadosModalNota, setDadosModalNota] = useState({});
    const [listaTotalizadoresReparcelar, setListaTotalizadoresReparcelar] = useState({});
    const [openModalDetalhes, setOpenModalDetalhes] = useState(false);
    const [listaParcelasReparcelar, setListaParcelasReparcelar] = useState([]);
    const [totalReceberParcelas, setTotalReceberParcelas] = useState(0);
    const [mostrarParcelasReceber, setMostrarParcelasReceber] = useState(false);
    const [carregando, setCarregando] = useState(false);
    const [parametrosEmpresa, setParametrosEmpresa] = useState({});
    const [showDrawerReparcelamento, setShowDrawerReparcelamento] = useState(false);
    const [caixaCadastrada, setCaixaCadastrada] = useState(false);
    const [semCondicao, setSemCondicao] = useState(false);
    const [ehCheque, setEhCheque] = useState(false);
    const [openModalCheque, setOpenModalCheque] = useState(false);
    const [dadosCheque, setDadosCheque] = useState({});
    const [pesquisaCodBarras, setPesquisaCodBarras] = useState([]);
    const [alterarValorPago, setAlterarValorPago] = useState(true);
    const [listaFormaPagamento, setListaFormaPagamento] = useState([]);
    const [dadosCartao, setDadosCartao] = useState({});

    let modalInstance = null;

    const rowSelection = {
        selectedRowKeys,
        onSelect: (record, selected, selectedRows) => { onSelect(record, selected, selectedRows) },
        onSelectAll: onSelectAll,
    };

    var tipoFormaPagamento = {
        fpDinheiro: 1,
        fpCheque: 2,
        fpCartaoCredito: 3,
        fpCartaoDebito: 4,
        fpCreditoLoja: 5,
        fpValeAlimentacao: 10,
        fpValeRefeicao: 11,
        fpValePresente: 12,
        fpValeCombustivel: 13,
        fpDuplicataMercantil: 14,
        fpBoletoBancario: 15,
        fpDepositoBancario: 16,
        fpPixDinamico: 17,
        fpTransferenciaBanacria: 18,
        fpCashback: 19,
        fpPixEstatico: 20,
        fpCreditoEmLoja: 21,
        fpFalhaDeHardware: 22,
        fpSemPagamento: 90,
        fpOutro: 99
    };

    const tipoFormaPagExistente = [
        tipoFormaPagamento.fpDinheiro,
        tipoFormaPagamento.fpCheque,
        tipoFormaPagamento.fpCartaoCredito,
        tipoFormaPagamento.fpCartaoDebito,
        tipoFormaPagamento.fpDeposito,
        tipoFormaPagamento.fpPixDinamico,
        tipoFormaPagamento.fpPixEstatico,
        tipoFormaPagamento.fpTransferencia
    ];

    const statusPagamentoIntegraPagto = {
        Aprovada: 1,
        Rejeitada: 2,
        Pendente: 3,
        Cancelada: 4,
        Abortada: 5,
        Finalizada: 6,
        NaoAutorizada: 7,
    }

    useEffect(() => {
        if (!!!dadosPdv.pdv) {
            verificaPdv();
        }
    }, [])

    useEffect(() => {
        if (isObjetoDiffVazio(parametrosEmpresa) && !!listaParcelasPeriodo) {
            listarParcelas([...listaParcelasPeriodo]);
        }
        if (!!!formulario.getFieldValue().dataPagamentoRecebimento) {
            formulario.setFieldsValue({ dataPagamentoRecebimento: moment(new Date()) });
        }
    }, [listaParcelasPeriodo, parametrosEmpresa]);

    async function verificaPdv() {
        let res = await carregarDadosPdv();
        dispatch({ type: dadosPdvActions.CHANGE, data: { pdv: res } });
    }

    function onSelect(record, selected, selectedRows, callback) {
        if (!!selectedRows && selectedRows.length === 0) {
            setDadosCliente({});
        }

        let lista = [...selectedRowKeys];
        if (selected) {
            if (!isObjetoDiffVazio(dadosCliente)) {
                setDadosCliente({ pes_id: record.pes_id, pes_nome: record.pes_nome });
            } else if (isObjetoDiffVazio(dadosCliente) && dadosCliente.pes_id !== record.pes_id) {
                notification.warning({ message: `Aviso!`, description: `Parcela selecionada não corresponde ao cliente ${dadosCliente.pes_nome}` });
                return;
            }
            lista.push(record.key);
        } else {
            lista = !!selectedRows && selectedRows.length > 0 ? selectedRows.map((x) => { return x.key }) : [];
        }
        somarParcelas(lista);
        if (!!callback) {
            callback();
        }
    };

    function onSelectAll(selected, selectedRows) {
        let lista = [];
        if (!!selectedRows && selectedRows.length > 0 && selected) {
            let cliente = isObjetoDiffVazio(dadosCliente) ? dadosCliente : { pes_id: selectedRows[0].pes_id, pes_nome: selectedRows[0].pes_nome };
            let exibirMsg = false;
            selectedRows.forEach((row) => {
                if (cliente.pes_id !== row.pes_id) {
                    exibirMsg = true;
                    return;
                }
                lista.push(row.key);
            });
            if (exibirMsg) {
                notification.warning({ message: `Aviso!`, description: `Não é possível selecionar parcelas de clientes diferentes!` });
            }
            setDadosCliente(cliente);
        }
        somarParcelas(lista);
    };

    function modalParcelaLiquidada(cliente) {
        Modal.confirm({
            title: 'Parcelas liquidadas com sucesso!',
            icon: <CheckCircleOutlined />,
            content: 'Deseja continuar na tela com parcelas do cliente ou realizar uma nova pesquisa?',
            okText: 'Continuar',
            cancelText: 'Nova Pesquisa',
            centered: true,
            onOk() {
                buscarParcelasPessoa(cliente.pes_id);
            },
            onCancel() {
                setListaParcelasCliente(false);
                setDadosCliente({});
                limparListas();
            }
        })
    }

    function efetuarRecebimento(values, dadosCartao) {
        var diasTolerancia = 30;
        if (formulario.getFieldValue().dataPagamentoRecebimento) {
            if (formulario.getFieldValue().dataPagamentoRecebimento > new Date()) {
                if (diferencaDatas(formulario.getFieldValue().dataPagamentoRecebimento) > diasTolerancia) {
                    formulario.setFieldsValue({ dataPagamentoRecebimento: moment(new Date(new Date().setDate(new Date().getDate() + 30))) });
                    let dataFormatada = moment(formulario.getFieldValue().dataPagamentoRecebimento).format('DD/MM/YYYY')
                    info(`Data Movimento informada maior que tolerância de ${diasTolerancia} dias, Data Máxima ${dataFormatada}`);
                    return;
                }
            } else {
                if (diferencaDatas(formulario.getFieldValue().dataPagamentoRecebimento) > diasTolerancia) {
                    formulario.setFieldsValue({ dataPagamentoRecebimento: moment(new Date(new Date().setDate(new Date().getDate() - 30))) });
                    let dataFormatada = moment(formulario.getFieldValue().dataPagamentoRecebimento).format('DD/MM/YYYY')

                    info(`Data Movimento informada maior que tolerância de ${diasTolerancia} dias, Data Máxima ${dataFormatada}`);
                    return;
                }
            }
            values.dataPagamentoRecebimento = moment(formulario.getFieldValue().dataPagamentoRecebimento).format('YYYY-MM-DD HH:mm:ss');
        } else {
            formulario.setFieldsValue({ dataPagamentoRecebimento: moment(new Date()).format('YYYY-MM-DD HH:mm:ss') });
        }
        values.pes_id = dadosCliente.pes_id;
        values.dataPagamentoRecebimento = formulario.getFieldValue().dataPagamentoRecebimento;
        let valorTot = values.valorPago ?? values.pfp_valor;
        values.valorTotalPagoRecebido = parseFloat(valorTot);
        values.valorTotalParcelas = parseFloat(listaTotalizadores.valorTotalParcelas.toFixed(2));
        values.valorTotalDesconto = parseFloat(listaTotalizadores.valorDesconto.toFixed(2));
        values.valorTotalJuros = parseFloat(listaTotalizadores.valorTotalJuros.toFixed(2));
        values.valorTotalMulta = parseFloat(listaTotalizadores.valorTotalMulta.toFixed(2));
        values.cpr_pagarreceber = 1;

        values.formaPgto = tratarDadosFormaPagamento(values, dadosCartao);
        values.parcelasQuitadas = listaParcelasReceber;
        setCarregando(true);
        api.post('ContasPagarReceber/RecebimentoParcelas', values).then(
            (res) => {
                if (res.data) {
                    let dadosParcelas = [];
                    values.parcelasQuitadas.forEach((parcela) => {
                        if (parcela.valorPagoRecebido > 0) {
                            dadosParcelas.push({
                                pes_id: parcela.pes_id,
                                prc_id: parcela.prc_id,
                                loc_id: getLocal(),
                                usu_nome: getNomeUsuario(),
                                fpg_id: values.fpg_id,
                                cpg_id: values.cpg_id
                            });
                        }
                    });
                    let dadosReceber = btoa(JSON.stringify(dadosParcelas));
                    novaAbaNavegador(`ContasPagarReceber/ImprimirComprovantePagamento?hashParcelas=${dadosReceber}`);
                    modalParcelaLiquidada(dadosCliente);
                }
            }
        ).catch(
            (erro) => {
                notification.warning({ message: `Aviso!`, description: `Não foi possivel liquidar parcelas!` });
            }
        ).finally(
            () => {
                setCarregando(false);
            }
        )
    }

    function tratarDadosFormaPagamento(values, dadosCartao) {
        let dadosFormaPagamento = {
            fpg_id: values.fpg_id,
            ctc_id: !!values.ctc_id ? values.ctc_id : null,
            cpg_id: !!values.cpg_id ? values.cpg_id : null,
            valorFormaPgto: values.valorTotalPagoRecebido,
            valorDescontoFormaPgto: 0,
            valorJurosFormaPgto: 0,
            valorMultaFormaPgto: 0,
            bce_id: dadosCartao?.bandeira?.bce_id,
            rcc_nsu: dadosCartao?.nsu,
            imp_id: values?.imp_id ?? null
        }
        return [dadosFormaPagamento];
    }

    function buscarParcelasPessoa(cliente) {
        let cli = !!cliente ? cliente : dadosCliente.pes_id;
        if (!!cli) {
            api.get(`Parcela/ListarParcelas?PagarOuReceber=1&IdPessoa=${cli}&StatusParcela=0,1&Estornado=false&PageSize=1000&Order=prc_datavencimento`).then(
                res => {
                    listarParcelas(res.data.items, true);
                }
            ).catch(error => {
                console.log(error);
            })
            if (!!cliente) {
                setMostrarParcelasReceber(false);
            }
        }
    }

    function excluirRegistro(dados) {
        Modal.confirm({
            title: `Remover da lista a parcela Nº ${dados.prc_numeroparcela} no valor de ${FormatNumber(!!dados.prc_valor ? dados.prc_valor : 0, true)}?`,
            icon: <ExclamationCircleOutlined />,
            onOk() {
                let listaTemp = [...listaParcelasReceber];
                let index = listaTemp.findIndex(item => item.prc_codigobarras === dados.prc_codigobarras);
                listaTemp.splice(index, 1);
                if (listaTemp.length === 0) {
                    setDadosCliente({});
                }
                setListaParcelasReceber(listaTemp);
            }
        });
    };

    async function validarFormaPagamento(valor) {
        let dados = (await api.get(`FormaPagamento/Get?id=${valor}`)).data;
        if (dados?.ctc_id != null) {
            formulario.setFieldsValue({ ctc_id: dados.ctc_id });
        } else {
            formulario.setFieldsValue({ ctc_id: dadosPdv?.pdv?.ctc_id });
        }
        // switch (dados.fpg_tipopagamento) {
        //     case tipoFormaPagamento.fpCartaoDebito:
        //     case tipoFormaPagamento.fpCartaoCredito:
        //         if (dados.fpg_tipointegracao == null)
        //             setOpenModalBandeiraCartao(true);
        //         break;
        // }
    }

    // function pagamentoCartao(dados, dadosCartao) {
    //     setDadosCartao(dadosCartao);
    //     efetuarRecebimento(dados);
    // };

    async function verificarFormaPagamento(values) {
        let formaPagamentoSelecionada = (await api.get(`FormaPagamento/Get?id=${values.fpg_id}`)).data;
        formaPagamentoSelecionada.pfp_valor = values.valorPago;
        formaPagamentoSelecionada.cpg_id = values.cpg_id;
        switch (formaPagamentoSelecionada.fpg_tipopagamento) {
            case tipoFormaPagamento.fpDinheiro:
            case tipoFormaPagamento.fpPixDinamico:
            case tipoFormaPagamento.fpPixEstatico:
            case tipoFormaPagamento.fpDepositoBancario:
            case tipoFormaPagamento.fpTransferenciaBancaria:
                efetuarRecebimento(values, null);
                break;
            case tipoFormaPagamento.fpCheque:
                break;
            case tipoFormaPagamento.fpCartaoDebito:
                values.credito = false;
                formaPagamentoSelecionada.parcelas = await calcularParcelasCartao(values);
                dispatch({ type: manutencaoActions.CHANGE, data: { dados: { ...manutencao.dados, ...values } } });
                if (formaPagamentoSelecionada.fpg_tipointegracao == null) {
                    setOpenModalBandeiraCartao(true);
                } else {
                    verificaIntegracaoPagamento(formaPagamentoSelecionada);
                }
                break;
            case tipoFormaPagamento.fpCartaoCredito:
                values.credito = true;
                formaPagamentoSelecionada.parcelas = await calcularParcelasCartao(values);
                dispatch({ type: manutencaoActions.CHANGE, data: { dados: { ...manutencao.dados, ...values } } });
                if (formaPagamentoSelecionada.fpg_tipointegracao == null) {
                    setOpenModalBandeiraCartao(true);
                } else {
                    verificaIntegracaoPagamento(formaPagamentoSelecionada);
                }
                break;
        };
    };

    async function verificaIntegracaoPagamento(values) {
        let dadosIntegracaoPos = {};
        let cnpjEmpresa = getCnpjEmpresa();
        if (values.fpg_tipointegracao != null) {
            if (values.fpg_tipointegracao == 1) {// vero
                let tipopgto = 0;
                let metodopgto = 0;
                let submetodopgto = 0;
                switch (values.fpg_tipopagamento) {
                    case tipoFormaPagamento.fpCartaoCredito:
                        tipopgto = 2;
                        metodopgto = 1;
                        submetodopgto = 4;
                        if (values.parcelas?.length == 1) {
                            submetodopgto = 1
                        }
                        break;
                    case tipoFormaPagamento.fpCartaoDebito:
                        tipopgto = 3;
                        metodopgto = 1;
                        submetodopgto = 1;
                        if (values.parcelas?.length >= 1) {
                            submetodopgto = 3
                        }
                        break;
                    case tipoFormaPagamento.fpPixDinamico:
                        tipopgto = 1;
                        metodopgto = 3;
                        submetodopgto = 1
                        break;
                }
                dadosIntegracaoPos = {
                    imp_cnpjlocal: cnpjEmpresa,
                    imp_valor: values.pfp_valor,
                    imp_tipopgto: tipopgto,
                    imp_metodopgto: metodopgto,
                    imp_submetodopgto: submetodopgto,
                    imp_qtdeparcelas: values.parcelas?.length ?? 0,
                }
                if (!isObjetoDiffVazio(values.integrapgto)) {
                    values.integrapgtook = false;
                    values.integrapgto = await criaRegistroIntegraPagto(dadosIntegracaoPos);
                }
                values.imp_id = values.integrapgto.imp_id;
            } else if (values.fpg_tipointegracao == 2) {//payer
                let _paymentType = "";
                let _paymentMethodSubType = "";
                let _installments = "";
                let _paymentMethod = "";
                let _command = "payment"

                if (values.fpg_tipopagamento === tipoFormaPagamento.fpCartaoCredito) {
                    _paymentMethod = "Card";
                    _paymentType = "Credit";
                    _paymentMethodSubType = "Financed_no_Fees";

                    if (values.parcelas?.length == 1) {
                        _paymentMethodSubType = "Full_Payment";
                    } else {
                        _installments = (values.parcelas?.length).toString();
                    }
                }
                if (values.fpg_tipopagamento === tipoFormaPagamento.fpCartaoDebito) {
                    if (values.parcelas?.length >= 1) {
                        if (values.parcelas.length == 1) {
                            _paymentMethodSubType = "Predated_Debit";
                        } else {
                            _paymentMethodSubType = "Financed_Debit";
                        }
                        _installments = (values.parcelas?.length).toString();
                    } else {
                        _paymentMethodSubType = "Full_Payment";
                    }
                    _paymentMethod = "Card";
                    _paymentType = "Debit"
                }
                if (values.fpg_tipopagamento === tipoFormaPagamento.fpPixDinamico) {
                    _paymentType = "Cash";
                    _paymentMethod = "Pix";
                }

                dadosIntegracaoPos = {
                    command: _command,
                    value: parseFloat(values.pfp_valor),
                    paymentMethod: _paymentMethod,
                    paymentType: _paymentType,
                    paymentMethodSubType: _paymentMethodSubType,
                    installments: _installments
                };
                if (!isObjetoDiffVazio(values.integrapgto)) {
                    values.integrapgtook = false;
                    let retorno = await apiPayer.post('Client/Request', dadosIntegracaoPos);
                }
            }
            modalAguardandoPagamento(values);
        }
    }

    async function criaRegistroIntegraPagto(dadosIntegracaoPos) {
        let retorno;
        try {
            retorno = (await api.post(`IntegraPagto/Incluir`, dadosIntegracaoPos)).data;
        } catch (error) {
            notification.warning({ description: 'Erro ao mandar o pagamento para a máquina!', message: 'Aviso!' });
            console.log(error);
            return false;
        }
        return retorno;
    }

    function modalAguardandoPagamento(values) {
        if (modalInstance !== null) {
            modalInstance.destroy();
        }
        modalInstance = Modal.confirm({

            title: 'Aviso',
            content: 'Aguardando Pagamento!',
            okText: 'Pagamento Efetuado',
            cancelText: 'Cancelar Pagamento',

            onOk: () => {
                verificarPagamentoMaquina(values);
            },
            onCancel: () => {
                cancelarPagamentoMaquina(values);
            }
        });
    }

    async function verificarPagamentoMaquina(values) {
        if (values.fpg_tipopagamento === tipoFormaPagamento.fpCartaoCredito || values.fpg_tipopagamento === tipoFormaPagamento.fpCartaoDebito || values.fpg_tipopagamento === tipoFormaPagamento.fpPixDinamico) {
            if (values.fpg_tipointegracao != null && values?.integrapgtook == false && values.fpg_tipointegracao == 1) {
                let retorno = (await api.get(`IntegraPagto/Buscar/${values.integrapgto.imp_id}`)).data;
                if (retorno.imp_statuspgto == statusPagamentoIntegraPagto.Pendente) {
                    if (modalInstance !== null) {
                        modalInstance.destroy();
                    }
                    modalAguardandoPagamento(values);
                } else if (retorno.imp_statuspgto == statusPagamentoIntegraPagto.Aprovada) {
                    values.integrapgtook = true;
                    values.integrapgto = retorno;
                    values.rcc_nsu = retorno.imp_autorizacao;
                } else if (retorno.imp_statuspgto == statusPagamentoIntegraPagto.NaoAutorizada) {
                    notification.warning({ description: 'Pagamento não Autorizado!', message: 'Aviso!' });
                } else if (retorno.imp_statuspgto == statusPagamentoIntegraPagto.Cancelada) {
                    notification.warning({ description: 'Pagamento Cancelado!', message: 'Aviso!' });
                }
            }
            if (values.fpg_tipointegracao != null && values.integrapgtook == false && values.fpg_tipointegracao == 2) {
                let retorno = (await apiPayer.get('Client/Response')).data;
                if (retorno.statusTransaction == "PENDING") {
                    return;
                } else if (retorno.statusTransaction == "APPROVED") {
                    let ret = (await api.post(`IntegraPagto/IncluirPayer`, retorno)).data;
                    values.integrapgtook = true;
                    values.integrapgto = ret;
                    values.rcc_nsu = ret.authorizerId;
                    values.imp_id = ret.imp_id;
                } else if (retorno.statusTransaction == "UNAUTHORIZED") {
                    notification.warning({ description: 'Pagamento não Autorizado, verifique no Checkout Payer e tente novamente!', message: 'Aviso!' });
                    return;
                    //analisaFormaPagamento(docfiscal);
                } else if (retorno.statusTransaction == "ABORTED") {
                    notification.warning({ description: 'Pagamento Cancelado!', message: 'Aviso!' });
                }
            }
        }
        await verificaTodosPagamentos(values);
    }

    async function verificaTodosPagamentos(values) {
        let finalizarVenda = true;
        if (values.fpg_tipopagamento === tipoFormaPagamento.fpCartaoCredito || values.fpg_tipopagamento === tipoFormaPagamento.fpCartaoDebito || values.fpg_tipopagamento === tipoFormaPagamento.fpPixEstatico) {
            if (values.fpg_tipointegracao != null && values.integrapgtook == false) {
                finalizarVenda = false;
            }
        }
        if (finalizarVenda == true) {
            efetuarRecebimento(values);
        } else {
            modalAguardandoPagamento(values);
        }
    }

    async function cancelarPagamentoMaquina(values) {
        let retorno = (await api.put(`IntegraPagto/CancelarPagamento?Id=${values.integrapgto?.imp_id}`)).data;
        if (retorno) {
            values.integrapgtook = false;
            values.integrapgto = null;
        } else {
            notification.warning({ description: 'Erro ao efetuar o cancelamento!', message: 'Aviso!' });
            modalAguardandoPagamento(values)
        }
    }

    function limparListas() {
        setShowSearchBar(false);
        setMostrarParcelasReceber(false);
        setPesquisaCodBarras(true);
        setListaParcelasReceber([]);
        setSelectedRowKeys([]);
        setListaParcelasCliente(false);
        setDadosCliente({});
        if (!!exibirParcelasPeriodo) {
            exibirParcelasPeriodo.setExibirParcelasPeriodo(false);
        }
        formulario.resetFields();
    };

    function editarParcela(linha) {
        setDadosLinhaEditar(linha);
        setOpenModalEditarParcela(true);
    };

    function buscarCondicaoPagamento(valor) {
        api.get(`CondicaoPagamento/Listar?codigoFormaPagamento=${valor}&filtro=`).then(res => {
            setListaCondicaoPagamento(res.data.items);
            setSemCondicao(res.data.items.length > 0);
        });
    };

    function zerarJuros() {
        let listaTemp = [...listaParcelasReceber];
        let totalParcelas = 0;
        listaTemp.forEach(item => {
            let valorTotal = parseFloat((!!item.valorOriginal ? item.valorOriginal : 0)) - parseFloat((!!item.valorQuitado ? item.valorQuitado : 0));
            item.valorJuros = 0;
            if (parseFloat(item.valorDesconto) > valorTotal) {
                item.valorDesconto = valorTotal;
            }
            item.zerouJuros = true;
            item.valorAReceber = parseFloat((valorTotal - parseFloat(item.valorDesconto) + parseFloat(item.valorJuros) + parseFloat(item.valorMulta)).toFixed(2));
            item.valorPagoRecebido = item.valorAReceber;
            item.valorPago = item.valorAReceber;
            item.prc_valor = item.valorAReceber;
            totalParcelas += item.valorAReceber;
        });
        setTotalReceberParcelas(parseFloat(totalParcelas))
        setListaParcelasReceber(listaTemp);
    };

    function zerarMulta() {
        let listaTemp = [...listaParcelasReceber];
        let totalParcelas = 0;
        listaTemp.forEach(item => {
            let valorTotal = parseFloat((!!item.valorOriginal ? item.valorOriginal : 0)) - parseFloat((!!item.valorQuitado ? item.valorQuitado : 0));
            item.valorMulta = 0;
            if (parseFloat(item.valorDesconto) > valorTotal) {
                item.valorDesconto = valorTotal;
            }
            item.zerouMulta = true;
            item.valorAReceber = parseFloat((valorTotal - parseFloat(item.valorDesconto) + parseFloat(item.valorJuros) + parseFloat(item.valorMulta)).toFixed(2));
            item.valorPagoRecebido = item.valorAReceber;
            item.valorPago = item.valorAReceber;
            item.prc_valor = item.valorAReceber;
            totalParcelas += item.valorAReceber;
        });
        setTotalReceberParcelas(parseFloat(totalParcelas))
        setListaParcelasReceber(listaTemp);
    };

    function reparcelarSelecionados(listaParcelas, selectedRowKeys) {
        let listaTemp = [];
        listaParcelas.map(parcela => {
            if (selectedRowKeys.indexOf(parcela.key) >= 0) {
                listaTemp.push(parcela);
            }
        });
        let lista = {};
        lista.valorTotalJuros = 0;
        lista.valorTotalMulta = 0;
        lista.valorDesconto = 0;
        lista.valorAReceber = 0;
        if (listaTemp.length > 0) {
            listaTemp.map(item => {
                let valorTotal = parseFloat((!!item.valorOriginal ? item.valorOriginal : 0)) - parseFloat((!!item.valorQuitado ? item.valorQuitado : 0));
                lista.valorTotalJuros += parseFloat(item.valorJuros);
                lista.valorTotalMulta += parseFloat(item.valorMulta);
                lista.valorDesconto += parseFloat(item.valorDesconto);
                lista.valorAReceber += parseFloat((valorTotal - parseFloat(item.valorDesconto) + parseFloat(item.valorJuros) + parseFloat(item.valorMulta)).toFixed(2));
            });
        }
        setListaTotalizadoresReparcelar(lista);
        setListaParcelasReparcelar(listaTemp);
        dispatch({ type: drawerActions.CHANGE, data: { showDrawer: true } });
    };

    function listarParcelas(dados, ignorarSelecionados = false) {
        let totalReceber = 0;
        let parcelasReceber = [];
        let keys = [];
        if (!ignorarSelecionados && listaParcelasReceber.length > 0) {
            listaParcelasReceber.map((parcela) => {
                keys.push(parcela.key);
            });
            setSelectedRowKeys(keys);
        } else {
            somarParcelas([]); //limpar lista
        }
        dados.forEach((item) => {
            let valorReceber;
            item.vencida = compararDatas(item.prc_datavencimento, new Date());
            item.numeroDiasVencida = 0;
            item.valorJuros = 0;
            item.zerouJuros = false;
            item.valorMulta = 0;
            item.zerouMulta = false;
            item.key = item.cpr_id.toString().concat(item.prc_id);
            if (item.vencida) {
                item.numeroDiasVencida = diferencaDatas(item.prc_datavencimento);
                if (!(!!parametrosEmpresa.par_diastoleranciacobrancajuro)) {
                    valorReceber = parseFloat((item.prc_valor - item.valorQuitado).toFixed(2));
                    item.valorJuros = parseFloat((((valorReceber * (parametrosEmpresa.par_percjuromesparcelavencida / 30)) / 100) * item.numeroDiasVencida).toFixed(2));
                } else if (item.numeroDiasVencida > parametrosEmpresa.par_diastoleranciacobrancajuro) {
                    valorReceber = parseFloat((item.prc_valor - item.valorQuitado).toFixed(2));
                    item.valorJuros = parseFloat((((valorReceber * (parametrosEmpresa.par_percjuromesparcelavencida / 30)) / 100) * item.numeroDiasVencida).toFixed(2));
                    if(parametrosEmpresa.par_tipocalculomulta == 1){
                        item.valorMulta= !!parametrosEmpresa.par_multaparcelavencida ? parametrosEmpresa.par_multaparcelavencida : 0;
                    } else if(parametrosEmpresa.par_tipocalculomulta == 2){
                        item.valorMulta= !!parametrosEmpresa.par_multaparcelavencida ? (item.prc_valor * ( parametrosEmpresa.par_multaparcelavencida/100)) : 0;
                    }
                }
            }
            item.valorOriginal = parseFloat(item.prc_valor);
            item.prc_valor = parseFloat((item.prc_valor - item.valorQuitado + item.valorJuros + parseFloat(item.valorMulta))).toFixed(2);
            item.valorPago = parseFloat(item.prc_valor);
            item.valorDesconto = 0.00;
            item.valorAReceber = item.prc_valor;
            totalReceber += parseFloat((item.valorPago).toFixed(2));
            parcelasReceber.push(item);
        });
        parcelasReceber.totalReceber = totalReceber;
        setListaParcelas(parcelasReceber);
        setListaParcelasReparcelar([]);
        setListaTotalizadoresReparcelar({});
    };

    function somarParcelas(keys) {
        let totais = [];
        totais.quantidade = keys.length;
        totais.valorOriginal = 0;
        totais.juros = 0;
        totais.multa = 0;
        totais.total = 0;

        keys.forEach((key) => {
            let parcela = listaParcelas.filter((item) => { return item.key === key })[0];
            totais.valorOriginal += parcela.valorOriginal;
            totais.juros += parcela.valorJuros;
            totais.multa += parseFloat(parcela.valorMulta);
            totais.total += parcela.valorPago;
        });
        setTotalSelecionados(totais);
        setSelectedRowKeys(keys);
    };

    async function calcularParcelasCartao(values) {
        let definicaoParcelamento = [];
        let parcelas = [];
        let somaParcelas = 0;
        let resto = 0;
        let numeroUltimaParcela;
        if (!!values.cpg_id) {
            let res = await api.get(`CondicaoPagamento/listarParcelamento?codigoCondicaoPagamento=${values.cpg_id}`);
            definicaoParcelamento = res.data;
            if (definicaoParcelamento.length > 0) {
                definicaoParcelamento.forEach((parcela, indice) => {
                    let valorParcela = 0;
                    valorParcela = ((parcela.pcl_percparcela / 100) * (values.valorPago)).toFixed(2);
                    parcelas.push({
                        prc_numeroparcela: indice + 1,
                        prc_valor: parseFloat(valorParcela),
                    });
                    somaParcelas += parseFloat(valorParcela);
                });
                resto = (values.valorPago - parseFloat(somaParcelas)).toFixed(2);
                resto = parseFloat(resto);
            }
            if (resto !== 0) {
                numeroUltimaParcela = 0;
                if (definicaoParcelamento.length > 0) {
                    numeroUltimaParcela = definicaoParcelamento.length - 1;
                } else {
                    numeroUltimaParcela = 1;
                }
                let valParcela = parseFloat(parcelas[numeroUltimaParcela].prc_valor);
                let totalParcela = parseFloat((valParcela + resto).toFixed(2));
                parcelas[numeroUltimaParcela].prc_valor = parseFloat(totalParcela);
            }
            return parcelas;
        }
    };

    function menuTable(dados) {
        return (
            <Menu className="text-right">
                <Menu.Item key="1">
                    <Button type="text" icon={<FileDoneOutlined />} onClick={() => {
                        let keys = [...selectedRowKeys];
                        if (selectedRowKeys.indexOf(dados.key) < 0) {
                            keys.push(dados.key);
                            onSelect(dados, true, keys, () => { receberSelecionados(listaParcelas, keys) });
                        } else {
                            receberSelecionados(listaParcelas, keys)
                        }
                    }} > Quitar Parcela</Button>
                </Menu.Item>
                <Menu.Item key="2">
                    <Button type="text" icon={<EyeOutlined />} onClick={() => {
                        showDrawerExtra(dados);
                    }}>Detalhes Nota</Button>
                </Menu.Item>
                <Menu.Item key="3">
                    <Button type="text" icon={<DollarCircleOutlined />} onClick={() => {
                        let keys = [...selectedRowKeys];
                        if (selectedRowKeys.indexOf(dados.key) < 0) {
                            keys.push(dados.key);
                            onSelect(dados, true, keys, () => { reparcelarSelecionados(listaParcelas, keys); });
                        } else {
                            reparcelarSelecionados(listaParcelas, keys);
                        }

                    }}> Reparcelar Parcela</Button>
                </Menu.Item>
            </Menu>
        )
    }

    function selecionarVencidas(e) {
        let keys = [];
        if (e.target.checked) {
            listaParcelas.map((item) => {
                if (compararDatas(item.prc_datavencimento, null, '<')) {
                    keys.push(item.key);
                }
            });
        }
        somarParcelas(keys);
    };

    async function showDrawerExtra(dados) {
        let retorno = [];
        let vendedor = [];
        if (!!dados.ntf_id) {
            retorno = await api.get(`NotaFiscal/ListarNotaFiscal?IdNotaFiscal=${dados.ntf_id}`);
            if (retorno.status === 200) {
                retorno = retorno.data;
                if (!!retorno && !!retorno.ven_id) {
                    vendedor = await api.get(`Vendedor/RetornaVendedor?idVendedor=${retorno.ven_id}`);
                    if (vendedor.status === 200) {
                        retorno.nomeVendedor = vendedor.data.pes_nome;
                    }
                }
                retorno.itensNf.forEach((item) => {
                    let totalItemSemDesconto = parseFloat((item.nfi_qtde * item.nfi_valorunitario)).toFixed(2);
                    item.perc_desconto = parseFloat((item.nfi_valordesconto / (totalItemSemDesconto)) * 100).toFixed(2);
                    let valorDesconto = item.nfi_valordesconto;
                    item.valor_item_desconto = parseFloat(totalItemSemDesconto - valorDesconto).toFixed(2);
                });
                setDadosModalNota(retorno);
                setOpenModalDetalhes(true);
            }
        }
    };

    function calcularRecebimentoParcial() {
        let valorRecebido = parseFloat(formulario.getFieldValue("valorPago"));
        if (valorRecebido > 0) {
            let listaTemp = [...listaParcelasReceber];
            for (const item of listaTemp) {
                let valorTotal = parseFloat((!!item.valorOriginal ? item.valorOriginal : 0)) - parseFloat((!!item.valorQuitado ? item.valorQuitado : 0));
                if (item.vencida && !item.zerouJuros) {
                    if (!(!!parametrosEmpresa.par_diastoleranciacobrancajuro)) {
                        item.valorJuros = parseFloat((((valorTotal * (parametrosEmpresa.par_percjuromesparcelavencida / 30)) / 100) * item.numeroDiasVencida).toFixed(2));
                    } else if (item.numeroDiasVencida > parametrosEmpresa.par_diastoleranciacobrancajuro) {
                        item.valorJuros = parseFloat((((valorTotal * (parametrosEmpresa.par_percjuromesparcelavencida / 30)) / 100) * item.numeroDiasVencida).toFixed(2));
                    }
                }
                if(item.vencida && !item.zerouMulta){
                    if(parametrosEmpresa.par_tipocalculomulta == 1){
                        item.valorMulta= !!parametrosEmpresa.par_multaparcelavencida ? parametrosEmpresa.par_multaparcelavencida : 0;
                    } else if(parametrosEmpresa.par_tipocalculomulta == 2){
                        item.valorMulta= !!parametrosEmpresa.par_multaparcelavencida ? (valorTotal * ( parametrosEmpresa.par_multaparcelavencida/100)) : 0;
                    }
                }
                let valorParcela = parseFloat(((valorTotal - parseFloat(item.valorDesconto)) + parseFloat(item.valorJuros) + parseFloat(item.valorMulta)).toFixed(2));
                if (valorRecebido > valorParcela) {
                    item.valorAReceber = valorParcela;
                } else {
                    let _perc = (parseFloat(valorRecebido).toFixed(2) / valorParcela);
                    item.valorJuros = parseFloat(item.valorJuros * _perc).toFixed(2);
                    if(parametrosEmpresa.par_tipocalculomulta == 1){
                        item.valorMulta= !!parametrosEmpresa.par_multaparcelavencida ? parametrosEmpresa.par_multaparcelavencida : 0;
                    } else if(parametrosEmpresa.par_tipocalculomulta == 2){
                        item.valorMulta= !!parametrosEmpresa.par_multaparcelavencida ? (valorTotal * ( parametrosEmpresa.par_multaparcelavencida/100)) : 0;
                    }
                    item.valorAReceber = parseFloat(valorRecebido).toFixed(2);
                }
                item.prc_valor = item.valorAReceber;
                item.valorPagoRecebido = item.valorAReceber;
                valorRecebido += item.valorAReceber;
            }
            setAlterarValorPago(false);
            setListaParcelasReceber(listaTemp);
        } else {
            calcularSobra();
        }
    };

    async function receberSelecionados(listaParcelas, selectedRowKeys) {
        if (!!parametrosEmpresa && !!parametrosEmpresa.par_ctgrecebimentoparcela) {
            if (!!dadosPdv.pdv?.ctc_id) {
                setCaixaCadastrada(true)
            }
            formulario.setFieldsValue({ ctg_id: parametrosEmpresa.par_ctgrecebimentoparcela, ctc_id: dadosPdv.pdv?.ctc_id })
        }
        let listaReceber = [];
        let listaTemp = [...listaParcelas];
        let totalParcelas = 0;
        listaParcelas.map(parcela => {
            if (selectedRowKeys.indexOf(parcela.key) >= 0 && listaTemp.filter((item) => (item.key === parcela.key)).length > 0) {
                parcela.valorPagoRecebido = parcela.valorPago;
                listaReceber.push(parcela);
                totalParcelas += parseFloat(parcela.valorAReceber);
                //totalParcelas = parcela.reduce((acc, valor) => acc + valor.valorAReceber, 0);
            }
        });
        setMostrarParcelasReceber(true);
        setListaParcelasReceber(listaReceber);
        formulario.setFieldsValue({ valorPago: parseFloat(totalParcelas).toFixed(2) });
        setTotalReceberParcelas(parseFloat(totalParcelas))
    };

    function calcularSobra(lista = { ...listaTotalizadores }) {
        let sobra = parseFloat((parseFloat(!!formulario.getFieldValue("valorPago") ? formulario.getFieldValue("valorPago") : 0) - parseFloat(lista.valorAReceber)).toFixed(2));
        lista.restanteReceber = 0;
        lista.troco = 0;
        if (sobra < 0) {
            lista.restanteReceber = parseFloat((sobra * -1).toFixed(2));
        } else {
            lista.troco = parseFloat(sobra);
        }
        setAlterarValorPago(true);
        setListaTotalizadores(lista);
    };

    function salvarDadosModal(values) {
        let listaTemp = [...listaParcelasReceber];
        let linha = listaTemp.filter((item) => { return item.prc_codigobarras === dadosLinhaEditar.prc_codigobarras })[0];
        linha.valorJuros = parseFloat(values.valorJuros);
        linha.valorMulta = parseFloat(values.valorMulta);
        linha.valorDesconto = parseFloat(values.valorDesconto);
        linha.valorPagoRecebido = parseFloat(values.valorPago);
        linha.valorAReceber = parseFloat(values.valorPago);
        linha.valorParcela = linha.valorAReceber;
        setListaParcelasReceber(listaTemp);
    };

    function adicionaDadosCheque(qtdeCheque) {
        let dadosTemp = {
            numeroCheques: qtdeCheque,
            pfp_valor: formulario.getFieldsValue().valorPago
        }
        setDadosCheque(dadosTemp);
        setOpenModalCheque(true);
    }

    useEffect(() => {
        api.get(`contaCorrente/listar?filtro=&Order=+ctc_descricao&PageSize=1000`).then(res => {
            setListaContaCorrente(res.data.items);
        });

        api.get(`ContaGerencial/ListarAtivosEstruturadoPelaCategoria?CodReceitaDespesa=2&filtro=&PageNumber=1&PageSize=1000`).then(res => {
            setListaContaGerencialAgrupador(res.data.filter((item) => { return item.podeSelecionar === false }));
            setListaContaGerencial(res.data.filter((item) => { return item.podeSelecionar === true }));
        });

        api.get(`ParametroEmpresa/BuscarParametros`).then(res => {
            setParametrosEmpresa(res.data);
        })
        api.get(`FormaPagamento/Listar?PageNumber=1&PageSize=1000`).then(res => {
            if (res.status === 200) {
                setListaFormaPagamento(res.data.items);
            }
        });
        if (!!!formulario.getFieldValue().dataPagamentoRecebimento) {
            formulario.setFieldsValue({ dataPagamentoRecebimento: moment(new Date()) });
        }
    }, []);

    useEffect(() => {
        if (isObjetoDiffVazio(parametrosEmpresa)) {
            buscarParcelasPessoa();
        }
    }, [parametrosEmpresa]);

    useEffect(() => {
        if (!!!formulario.getFieldValue().dataPagamentoRecebimento) {
            formulario.setFieldsValue({ dataPagamentoRecebimento: moment(new Date()) });
        }
        let lista = {};
        lista.valorTotalParcelas = 0;
        lista.valorTotalJuros = 0;
        lista.valorTotalMulta = 0;
        lista.valorDesconto = 0;
        lista.valorAReceber = 0;
        let valorRecebido = 0;
        if (listaParcelasReceber.length > 0) {
            listaParcelasReceber.map(item => {
                let valorTotal = parseFloat((!!item.valorOriginal ? item.valorOriginal : 0)) - parseFloat((!!item.valorQuitado ? item.valorQuitado : 0));
                lista.valorTotalParcelas += parseFloat(item.valorOriginal);
                lista.valorTotalJuros += parseFloat(item.valorJuros);
                lista.valorTotalMulta += parseFloat(item.valorMulta);
                lista.valorDesconto += parseFloat(item.valorDesconto);
                lista.valorAReceber += parseFloat(((valorTotal - parseFloat(item.valorDesconto)) + parseFloat(item.valorJuros) + parseFloat(item.valorMulta)).toFixed(2));
                valorRecebido += parseFloat(item.valorAReceber);
            });
            if (alterarValorPago) {
                formulario.setFieldsValue({ valorPago: parseFloat(valorRecebido).toFixed(2) });
            }

            calcularSobra(lista);
        }
    }, [listaParcelasReceber]);

    function validaIntervaloData() {
        var diasTolerancia = 30;
        if (formulario.getFieldValue().dataPagamentoRecebimento) {
            if (formulario.getFieldValue().dataPagamentoRecebimento > new Date()) {
                if (diferencaDatas(formulario.getFieldValue().dataPagamentoRecebimento) > diasTolerancia) {
                    formulario.setFieldsValue({ dataPagamentoRecebimento: moment(new Date(new Date().setDate(new Date().getDate() + 30))) });
                    let dataFormatada = moment(formulario.getFieldValue().dataPagamentoRecebimento).format('DD/MM/YYYY')
                    info(`Data Movimento informada maior que tolerância de ${diasTolerancia} dias, Data Máxima ${dataFormatada}`);
                }
            } else {
                if (diferencaDatas(formulario.getFieldValue().dataPagamentoRecebimento) > diasTolerancia) {
                    formulario.setFieldsValue({ dataPagamentoRecebimento: moment(new Date(new Date().setDate(new Date().getDate() - 30))) });
                    let dataFormatada = moment(formulario.getFieldValue().dataPagamentoRecebimento).format('DD/MM/YYYY')
                    info(`Data Movimento informada maior que tolerância de ${diasTolerancia} dias, Data Máxima ${dataFormatada}`);
                }
            }
        }
    }
    return (
        <div>
            <Form layout="vertical">
                <Row align="middle" justify="space-between" gutter={[8, 0]}>
                    <Col>
                        <Button icon={<ArrowLeftOutlined />} type="primary" htmlType="submit" onClick={() => {
                            if (mostrarParcelasReceber) {
                                setMostrarParcelasReceber(false);
                            } else {
                                setShowSearchBar(false);
                                setListaParcelasCliente(false);
                                setDadosCliente({});
                                if (!!exibirParcelasPeriodo) {
                                    exibirParcelasPeriodo.setExibirParcelasPeriodo(false);
                                }
                            }
                        }}>
                            Voltar
                        </Button>
                    </Col>
                    <Col className="text-center">
                        Parcelas em Aberto do cliente<br />
                        <b className="f-16">{isObjetoDiffVazio(dadosCliente) ? dadosCliente.pes_nome : "Vários"}</b>
                    </Col>
                    <Col>
                        <div className="ant-input-number-input">
                            Total à receber {!!exibirParcelasPeriodo && exibirParcelasPeriodo.exibirParcelasPeriodo ? ' do período' : ' do cliente'}<br />
                            <b className="f-16">{FormatNumber(!!listaParcelas.totalReceber ? listaParcelas.totalReceber : 0, true)}</b>
                        </div>
                    </Col>
                </Row>
            </Form>
            {!mostrarParcelasReceber &&
                <div className="tabela p-b-100">
                    <Row align="middle" gutter={[8, 0]} justify="space-between">
                        <Col>
                            {(!!!exibirParcelasPeriodo || !exibirParcelasPeriodo.exibirParcelasPeriodo) && <Checkbox onChange={selecionarVencidas}>
                                Selecionar Vencidas
                            </Checkbox>}
                        </Col>
                        <Col>
                            <Row align="middle" justify="end" gutter={[8, 0]} className="m-t-5 m-b-5">
                                <Col>
                                    <Button disabled={selectedRowKeys.length === 0} onClick={() => { reparcelarSelecionados(listaParcelas, selectedRowKeys); }} icon={<SyncOutlined />} type="primary" htmlType="submit">
                                        Reparcelar Todos os Selecionados
                                    </Button>
                                </Col>
                                <Col>
                                    <Button disabled={selectedRowKeys.length === 0} onClick={() => receberSelecionados(listaParcelas, selectedRowKeys)} icon={<DollarOutlined />} htmlType="button">
                                        Receber Todos os Selecionados
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Table dataSource={listaParcelas} columns={
                        [
                            {
                                title: 'Nº Doc.',
                                width: '8%',
                                render: ({ cpr_numerodocumento }) => (
                                    <Tag color="processing" className="w-100">
                                        <b>{cpr_numerodocumento || '--'}</b>
                                    </Tag>
                                ),
                            },
                            {
                                title: 'Nº Parc.',
                                align: 'center',
                                width: '10%',
                                render: ({ prc_numeroparcela }) => (
                                    <div>
                                        <b>{prc_numeroparcela}</b>
                                    </div>
                                ),
                            },
                            {
                                title: 'Emissão',
                                width: '10%',
                                render: ({ cpr_datainclusao }) => (
                                    <div>
                                        <b>{moment(cpr_datainclusao).format('DD/MM/YYYY')}</b>
                                    </div>
                                ),
                            }, {
                                title: 'Vencimento',
                                width: '10%',
                                render: ({ prc_datavencimento, vencida }) => (
                                    <div>
                                        <b className={vencida ? "c-red" : ""}>{moment(prc_datavencimento).format('DD/MM/YYYY')}</b>
                                    </div>
                                ),
                            }, {
                                title: 'Cliente',
                                width: '10%',
                                render: ({ pes_nome }) => (
                                    <div>
                                        <b>
                                            {pes_nome}
                                        </b>
                                    </div>
                                ),
                            },
                            {
                                title: 'Valor Original (R$)',
                                align: 'right',
                                width: '10%',
                                render: ({ valorOriginal }) => (
                                    <div>
                                        <b>
                                            {FormatNumber(!!valorOriginal ? valorOriginal : 0, true)}
                                        </b>
                                    </div>
                                ),
                            }, {
                                title: 'Juros (R$)',
                                align: 'right',
                                width: '10%',
                                render: ({ valorJuros }) => (
                                    <div>
                                        <b className={!!valorJuros && parseFloat(valorJuros) > 0 ? 'c-red' : ''}>
                                            {FormatNumber(!!valorJuros ? valorJuros : 0, true)}
                                        </b>
                                    </div>
                                ),
                            }, {
                                title: 'Multa (R$)',
                                align: 'right',
                                width: '10%',
                                render: ({ valorMulta }) => (
                                    <div>
                                        <b className={!!valorMulta && parseFloat(valorMulta) > 0 ? 'c-red' : ''}>
                                            {FormatNumber(!!valorMulta ? valorMulta : 0, true)}
                                        </b>
                                    </div>
                                ),
                            }, {
                                title: 'Recebido (R$)',
                                align: 'right',
                                width: '10%',
                                render: ({ valorQuitado }) => (
                                    <div>
                                        <b>
                                            {FormatNumber(!!valorQuitado ? valorQuitado : 0, true)}
                                        </b>
                                    </div>
                                ),
                            }, {
                                title: 'A Receber (R$)',
                                align: 'right',
                                width: '10%',
                                render: ({ valorAReceber }) => (
                                    <div>
                                        <b>
                                            {FormatNumber(!!valorAReceber ? valorAReceber : 0, true)}
                                        </b>
                                    </div>
                                ),
                            },
                            {
                                align: 'center',
                                width: '5%',
                                fixed: 'right',
                                title: 'Ações',
                                render: (record) => (
                                    <div>
                                        <Row align="center" gutter={[5, 0]}>
                                            <Col>
                                                <Dropdown overlay={menuTable(record)} trigger={['click']}>
                                                    <Button onClick={e => e.preventDefault()} icon={<MoreOutlined />} />
                                                </Dropdown>
                                            </Col>
                                        </Row>
                                    </div>
                                ),
                            },
                        ]
                    } rowSelection={rowSelection} scroll={900} />
                    <div className="col-fix-rodape">
                        <Row align="middle" justify="center" gutter={[8, 0]}>
                            <Col span={4}>
                                <span className="f-14">Quantidade: </span>
                                <b className="f-16">{!!totalSelecionados.quantidade ? totalSelecionados.quantidade : 0}</b>
                            </Col>
                            <Col span={4} align="right">
                                <span className="f-14">Original (R$): </span>
                                <b className="f-16">{FormatNumber(!!totalSelecionados.valorOriginal ? totalSelecionados.valorOriginal : 0, true)}</b>
                            </Col>
                            <Col span={4} align="right">
                                <span className="f-14">Juros (R$): </span>
                                <b className="f-16">{FormatNumber(!!totalSelecionados.juros ? totalSelecionados.juros : 0, true)}</b>
                            </Col>
                            <Col span={4} align="right">
                                <span className="f-14">Multa (R$): </span>
                                <b className="f-16">{FormatNumber(!!totalSelecionados.multa ? totalSelecionados.multa : 0, true)}</b>
                            </Col>
                            <Col span={4} align="right">
                                <span className="f-14">Total (R$): </span>
                                <b className="f-16">{FormatNumber(!!totalSelecionados.total ? totalSelecionados.total : 0, true)}</b>
                            </Col>
                        </Row>
                    </div>
                </div>
            }
            <div>
                {mostrarParcelasReceber &&
                    <div>
                        <div className="tabela">
                            <Table pagination={false} dataSource={listaParcelasReceber} columns={
                                [
                                    {
                                        title: 'Nº Doc.',
                                        width: '10%',
                                        render: ({ cpr_numerodocumento }) => (
                                            <div>
                                                <b>{cpr_numerodocumento || '--'}</b>
                                            </div>
                                        ),
                                    },
                                    {
                                        title: 'Nº Parcela',
                                        align: 'left',
                                        width: '8%',
                                        render: ({ prc_numeroparcela }) => (
                                            <div>
                                                <b>{prc_numeroparcela}</b>
                                            </div>
                                        ),
                                    },
                                    {
                                        title: 'Emissão',
                                        width: '8%',
                                        render: ({ cpr_datainclusao }) => (
                                            <div>
                                                <b>{moment(cpr_datainclusao).format('DD/MM/YYYY')}</b>
                                            </div>
                                        ),
                                    }, {
                                        title: 'Vencimento',
                                        width: '8%',
                                        render: ({ prc_datavencimento, vencida }) => (
                                            <div>
                                                <b className={vencida ? "c-red" : ""}>{moment(prc_datavencimento).format('DD/MM/YYYY')}</b>
                                            </div>
                                        ),
                                    }, {
                                        title: 'Valor Original (R$)',
                                        align: 'right',
                                        width: '12%',
                                        render: ({ valorOriginal }) => (
                                            <div>
                                                <b>
                                                    {FormatNumber(!!valorOriginal ? valorOriginal : 0, true)}
                                                </b>
                                            </div>
                                        ),
                                    }, {
                                        title: () => {
                                            return (
                                                <div>
                                                    <Row gutter={[5, 0]} justify="end">
                                                        <Col>Juros</Col>
                                                        <Col>
                                                            <Tooltip title="Zerar Juros">
                                                                <Button size="small" icon={<ReloadOutlined />} onClick={zerarJuros} />
                                                            </Tooltip>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            )
                                        },
                                        align: 'right',
                                        width: '10%',
                                        render: ({ valorJuros }) => (
                                            <div>
                                                <b className={!!valorJuros && parseFloat(valorJuros) > 0 ? 'c-red' : ''}>
                                                    {FormatNumber(!!valorJuros ? valorJuros : 0, true)}
                                                </b>
                                            </div>
                                        ),
                                    }, {
                                        title: () => {
                                            return (
                                                <div>
                                                    <Row gutter={[5, 0]} justify="end">
                                                        <Col>Multa</Col>
                                                        <Col>
                                                            <Tooltip title="Zerar Multa">
                                                                <Button size="small" icon={<ReloadOutlined />} onClick={zerarMulta} />
                                                            </Tooltip>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            )
                                        },
                                        align: 'right',
                                        width: '10%',
                                        render: ({ valorMulta }) => (
                                            <div>
                                                <b className={!!valorMulta && parseFloat(valorMulta) > 0 ? 'c-red' : ''}>
                                                    {FormatNumber(!!valorMulta ? valorMulta : 0, true)}
                                                </b>
                                            </div>
                                        ),
                                    },{
                                        title: 'Recebido (R$)',
                                        align: 'right',
                                        width: '10%',
                                        render: ({ valorQuitado }) => (
                                            <div>
                                                <b>
                                                    {FormatNumber(!!valorQuitado ? valorQuitado : 0, true)}
                                                </b>
                                            </div>
                                        ),
                                    }, {
                                        title: 'A Receber (R$)',
                                        align: 'right',
                                        width: '12%',
                                        render: ({ valorPagoRecebido }) => (
                                            <div>
                                                <b>
                                                    {FormatNumber(!!valorPagoRecebido ? valorPagoRecebido : 0, true)}
                                                </b>
                                            </div>
                                        ),
                                    },
                                    {
                                        dataIndex: '',
                                        key: 'x',
                                        align: 'center',
                                        width: '8%',
                                        fixed: 'right',
                                        title: 'Ações',
                                        render: (record) => (
                                            <div>
                                                <Row align="middle" justify="center" gutter={[5, 0]}>
                                                    <Col>
                                                        <Editar onClick={() => editarParcela(record)} icon={<EditOutlined />} />
                                                    </Col>
                                                    <Col>
                                                        <Excluir onClick={() => { excluirRegistro(record) }} icon={<DeleteOutlined />} />
                                                    </Col>
                                                </Row>
                                            </div>
                                        ),
                                    },
                                ]
                            } expandable={{
                                expandedRowRender: ({ prc_codigobarras, cpr_numerodocumento }) =>
                                    <div>
                                        <Row gutter={[8, 0]}>
                                            <Col>
                                                Cód. Barras: <b> {prc_codigobarras || 'Não Informado'}</b>
                                            </Col>
                                            <Col>
                                                Nº Documento: <b> {cpr_numerodocumento || 'Não Informado'}</b>
                                            </Col>
                                        </Row>
                                    </div>,
                                expandIcon: ({ expanded, onExpand, record }) =>
                                    expanded ? (
                                        <ArrowUpOutlined onClick={e => onExpand(record, e)} />
                                    ) : (
                                        <ArrowDownOutlined onClick={e => onExpand(record, e)} />
                                    )
                            }} scroll={{ x: 900, y: 363 }} columnWidth={30} />
                            <Row>
                                <Col span={24} align="right">
                                    <b>Total a Receber (R$): {parseFloat(totalReceberParcelas).toFixed(2)}</b>
                                </Col>
                            </Row>
                        </div>
                        <Form layout="vertical" form={formulario} name="formRecebimentoParcelas" onFinish={verificarFormaPagamento}>
                            <Row gutter={[8, 0]} className="m-t-16">
                                <Col xs={24} sm={6} md={3} lg={3} xl={3}>
                                    <Data label="Data Movimento" name="dataPagamentoRecebimento" onBlur={() => validaIntervaloData()} />
                                </Col>
                                <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                                    <Form.Item label="Formas de Pagamento" name="fpg_id" rules={[{ required: true, message: 'Selecione a Forma de Pagamento' }]}>
                                        <Select allowClear onChange={valor => { buscarCondicaoPagamento(valor); validarFormaPagamento(valor) }} showSearch options={listaFormaPagamento.filter((pagamento) => { return tipoFormaPagExistente.includes(pagamento.fpg_tipopagamento) }).map((item) => {
                                            return { label: item.fpg_descricao, value: item.fpg_id, key: item.fpg_id }
                                        })} filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0} placeholder="Selecione a Forma de Pagamento..." />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={8} md={6} lg={5} xl={5} xxl={5}>
                                    <Form.Item label="Conta Corrente" name="ctc_id" rules={[{ required: true, message: 'Selecione uma Conta Corrente' }]}>
                                        <Select allowClear disabled={caixaCadastrada} showSearch options={listaContaCorrente.map((item) => {
                                            return { label: item.ctc_descricao, value: item.ctc_id, key: item.ctc_id }
                                        })} filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0} placeholder="Selecione uma Conta Corrente..." />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={8} md={6} lg={6} xl={6} xxl={6}>
                                    <Form.Item label="Conta Gerencial" name="ctg_id" rules={[{ required: true, message: 'Selecione uma Conta Gerencial' }]}>
                                        <Select allowClear showSearch optionFilterProp="children" placeholder="Selecione uma Conta Gerencial...">
                                            {listaContaGerencialAgrupador.map(item => (
                                                <Select.OptGroup label={item.ctg_descricao}>
                                                    {listaContaGerencial.filter((conta) => { return conta.ctc_id === item.ctc_id }).map(conta => (
                                                        <Select.Option key={conta.ctg_id} value={conta.ctg_id} label={conta.ctg_descricao}>{conta.ctg_descricao}</Select.Option>
                                                    ))}
                                                </Select.OptGroup>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                {semCondicao &&
                                    <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.fpg_id !== currentValues.fpg_id}>
                                        <Col xs={24} sm={8} md={6} lg={6} xl={5} xxl={5}>
                                            <Form.Item label="Condições de Pagamento" name="cpg_id">
                                                <Select allowClear placeholder="Selecione a Condição de Pagamento" showSearch optionFilterProp="children">
                                                    {listaCondicaoPagamento.map((item) => (
                                                        <Select.Option value={item.cpg_id} key={item.cpg_id} label={item.cpg_descricao}>{item.cpg_descricao}</Select.Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Form.Item>
                                }
                                <Col xs={24} sm={8} md={6} lg={4} xl={4} xxl={4}>
                                    <InputPreco onBlur={() => calcularRecebimentoParcial()} onPressEnter={() => calcularRecebimentoParcial()} name={'valorPago'} label={'Valor Recebido (R$)'} />
                                </Col>
                                {ehCheque &&
                                    <Col xs={24} sm={8} md={6} lg={6} xl={5} xxl={5}>
                                        <Form.Item name="numeroCheques" label="Nº Cheques">
                                            <InputNumber
                                                onBlur={(valor) => adicionaDadosCheque(valor.target.value)}
                                                step={1}
                                                min={0}
                                                controls={false}
                                                placeholder="Informe o Nº de Cheques"
                                            />
                                        </Form.Item>
                                    </Col>
                                }
                            </Row>
                        </Form>
                        <div className="ant-drawer-footer footer-recebimento">
                            <Row align="middle" justify="end" gutter={[8, 0]}>
                                {(!!listaTotalizadores.valorTotalParcelas && listaTotalizadores.valorTotalParcelas !== 0) &&
                                    <Col span={24}>
                                        <Row align="middle" justify="end" gutter={[8, 0]}>
                                            <Col className="f-14">
                                                Valor Parcelas (R$):
                                            </Col>
                                            <Col>
                                                <b className="f-16 c-primary">{FormatNumber(listaTotalizadores.valorTotalParcelas, true)}</b>
                                            </Col>
                                        </Row>
                                    </Col>
                                }
                                {(!!listaTotalizadores.valorTotalJuros && listaTotalizadores.valorTotalJuros !== 0) &&
                                    <Col span={24} className="c-red">
                                        <Row align="middle" justify="end" gutter={[8, 0]}>
                                            <Col className="f-14">
                                                Valor Juros (R$):
                                            </Col>
                                            <Col>
                                                <b className="f-18">{FormatNumber(listaTotalizadores.valorTotalJuros, true)}</b>
                                            </Col>
                                        </Row>
                                    </Col>
                                }
                                {(!!listaTotalizadores.valorTotalMulta && listaTotalizadores.valorTotalMulta !== 0) &&
                                    <Col span={24} className="c-red">
                                        <Row align="middle" justify="end" gutter={[8, 0]}>
                                            <Col className="f-14">
                                                Valor Multa (R$):
                                            </Col>
                                            <Col>
                                                <b className="f-18">{FormatNumber(listaTotalizadores.valorTotalMulta, true)}</b>
                                            </Col>
                                        </Row>
                                    </Col>
                                }
                                {(!!listaTotalizadores.valorDesconto && listaTotalizadores.valorDesconto !== 0) &&
                                    <Col span={24} className="c-primary">
                                        <Row align="middle" justify="end" gutter={[8, 0]}>
                                            <Col className="f-14">
                                                Valor Descontos: (R$):
                                            </Col>
                                            <Col>
                                                <b className="f-18">{FormatNumber(listaTotalizadores.valorDesconto, true)}</b>
                                            </Col>
                                        </Row>
                                    </Col>
                                }
                                {(!!listaTotalizadores.valorAReceber && listaTotalizadores.valorAReceber !== 0) &&
                                    <Col span={24}>
                                        <Row align="middle" justify="end" gutter={[8, 0]}>
                                            <Col className="f-16">
                                                Valor à Receber (R$):
                                            </Col>
                                            <Col>
                                                <b className="f-21">{FormatNumber(listaTotalizadores.valorAReceber, true)}</b>
                                            </Col>
                                        </Row>
                                    </Col>
                                }
                                {(!!listaTotalizadores.troco && listaTotalizadores.troco !== 0) &&
                                    <Col span={24} className="c-green">
                                        <Row align="middle" justify="end" gutter={[8, 0]}>
                                            <Col className="f-14">
                                                Troco (R$):
                                            </Col>
                                            <Col>
                                                <b className="f-18">{FormatNumber(listaTotalizadores.troco, true)}</b>
                                            </Col>
                                        </Row>
                                    </Col>
                                }
                                {(!!listaTotalizadores.restanteReceber && listaTotalizadores.restanteReceber !== 0) &&
                                    <Col span={24} className="c-red">
                                        <Row align="middle" justify="end" gutter={[8, 0]}>
                                            <Col className="f-14">
                                                Restante a Receber (R$):
                                            </Col>
                                            <Col>
                                                <b className="f-18">{FormatNumber(listaTotalizadores.restanteReceber, true)}</b>
                                            </Col>
                                        </Row>
                                    </Col>
                                }
                            </Row>
                            <Row justify="end" gutter={[8, 0]} className="m-t-8">
                                <Col>
                                    <Button onClick={() => { limparListas() }} icon={<CloseOutlined />} size="large" htmlType="button">
                                        Cancelar
                                    </Button>
                                </Col>
                                <Col>
                                    <Button onClick={() => formulario.submit()} icon={<SaveOutlined />} loading={carregando} size="large" type="primary" htmlType="submit">
                                        Efetuar Recebimento
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    </div>
                }
            </div>
            <ModalEditarParcela ModalEditarValoresParcela exibirModal={openModalEditarParcela} fecharModal={() => setOpenModalEditarParcela(false)} dadosLinha={dadosLinhaEditar} salvarDadosModal={salvarDadosModal} />
            <ModalBandeiraCartao exibirModal={openModalBandeiraCartao} fecharModal={() => setOpenModalBandeiraCartao(false)} retornoModal={efetuarRecebimento} />
            <ModalCheque form={formulario} exibirModal={openModalCheque} fecharModal={() => setOpenModalCheque(false)} dadosCheque={dadosCheque} />
            <DrawerDetalheNotaFiscal openModal={openModalDetalhes} closeModal={() => { setOpenModalDetalhes(false) }} dadosDrawer={dadosModalNota} />
            <DrawerReparcelamento listaParcelasReparcelar={listaParcelasReparcelar} listaTotalizadoresReparcelar={listaTotalizadoresReparcelar} dadosCliente={dadosCliente} buscarParcelasPessoa={buscarParcelasPessoa} showDrawerReparcelamento={showDrawerReparcelamento} fecharDrawerReparcelamento={() => dispatch({ type: drawerActions.CHANGE, data: { showDrawer: false } })} />
        </div >
    )
}