import React, { useEffect, useState } from "react";
import { Modal, Form } from "antd";
import { ExclamationCircleOutlined, PlusOutlined, CloseOutlined } from "@ant-design/icons";

import { LancamentoCheque } from "../..";
import { useStateValue } from "../../../state";
import { manutencaoActions } from "../../../actions";

export default function ModalCheque({ form, exibirModal, setExibirModal, fecharModal, dadosCheque, dadosCliente, setInfoCheque }) {
    const [formCheque] = Form.useForm();
    const [carregando, setCarregando] = useState(false);
    const [{ manutencao }, dispatch] = useStateValue();

    const onCloseModal = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                fecharModal();
            }
        });
    };

    const salvarModalContaCorrente = (dados) => {
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: { ...manutencao.dados, ...{ dadosModalCheque: { cheques: dados.cheques, contaCorrente: dados.ctc_id } } } } });
        setExibirModal(false);
    };

    useEffect(() => {
        if (exibirModal) {
            dispatch({ type: manutencaoActions.CHANGE, data: { dados: { ...manutencao.dados, ...{ dadosModalCheque: { vemDaNota: true, quantidadeCheque: dadosCheque.numeroCheques, valorTotalCheque: dadosCheque.pfp_valor } } } } });
            formCheque.setFieldsValue({ pes_id: form.getFieldValue().pes_id });
        }
    }, [exibirModal]);

    return (
        <Modal centered
            title="Lançamento de cheque"
            open={exibirModal}
            getContainer={false}
            onCancel={onCloseModal}
            onOk={() => { formCheque.submit() }}
            okText={
                <>
                    <PlusOutlined /> Salvar
                </>
            }
            cancelText={
                <>
                    <CloseOutlined /> Cancelar
                </>
            } 
            width={1000}>
            <LancamentoCheque form={formCheque} carregando={setCarregando} funcaoSalvar={salvarModalContaCorrente} dadosCliente={dadosCliente} setInfoCheque={setInfoCheque} />
        </Modal>
    );
}