import React, { useEffect, useRef, useState } from "react";
import { Row, Col, InputNumber, Form, Button, Select, Input, Tooltip, notification, Modal } from "antd";
import { SearchOutlined, BarcodeOutlined, EditOutlined, DeleteOutlined, PlusOutlined, CloseOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { getModoBusca, setModoBusca } from '../../services/auth';

import api from "../../services/api";
import { useStateValue } from "../../state";
import { FormGW, InputPreco, SelectProduto } from "../../components";
import { HasValue, isObjetoDiffVazio, VericarSeEANProdutoBalanca, RetornaPesoValorBalanca, ContemLetrasENumeros, arredondar } from "../../services/funcoes";
import { ModalCliente, ModalGradeProduto, ModalSenhaGerencial, ModalVendedor } from "../../components/modals/";
import { listaSelectPaginadoActions } from "../../actions";

export default function ItemSelecionar({ itensSelecionados, setItensSelecionados, dadosVendedor, setDadosVendedor, dadosCliente, setDadosCliente, atualizarListaProd, setAtualizarListaProd }) {

    const [{ manutencao, listaSelectPaginado }, dispatch] = useStateValue();
    const [formulario] = Form.useForm();
    const [consultaProduto, setConsultaProduto] = useState(null);
    const [descontoItem, setDescontoItem] = useState(false);
    const [acrescimoItem, setAcrescimoItem] = useState(false);
    const [openModalBuscaCliente, setOpenModalBuscaCliente] = useState(false);
    const [openModalVendedor, setOpenModalVendedor] = useState(false);
    const [listaProdutos, setListaProdutos] = useState([]);
    const [listaItens, setListaItens] = useState([]);
    const [listaPreco, setListaPreco] = useState([]);
    const [filtroProd, setFiltroProd] = useState('');
    const [listaNcm, setListaNcm] = useState([]);
    const [dadosContribuinte, setDadosContribuinte] = useState([]);
    const [parametrosEmpresa, setParametrosEmpresa] = useState([]);
    const [dadosProdutoImposto, setDadosProdutoImposto] = useState({});
    const [dadosCfop, setDadosCfop] = useState([]);
    const [produtoBalanca, setProdutoBalanca] = useState({});
    const [abreModalLiberacao, setAbreModalLiberacao] = useState(null);
    const [liberacaoSenha, setLiberacaoSenha] = useState(null);
    const codBarras = useRef();
    const adicionarButtonRef = useRef(null);

    const togglePesquisa = () => {
        if (consultaProduto == true) {
            setModoBusca(1);
        }
        else {
            setModoBusca(2);
        }

        setConsultaProduto(!consultaProduto);
    };

    useEffect(() => {

        let modo = getModoBusca();
        if (modo) {
            if (modo == 1) {
                setConsultaProduto(false);
            } else if (modo == 2) {
                setConsultaProduto(true);
            }
        } else {
            setModoBusca(1);
        }

        carregarDados();
        api.get(`ParametroEmpresa/BuscarParametros`).then(res => { setParametrosEmpresa(res.data) }).catch(error => { console.log(error) });
    }, [])

    useEffect(() => {
        if (!!dadosCliente.pes_id) {
            verificaParcelasAtraso(dadosCliente.pes_id);
        }
    }, [dadosCliente])

    useEffect(() => {
        if (consultaProduto && !!codBarras && !!codBarras.current) {
            codBarras.current.focus();
        }
    }, [consultaProduto])

    useEffect(() => {
        if (!!window.produtoVenda && window.produtoVenda.length > 0) {
            setItensSelecionados(window.produtoVenda);
        }
    }, [])

    useEffect(() => {
        window.addEventListener('keydown', handleKeyPress);
        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        }
    }, [])

    function carregarDados() {
        api.get(`DadosContribuinte/Buscar`).then(res => { setDadosContribuinte(res.data) }).catch(error => { console.log(error) });
        api.get(`Ncm/Listar`).then(res => { setListaNcm(res.data) }).catch(error => { console.log(error) });
        api.get(`ListaPreco/ListarTodasListaPreco`).then(res => { setListaPreco(res.data.items) }).catch(error => { console.log(error) });
        if (!manutencao.voltaVenda) {
            api.get(`Vendedor/RetornaVendedorUsuarioLogado`).then(res => { setDadosVendedor(res.data) }).catch(error => { console.log(error) });
        }
        api.get(`Produto/ListaProdutoFiltro`).then(
            res => {
                let key = 0;
                res.data.items.forEach((produto) => {
                    produto.key = key++;
                });
                setListaProdutos(res.data);
            }
        ).catch(
            error => {
                console.log(error);
            }
        );
        api.get(`Cfop/ListarCfopTipo?tipoCfopFim=8000&tipoCfopIni=1000`).then(
            (res) => {
                setDadosCfop(res.data);
            }
        ).catch(
            (erro) => {
                console.log(erro);
            }
        )
    };

    const clickVlrUnitario = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            adicionarButtonRef.current.focus();
        }
    };

    const selectVlrUnit = (e) => {
        if (e.key === 'Enter') {
            setarCampoNoElemento("itemSelecionar_valorUnitario", 100)
        }
    };

    const handleKeyPress = (event) => {
        if (event.key === "F2") {
            setOpenModalBuscaCliente(true)
        }
    }

    function setarCampoNoElemento(elemento = "qtde", atraso = 500) {
        setTimeout(
            () => {
                try {
                    let element = document.getElementById(elemento);
                    if (element) {
                        element.focus();
                        element.select(); // Seleciona todo o texto dentro do campo
                    }
                } catch (error) {
                    console.log('error :>> ', error);
                }
            }, atraso);
    }

    function adicionarItem(item = {}) {
        let dados = popularItemNota(item);
        dados.nfi_numeroitem = listaItens.length + 1;
        if (item.pro_usabalanca == true && formulario.getFieldValue().valorTotalItem == 0) {
            setProdutoBalanca(dados);
            setarCampoNoElemento("qtde");
        } else
            adicionarProdutoVenda(dados)
    }

    //area calculos desconto e acrescimo
    function alterarValorTotal() {
        if (parseFloat(formulario.getFieldValue().valorUnitario) < 0) {
            notification.warning({ message: 'Aviso!', description: 'Valor unitário não pode ser negativo!' });
            formulario.setFieldsValue({ valorUnitario: 0 });
        }
        let total = 0;
        total = arredondar(formulario.getFieldValue().quantidade * formulario.getFieldValue().valorUnitario);
        formulario.setFieldsValue({ valorTotalItem: total, subTotalItem: total });
    }

    function calculaDesconto(campo) {
        if (parseFloat(formulario.getFieldValue().valorDesconto) < 0) {
            notification.warning({ message: 'Aviso!', description: 'Valor do Desconto não pode ser negativo!' });
            formulario.setFieldsValue({ valorDesconto: 0 });
        }
        let percentualVendedor = !!manutencao.dados && !!manutencao.dados.vendedorDados && !!manutencao.dados.vendedorDados.ven_maximodesconto ? manutencao.dados.vendedorDados.ven_maximodesconto : null;
        let totalDesconto = !!manutencao.dados && !!manutencao.dados.totais && !!manutencao.dados.totais.valorTotalDesconto ? parseFloat(manutencao.dados.totais.valorTotalDesconto) : 0;
        let valorTotal = !!manutencao.dados && !!manutencao.dados.totais && !!manutencao.dados.totais.valorTotalSub ? parseFloat(manutencao.dados.totais.valorTotalSub) : 0;
        let valor = (!!formulario.getFieldValue().valorDesconto ? parseFloat(formulario.getFieldValue().valorDesconto) : 0)
        let perc = (!!formulario.getFieldValue().percentualDesconto ? parseFloat(formulario.getFieldValue().percentualDesconto) : 0);
        let tot = (!!formulario.getFieldValue().subTotalItem ? parseFloat(formulario.getFieldValue().subTotalItem) : 0);
        let result = 0;
        if (!!campo) {
            if (campo === 'PERC') {
                result = (tot * perc) / 100;
                if (percentualVendedor != null) {
                    if (perc > percentualVendedor) {
                        notification.warning({ message: 'Aviso!', description: 'Desconto maior do que o permitido ao vendedor!' });
                        formulario.setFieldsValue({ percentualDesconto: 0 });
                        formulario.setFieldsValue({ valorDesconto: 0 });
                        formulario.setFieldsValue({ valorTotal: valorTotal - totalDesconto });
                        return false;
                    }
                    formulario.setFieldsValue({ valorDesconto: result.toFixed(2) });
                } else {
                    formulario.setFieldsValue({ valorDesconto: result.toFixed(2) });
                }
            } else if (campo === 'ATT') {
                result = (tot * perc) / 100;
                formulario.setFieldsValue({ valorDesconto: result.toFixed(2) });
                valor = (!!formulario.getFieldValue().valorDesconto ? parseFloat(formulario.getFieldValue().valorDesconto) : 0);
                result = (!!tot > 0 ? (valor * 100) / tot : 0);
                formulario.setFieldsValue({ percentualDesconto: result.toFixed(2) });
            } else {
                if (valor > tot) {
                    formulario.setFieldsValue({ valorDesconto: tot });
                    formulario.setFieldsValue({ percentualDesconto: 100 });
                } else {
                    result = (valor * 100) / tot;
                    if (percentualVendedor != null) {
                        if (result > percentualVendedor) {
                            notification.warning({ message: 'Aviso!', description: 'Desconto maior do que o permitido ao vendedor!' });
                            formulario.setFieldsValue({ percentualDesconto: 0 });
                            formulario.setFieldsValue({ valorDesconto: 0 });
                            formulario.setFieldsValue({ valorTotalItem: tot });
                            return false;
                        }
                        formulario.setFieldsValue({ percentualDesconto: result.toFixed(2) });
                    } else {
                        formulario.setFieldsValue({ percentualDesconto: result.toFixed(2) });
                    }
                }
            }
            recalcularTotal();
        }
    }

    function calcularAcrescimo(campo) {
        let valor = (!!formulario.getFieldValue().valorAcrescimo ? formulario.getFieldValue().valorAcrescimo : 0);
        let perc = (!!formulario.getFieldValue().percentualAcrescimo ? formulario.getFieldValue().percentualAcrescimo : 0);
        let tot = (!!formulario.getFieldValue().subTotalItem ? parseFloat(formulario.getFieldValue().subTotalItem) : 0);
        let result = 0;
        if (!!campo) {
            if (campo === 'PERC') {
                result = (tot * perc) / 100;
                formulario.setFieldsValue({ valorAcrescimo: result.toFixed(2) });
            } else if (campo === 'ATT') {
                result = (tot * perc) / 100;
                formulario.setFieldsValue({ valorAcrescimo: result.toFixed(2) });
                result = (tot > 0 ? (valor * 100) / tot : 0);
                formulario.setFieldsValue({ percentualAcrescimo: result.toFixed(2) });
            } else {
                result = (valor * 100) / tot;
                formulario.setFieldsValue({ percentualAcrescimo: result.toFixed(2) });
            }
            recalcularTotal()
        }
    }

    function recalcularTotal() {
        let valorTot = 0;
        let valorItens = !!formulario.getFieldValue().valorUnitario && !!formulario.getFieldValue().quantidade ? arredondar(formulario.getFieldValue().valorUnitario * formulario.getFieldValue().quantidade) : 0;
        let valorAcrescimo = !!formulario.getFieldValue().valorAcrescimo ? formulario.getFieldValue().valorAcrescimo : 0;
        let valorDesc = !!formulario.getFieldValue().valorDesconto ? formulario.getFieldValue().valorDesconto : 0;
        valorTot = arredondar(valorItens) + arredondar(valorAcrescimo) - arredondar(valorDesc);
        formulario.setFieldsValue({ valorTotalItem: arredondar(valorTot) });
        formulario.setFieldsValue({ subTotalItem: arredondar(valorItens) });
    }

    //fim area calculos desconto area

    // area lista preço e cliente

    function removerCliente() {
        let consumidorFinal = {
            pes_nome: 'CONSUMIDOR FINAL',
            cpfCnpj: ''
        }
        setDadosCliente(consumidorFinal);
    }

    function verificaParcelasAtraso(clienteId) {
        api.get(`Parcela/ListarParcelasClientesEmAtrazo?IdPessoa=${clienteId}`).then(
            res => {
                if (!!res.data && res.data.items.length > 0) {
                    Modal.warning({
                        title: 'AVISO!',
                        icon: <ExclamationCircleOutlined />,
                        content: `Esse cliente possui parcelas em atraso!`
                    })
                }
            }
        ).catch(
            error => {
                console.log(error);
            }
        )
    }

    function alteraListaPreco(record) {
        if (!!record) {
            let dadosListaPreco = listaPreco.filter((item) => (item.ltp_id === record))[0];
            formulario.setFieldsValue({ valorUnitario: dadosListaPreco.lpi_valorvenda, quantidade: 1 });
            alterarValorTotal();
        }
    }

    // fim area lista preço e cliente

    // area popular dados produto 

    async function popularDadosProduto(key) {
        if (!!key || key === 0) {
            let dados = [...listaSelectPaginado.itens];
            let produto = dados.filter((item) => item.key === key)[0];
            formulario.setFieldsValue({ ltp_id: null });
            let listaPrecosProduto = await api.get(`ListaPreco/ListarListaPrecoProduto?idProduto=${produto.pro_id}${!!produto.prg_id ? `&idProdutoGrade=${produto.prg_id}` : ''}`);
            let chave = 0;
            listaPrecosProduto.data.forEach(item => item.key = chave++);
            setListaPreco(listaPrecosProduto.data);
            let listaPrincipal = listaPrecosProduto.data.filter((lista) => { return lista.ltp_principal });
            formulario.setFieldsValue({ ltp_id: listaPrincipal[0].ltp_id, valorUnitario: produto.lpi_valorvenda, quantidade: 1, subTotalItem: produto.lpi_valorvenda, valorTotalItem: produto.lpi_valorvenda });
            buscarDadosProdImpostos(produto.pro_id, (produtoItemImposto) => {
                popularFormProduto(produto, produtoItemImposto);
            });
        }

        setarCampoNoElemento("qtde");
    }

    // area verificar parametros e estoque item

    async function carregarParametrosLocal(produtoAdicionado, listaTemp, dados) {
        const codigo = produtoAdicionado.pro_id
        const codigoGrade = produtoAdicionado.prg_id
        const quantidade = produtoAdicionado.quantidade

        let resParams = await api.get(`ParametroLocal/BuscarParametros`);
        if (resParams.status == 200) {
            let paramsEstoque = resParams.data.par_avisoestoqueinsuficiente;

            var dadosIncluido = listaTemp
                .filter((prod) => (prod.pro_id === produtoAdicionado.pro_id && (!!!produtoAdicionado.prg_id || produtoAdicionado.prg_id === prod.prg_id)))
                .reduce((total, prod) => total + prod.quantidade, 0);

            if (paramsEstoque === null) {
                return;
            }

            let resEstoque = await api.get(`Estoque/BuscarEstoqueProduto?codigoProduto=${codigo}` + (codigoGrade ? `&codigoGrade=${codigoGrade}` : ''))
            let estoque = resEstoque.data.valorTotal;

            let valorEstoque = calcularValorEstoque(estoque, dadosIncluido, quantidade);

            return new Promise((resolve, reject) => {
                if (valorEstoque <= 0) {
                    estoqueInsuficiente(paramsEstoque, valorEstoque, resolve, reject);
                } else {
                    resolve();
                }
            });
        }
    }

    function calcularValorEstoque(estoque, dadosIncluido, quantidade) {
        if (dadosIncluido !== 0) {
            var novosDadosIncluido = dadosIncluido + quantidade
        }
        if (novosDadosIncluido > estoque) {
            return estoque - novosDadosIncluido;
        } else if (quantidade > estoque) {
            return estoque - quantidade;
        } else {
            return estoque
        }
    }

    function estoqueInsuficiente(paramsEstoque, valorEstoque, resolve, reject) {
        if (paramsEstoque === 0) {
            Modal.confirm({
                icon: <ExclamationCircleOutlined />,
                title: 'Aviso!',
                content: `Este produto está sem estoque disponível (Estoque: ${valorEstoque}). Deseja continuar?`,
                okText: 'Continuar',
                cancelText: 'Cancelar',
                centered: true,
                width: 440,
                onOk() {
                    resolve();
                },
                onCancel() {
                    reject();
                }
            });
        } else if (paramsEstoque === 1) {
            Modal.confirm({
                title: 'Aviso!',
                icon: <ExclamationCircleOutlined />,
                content: `Este produto está sem estoque disponível (Estoque: ${valorEstoque}). Deseja continuar? Será solicitado uma senha Gerencial!`,
                okText: 'Continuar',
                cancelText: 'Cancelar',
                centered: true,
                width: 440,
                onOk() {
                    setAbreModalLiberacao(true);
                    setLiberacaoSenha({ resolve, reject });
                },
                onCancel() {
                    reject();
                }
            });
        } else if (paramsEstoque === 2) {
            Modal.warning({
                title: 'Atenção Sistema de Controle de Estoque!',
                icon: <ExclamationCircleOutlined />,
                content: `Produto com estoque ${valorEstoque}, sistema está configurado para bloquear vendas de produtos sem estoque!`,
                centered: true,
                onOk() {
                    reject();
                }
            });
        }
    }

    const onCloseModalLiberacao = (aprovado) => {
        setAbreModalLiberacao(false);
        if (aprovado && liberacaoSenha) {
            liberacaoSenha.resolve();
        } else if (liberacaoSenha) {
            liberacaoSenha.reject();
        }
        setLiberacaoSenha(null);
    };

    // fim area verificar parametros e estoque item

    async function adicionarProdutoVenda(item) {
        // usada quando usuario não usar a leitura pelo cod. de barras e sim pelo cod. ean de um produto que usa balança.
        if (item === undefined && !!isObjetoDiffVazio(produtoBalanca)) {
            item = { ...produtoBalanca };
        }

        let produtoAdicionado = { ...item };
        let dados = [...listaSelectPaginado.itens];
        if (parseFloat(formulario.getFieldValue().valorTotalItem) <= 0) {
            notification.warning({ message: 'Aviso!', description: 'Produto com valor inválido!' });
            return false;
        }
        if (!!!item) {
            if (formulario.getFieldsValue().pro_key === undefined || formulario.getFieldsValue().pro_key === null) {
                notification.warning({ message: 'Aviso!', description: 'Nenhum produto selecionado!' })
                return false;
            }
            produtoAdicionado = { ...dados.filter((prod) => (prod.key === formulario.getFieldsValue().pro_key))[0], ...formulario.getFieldsValue() };
        } else {
            produtoAdicionado = { ...item, ...formulario.getFieldsValue() };
        }

        //FAZER AQUI A PARADA PRA ADICIONAR TODOS OS ITENS AO MESMO TEMPO
        let listaTemp = [...itensSelecionados];

        //função que verifica o parametro local e a quantidade de estoque do item
        await carregarParametrosLocal(produtoAdicionado, listaTemp, dados);

        let _numeroItem = 0;
        if (listaTemp.length > 0) {
            _numeroItem = listaTemp[listaTemp.length - 1].nItem;// Pega da posição zero pq esta ordenado decrescente.
        }

        if (produtoAdicionado.pro_kitcomposicao) {
            let _valorTotalDescontoItem = produtoAdicionado.valorDesconto ?? 0;
            let _valorTotalAcrescimoItem = produtoAdicionado.valorAcrescimo ?? 0;
            let _percDescontoItem = 0;
            let _percAcrescimoItem = 0;
            if (_valorTotalDescontoItem > 0) {
                _percDescontoItem = _valorTotalDescontoItem / produtoAdicionado.valorTotalItem;
            }
            if (_valorTotalAcrescimoItem > 0) {
                _percAcrescimoItem = _valorTotalAcrescimoItem / produtoAdicionado.valorAcrescimo;
            }
            let listaKitsProduto = (await api.get(`ProdutoKitComposicao/BuscarDadosProdutoKitComposicao?idProduto=${produtoAdicionado.pro_id}`)).data;
            for (let i = 0; i < listaKitsProduto.length; i++) {
                let item = listaKitsProduto[i];
                let objetoProd = (await api.get(`Produto/BuscarDadosProduto?IdProduto=${item.pro_id}&ufDestino=${dadosContribuinte.est_sigla}`
                    + `&operacaoCodigo=${parametrosEmpresa.ope_id}&ufOrigem=${dadosContribuinte.est_sigla}&tipoContribuinte=${dadosContribuinte.loc_tipocontribuinte}`
                    + `&empresaEnquadramento=${dadosContribuinte.emp_regimetributario}&destinoOperacao=1&indicadorPresenca=1`)).data[0];
                let _valorDesconto = _percDescontoItem > 0 ? parseFloat(((item.pkc_qtde * item.lpi_valorvenda) * _percDescontoItem).toFixed(2)) : 0;
                let _valorAcrescimo = _percAcrescimoItem > 0 ? parseFloat(((item.pkc_qtde * item.lpi_valorvenda) * _percAcrescimoItem).toFixed(2)) : 0;
                let itemTemp = {
                    nItem: ++_numeroItem,
                    pro_id: item.pro_id,
                    prg_id: item.prg_id,
                    pro_codigo: item.pro_codigo,
                    pkc_id: item.pkc_id,
                    pro_idkit: item.pro_idkit,
                    nfi_qtde: item.pkc_qtde,
                    quantidade: item.pkc_qtde,
                    nfi_valorunitario: item.lpi_valorvenda,
                    valorTotalItem: item.lpi_valorvenda,
                    nfi_ean: item.pro_ean,
                    nfi_unidademedida: item.ump_id,
                    nfi_valordesconto: _valorDesconto,
                    nfi_valoracrescimo: _valorAcrescimo,
                    editandoItem: false,
                    nfi_ncm: objetoProd.ncm_codigo,
                    icm_id: objetoProd.icm_id,
                    ncm_id: objetoProd.ncm_id,
                    pis_id: objetoProd.pis_id,
                    cso_id: objetoProd.cso_id,
                    cst_id: objetoProd.cst_id,
                    nfi_cfop: objetoProd.cfo_cfop,
                    ...item,
                };
                listaTemp.push(itemTemp);
            }
        } else {
            produtoAdicionado.nItem = ++_numeroItem;
            produtoAdicionado.nfi_qtde = produtoAdicionado.quantidade;
            produtoAdicionado.nfi_valorunitario = produtoAdicionado.valorUnitario;
            produtoAdicionado.nfi_ean = produtoAdicionado.nfi_ean ?? (produtoAdicionado?.pro_ean ?? produtoAdicionado.prg_ean);
            produtoAdicionado.nfi_unidademedida = !!produtoAdicionado.ump_id ? produtoAdicionado.ump_id : '';
            produtoAdicionado.nfi_valordesconto = !!formulario.getFieldValue().valorDesconto ? formulario.getFieldValue().valorDesconto : 0;
            produtoAdicionado.valorDesconto = !!formulario.getFieldValue().valorDesconto ? formulario.getFieldValue().valorDesconto : 0;
            produtoAdicionado.percentualDesconto = !!formulario.getFieldValue().percentualDesconto ? formulario.getFieldValue().percentualDesconto : 0;
            produtoAdicionado.nfi_valoracrescimo = !!formulario.getFieldValue().valorAcrescimo ? formulario.getFieldValue().valorAcrescimo : 0;
            produtoAdicionado.nfi_valoroutros = !!formulario.getFieldValue().valorAcrescimo ? formulario.getFieldValue().valorAcrescimo : 0;
            produtoAdicionado.percentualAcrescimo = !!formulario.getFieldValue().percentualAcrescimo ? formulario.getFieldValue().percentualAcrescimo : 0;
            produtoAdicionado.nfi_cfop = formulario.getFieldValue().nfi_cfop;
            produtoAdicionado.nfi_ncm = formulario.getFieldValue().ncm_codigo;
            produtoAdicionado.grade = produtoAdicionado.descricaograde;
            produtoAdicionado.editandoItem = false;
            listaTemp.push(produtoAdicionado);
        }
        setItensSelecionados(listaTemp);
        window.produtoVenda = listaTemp;
        formulario.resetFields();
        setProdutoBalanca({})
    }

    function popularItemNota(item) {
        let itemNota = {};
        let cfop = dadosCfop.filter((cfop) => (cfop.cfo_cfop === formulario.getFieldValue().nfi_cfop))[0];
        itemNota = {
            pro_id: item.pro_id,
            prg_id: item.prg_id,
            nfi_cfop: cfop.cfo_cfop,
            nfi_qtde: formulario.quantidade,
            nfi_numeroitem: !!itensSelecionados && itensSelecionados.length > 0 ? itensSelecionados.length + 1 : 1,
            nfi_valorunitario: parseFloat(!!formulario.getFieldValue().valorUnitario ? formulario.getFieldValue().valorUnitario : 0),
            total: parseFloat(!!formulario.getFieldValue().valorItemDesconto ? formulario.getFieldValue().valorItemDesconto : 0),
            percdesc: parseFloat(!!formulario.getFieldValue().percdesc ? formulario.getFieldValue().percdesc : 0),
            nfi_valordesconto: parseFloat(!!formulario.getFieldValue().desconto ? formulario.getFieldValue().desconto : 0),
            percacresc: parseFloat(!!formulario.getFieldValue().percacresc ? formulario.getFieldValue().percacresc : 0),
            nfi_valoroutros: parseFloat(!!formulario.getFieldValue().acrescimo ? formulario.getFieldValue().acrescimo : 0),
            nfi_informacaoadicional: formulario.getFieldValue().informacaoAdicional,
            cfo_cfop: cfop.cfo_cfop,
            cfo_descricao: cfop.cfo_descricao,
            cfo_id: cfop.cfo_id,
            nfi_ean: HasValue(item.pro_ean) ? item.pro_ean : item.prg_ean,
            nfi_unidademedida: item.ump_id,
            pro_codigo: item.pro_codigo,
            pro_descricao: item.pro_descricao,
            pro_ean: item.pro_ean,
            ump_id: item.ump_id,
            nfi_numeropedidocompra: null,
            grade: item.descricaograde,
            nfi_ncm: formulario.getFieldValue().ncm_codigo,
            icm_id: formulario.getFieldValue().icm_id,
            ncm_id: formulario.getFieldValue().ncm_id,
            pis_id: formulario.getFieldValue().pis_id,
            cso_id: formulario.getFieldValue().cso_id,
            cst_id: formulario.getFieldValue().cst_id
        };
        return itemNota;
    };

    async function onPressEnterProCodigo(campo) {
        let codigoEan = campo.target.value.trim();

        let tipoDados = ContemLetrasENumeros(codigoEan);
        if (tipoDados != 2 && codigoEan.length <= 3) {
            notification.warning({ message: 'Aviso!', description: 'Pesquisa com Letras e Números devem conter mais de 3 caractes!' });
            return;
        }
        // usar a função para item de balança
        let dadosRequisicao = null;
        var codigoDeBalanca = VericarSeEANProdutoBalanca(codigoEan);
        if (HasValue(codigoDeBalanca)) {
            dadosRequisicao = await api.get(`Produto/ListaProdutoFiltro?filtro=${codigoDeBalanca}`);
        }
        if (dadosRequisicao == null || dadosRequisicao?.data?.items?.length == 0) {
            dadosRequisicao = await api.get(`Produto/ListaProdutoFiltro?filtro=${codigoEan}`);
        }

        if (!!dadosRequisicao?.data?.items?.length > 0) {
            let dadosProduto = dadosRequisicao.data.items[0];
            dadosProduto.codigoEanPesquisa = codigoEan;
            buscarDadosProdImpostos(dadosProduto.pro_id, (produtoItemImposto) => {
                setConsultaProduto(true);
                popularFormProduto(dadosProduto, produtoItemImposto, () => {
                    recalcularTotal();
                    adicionarItem(dadosProduto);
                    setTimeout(() => {
                        if (!!codBarras && !!codBarras.current) {
                            codBarras.current.focus();
                        }
                    }, 300);
                })
            })
        } else {
            formulario.resetFields();
            setTimeout(() => {
                if (!!codBarras && !!codBarras.current) {
                    codBarras.current.focus();
                }
            }, 300);
            notification.warning({ message: 'Aviso!', description: 'Produto com EAN - ' + codigoEan + ' não encontrado!' });
        }
    }

    function popularFormProduto(produto, dadosImposto, callback) {
        let valorVenda = (!!produto.lpi_valorvenda ? produto.lpi_valorvenda : 0);
        formulario.setFieldsValue({ pro_descricao: produto.pro_descricao });
        if (produto.pro_usabalanca == true && HasValue(produto.codigoEanPesquisa)) {
            if (produto.codigoEanPesquisa.length == 13) {
                let ret = RetornaPesoValorBalanca(produto.codigoEanPesquisa, valorVenda, produto.ump_casasdecimais);
                formulario.setFieldsValue({ quantidade: ret.quantidadeProdutoBalanca, valorUnitario: valorVenda, valorTotalItem: ret.valorProdutoBalanca });
            } else {
                let quantidadeProduto = 0;
                formulario.setFieldsValue({ quantidade: quantidadeProduto.toFixed(produto.ump_casasdecimais), valorUnitario: valorVenda, valorTotalItem: valorVenda });
            }
        } else {
            formulario.setFieldsValue({ quantidade: 1, valorUnitario: valorVenda, valorTotalItem: valorVenda });
        }
        if (!!callback) {
            callback();
        }
    }

    function buscarDadosProdImpostos(produto, callback) {
        api.get(`Produto/BuscarDadosProduto?IdProduto=${produto}&ufDestino=${dadosContribuinte.est_sigla}`
            + `&operacaoCodigo=${parametrosEmpresa.ope_id}&ufOrigem=${dadosContribuinte.est_sigla}&tipoContribuinte=${dadosContribuinte.loc_tipocontribuinte}`
            + `&empresaEnquadramento=${dadosContribuinte.emp_regimetributario}&destinoOperacao=1&indicadorPresenca=1`).then(
                res => {
                    let objetoProd = res.data[0];
                    formulario.setFieldsValue({
                        ncm_codigo: objetoProd.ncm_codigo,
                        icm_id: objetoProd.icm_id,
                        ncm_id: objetoProd.ncm_id,
                        pis_id: objetoProd.pis_id,
                        cso_id: objetoProd.cso_id,
                        cst_id: objetoProd.cst_id,
                        nfi_cfop: objetoProd.cfo_cfop
                    });
                    objetoProd.nfi_cfop = objetoProd.cfo_cfop;
                    setDadosProdutoImposto(objetoProd);
                    if (!!callback) {
                        callback(objetoProd);
                    }
                }
            ).catch(
                error => {
                    console.log(error);
                }
            )
    }
    function limparCampos() {
        formulario.resetFields();
        setProdutoBalanca({})
    }


    return (
        <div className="pages-col">
            <Row align="middle" className="col-primary">
                <Col span={24}>
                    <Row align="middle" justify="space-between" gutter={[8, 0]}>
                        <Col xs={16} sm={16} md={16} lg={20} xl={21} xxl={21} className="over-text">
                            Cliente: <b>{dadosCliente.pes_nome}</b>
                        </Col>
                        <Col>
                            <Tooltip title="Editar Cliente (F2)" placement="top">
                                <Button type="text" icon={<EditOutlined />} onClick={() => setOpenModalBuscaCliente(true)} />
                            </Tooltip>
                            <Tooltip title="Remover Cliente" placement="top">
                                <Button onClick={() => removerCliente()} type="text" icon={<DeleteOutlined />} />
                            </Tooltip>
                        </Col>
                    </Row>
                </Col>
                <Col span={24}>
                    CPF/CNPJ: <b>{!!dadosCliente.pef_cpf ? dadosCliente.pef_cpf : (!!dadosCliente.pej_cnpj ? dadosCliente.pej_cnpj : !!dadosCliente.cpfCnpj ? dadosCliente.cpfCnpj : 'Não Informado')}</b>
                </Col>
                <Col span={24}>
                    <Row align="middle" justify="space-between" gutter={[8, 0]}>
                        <Col xs={16} sm={16} md={16} lg={20} xl={21} xxl={21} className="over-text">
                            Vendedor: <b>{!!isObjetoDiffVazio(dadosVendedor) ? dadosVendedor.pes_nome : 'SELECIONE O VENDEDOR'}</b>
                        </Col>
                        <Col>
                            <Tooltip title="Editar Vendedor" placement="top">
                                <Button type="text" icon={<EditOutlined />} onClick={() => setOpenModalVendedor(true)} />
                            </Tooltip>
                            <Tooltip title="Remover Vendedor" placement="top">
                                <Button type="text" icon={<DeleteOutlined />} onClick={() => setDadosVendedor({})} />
                            </Tooltip>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <div className="p-10">
                <FormGW layout="vertical" form={formulario} name="itemSelecionar" >
                    <Row align="middle" justify="center" gutter={[8, 8]}>
                        <Col span={24}>
                            <Row gutter={[8, 0]}>
                                {!!consultaProduto === false &&
                                    <Col xs={20} sm={21} md={21} lg={21} xl={22} xxl={23}>
                                        <Form.Item name="pis_id" hidden />
                                        <Form.Item name="ncm_id" hidden />
                                        <Form.Item name="icm_id" hidden />
                                        <Form.Item name="nfi_cfop" hidden />
                                        <Form.Item label="Selecione o Produto" name="pro_key">
                                            <SelectProduto valueKey={true} detalhes={true}
                                                focus={true}
                                                defaultOpen={true}
                                                allowClear
                                                placeholder="Selecione o Produto"
                                                name="pro_key"
                                                form={formulario}
                                                ordem={"pro_descricao"}
                                                onChange={(dados) => { popularDadosProduto(dados) }}
                                                filtroPesq={filtroProd}
                                                atualizaComponente={atualizarListaProd} />
                                        </Form.Item>
                                    </Col>
                                }
                                {!!consultaProduto &&
                                    <Col xs={9} sm={9} md={9} lg={9} xl={9} xxl={9}>
                                        <Form.Item label="Localize o Produto" name="pro_codigo">
                                            <Input ref={codBarras} onPressEnter={(value) => {
                                                onPressEnterProCodigo(value)
                                            }} placeholder="Informe o Produto/Mercadoria" />
                                        </Form.Item>
                                    </Col>
                                }
                                {!!consultaProduto &&
                                    <Col xs={12} sm={12} md={12} lg={12} xl={13} xxl={14} className="over-text-top">
                                        <b> {produtoBalanca?.pro_descricao}</b>
                                    </Col>
                                }
                                <Col xs={4} sm={3} md={3} lg={3} xl={2} xxl={1} className="btn-fil">
                                    <Tooltip title={!!consultaProduto ? 'Localize produto por EAN' : 'Selecione o produto'} placement="top">
                                        <Button onClick={togglePesquisa} block className="p-0">
                                            {consultaProduto ? <SearchOutlined /> : <BarcodeOutlined />}
                                        </Button>
                                    </Tooltip>
                                </Col>
                            </Row>
                            <Row gutter={[0, 0]}>
                                <Col span={24}>
                                    <Form.Item label="Lista de Preço" name="ltp_id" rules={[{ required: true, message: 'Favor selecionar uma lista de preço!' }]}>
                                        <Select placeholder="Selecione uma Lista" allowClear onChange={(value) => alteraListaPreco(value)}>
                                            {listaPreco.map((listaPre) => (
                                                <Select.Option label={listaPre.ltp_nome} value={listaPre.ltp_id} key={listaPre.key}>{listaPre.ltp_nome}</Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={24}>
                            <Row align="middle" justify="center" gutter={[8, 0]}>
                                <Col xs={8} sm={7} md={5} lg={6} xl={7} xxl={8}>
                                    <img src={require("../../assets/img-image.png").default} className="image" />
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={24} xl={16} xxl={16} className="text-right">
                                    <Row align="middle" justify="end" gutter={[8, 0]}>
                                        <Col xs={24} sm={12} md={16} lg={16} xl={16} xxl={18}>
                                            Quantidade:
                                        </Col>
                                        <Col xs={24} sm={12} md={8} lg={8} xl={8} xxl={6}>
                                            <Form.Item name="quantidade">
                                                <InputNumber decimalSeparator="," min={0} step={1} id="qtde" placeholder="Qtde do Produto" onChange={() => { alterarValorTotal() }} controls={false} onKeyDown={selectVlrUnit} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row align="middle" justify="end" gutter={[8, 0]}>
                                        <Col xs={24} sm={12} md={16} lg={16} xl={16} xxl={18}>
                                            Valor Unitário (R$):
                                        </Col>
                                        <Col xs={24} sm={12} md={8} lg={8} xl={8} xxl={6} onKeyDown={clickVlrUnitario}>
                                            <InputPreco name="valorUnitario" somenteValorPositivo={true} onChange={() => alterarValorTotal()} />
                                        </Col>
                                    </Row>
                                    <Row align="middle" justify="end" gutter={[8, 0]}>
                                        <Col xs={24} sm={12} md={16} lg={16} xl={16} xxl={18}>
                                            Subtotal (R$):
                                        </Col>
                                        <Col xs={24} sm={12} md={8} lg={8} xl={8} xxl={6}>
                                            <InputPreco name="subTotalItem" disabled={true} somenteValorPositivo={true} />
                                        </Col>
                                    </Row>
                                    <Row justify="end" align="middle" gutter={[5, 0]}>
                                        <Col xs={24} sm={17} md={16} lg={16} xl={16} xxl={18}>
                                            <Form.Item>
                                                <Row align="middle" justify="end" gutter={[5, 0]}>
                                                    <Col>
                                                        Desconto em:
                                                    </Col>
                                                    <Col>
                                                        <Button className={!descontoItem ? 'ant-btn-primary' : ''} onClick={() => setDescontoItem(false)}>
                                                            R$
                                                        </Button>
                                                        <Button className={descontoItem ? 'ant-btn-primary' : ''} onClick={() => setDescontoItem(true)}>
                                                            %
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Form.Item>
                                        </Col>
                                        {!descontoItem &&
                                            <Col xs={24} sm={7} md={8} lg={8} xl={8} xxl={6}>
                                                <InputPreco somenteValorPositivo={true} name="valorDesconto" disabled={!isObjetoDiffVazio(dadosVendedor)} onBlur={() => calculaDesconto('VALOR')} />
                                            </Col>
                                        }
                                        {descontoItem &&
                                            <Col xs={24} sm={7} md={8} lg={8} xl={8} xxl={6}>
                                                <Form.Item name="percentualDesconto">
                                                    <InputNumber
                                                        disabled={!isObjetoDiffVazio(dadosVendedor)}
                                                        defaultValue={0}
                                                        min={0}
                                                        controls={false}
                                                        max={100}
                                                        formatter={value => `${value}%`}
                                                        parser={value => value.replace('%', '')}
                                                        onBlur={() => calculaDesconto('PERC')} />
                                                </Form.Item>
                                            </Col>
                                        }
                                    </Row>
                                    <Row justify="end" align="middle" gutter={[5, 0]}>
                                        <Col xs={24} sm={17} md={16} lg={16} xl={16} xxl={18}>
                                            <Form.Item>
                                                <Row align="middle" justify="end" gutter={[5, 0]}>
                                                    <Col>
                                                        Acrescimo em:
                                                    </Col>
                                                    <Col>
                                                        <Button className={!acrescimoItem ? 'ant-btn-primary' : ''} onClick={() => setAcrescimoItem(false)}>
                                                            R$
                                                        </Button>
                                                        <Button className={acrescimoItem ? 'ant-btn-primary' : ''} onClick={() => setAcrescimoItem(true)}>
                                                            %
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Form.Item>
                                        </Col>
                                        {!acrescimoItem &&
                                            <Col xs={24} sm={7} md={8} lg={8} xl={8} xxl={6}>
                                                <InputPreco name="valorAcrescimo" onChange={() => calcularAcrescimo('VALOR')} somenteValorPositivo={true} />
                                            </Col>
                                        }
                                        {acrescimoItem &&
                                            <Col xs={24} sm={7} md={8} lg={8} xl={8} xxl={6}>
                                                <Form.Item name="percentualAcrescimo">
                                                    <InputNumber
                                                        defaultValue={0}
                                                        min={0}
                                                        max={100}
                                                        controls={false}
                                                        formatter={value => `${value}%`}
                                                        parser={value => value.replace('%', '')}
                                                        onChange={() => calcularAcrescimo('PERC')} />
                                                </Form.Item>
                                            </Col>
                                        }
                                    </Row>
                                    <Row justify="end" align="middle" gutter={[8, 8]}>
                                        <Col xs={24} sm={12} md={16} lg={16} xl={16} xxl={18}>
                                            Valor Total (R$):
                                        </Col>
                                        <Col xs={24} sm={12} md={8} lg={8} xl={8} xxl={6}>
                                            <InputPreco name="valorTotalItem" disabled={true} somenteValorPositivo={true} />
                                        </Col>
                                        <Col>
                                            <Button onClick={() => limparCampos()} icon={<CloseOutlined />} size="middle">
                                                Cancelar
                                            </Button>
                                        </Col>
                                        <Col className="p-b-9">
                                            <Button onClick={() => adicionarProdutoVenda()} type="primary" icon={<PlusOutlined />} size="large" ref={adicionarButtonRef}>
                                                Adicionar Item
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </FormGW>
            </div>
            <ModalCliente exibirModalBuscaCliente={openModalBuscaCliente} fecharModalBuscaCliente={() => setOpenModalBuscaCliente(false)} dadosCliente={dadosCliente} setDadosCliente={setDadosCliente} />
            <ModalVendedor exibirModalVendedor={openModalVendedor} fecharModalVendedor={() => setOpenModalVendedor(false)} dadosVendedor={dadosVendedor} setDadosVendedor={setDadosVendedor} />
            <ModalSenhaGerencial exibirModalSenhaGerencial={abreModalLiberacao} fecharModalSenhaGerencial={onCloseModalLiberacao} />
        </div>

    );
}