import React, { useEffect } from "react";
import { Button, Layout, Modal } from "antd";
import { Route, HashRouter, Routes, BrowserRouter, Outlet, useNavigate } from "react-router-dom";

import api from "../../services/api";
import SemAcesso from "../semAcesso";
import { useStateValue } from "../../state";
import { HeaderPage, MenuPage } from "../../components";
import { parametrosEmpresaActions, tributacaoActions } from "../../actions";
import { setPermissoes, setStatusPlano, setNavegacao, getEmpresa, setParametrosEmpresa, setValidadeCertificado, getValidadeCertificado } from "../../services/auth";
import { Login, Resumo, Venda, Clientes, FinalizarVenda, VendaRealizada, DevolucaoVenda, Parcelas, ParametrosEmpresa } from "../";
import { Content } from "antd/es/layout/layout";
import moment from 'moment';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { diferencaDatas } from "../../services/funcoes";

export default function Home() {

    const [{ }, dispatch] = useStateValue();
    const navigation = useNavigate();

    function buscarParametros() {
        api.get('ParametroEmpresa/BuscarParametros').then(
            res => {
                if (res.status === 200) {
                    dispatch({ type: parametrosEmpresaActions.CHANGE, data: { markupProduto: res.data.par_markupproduto, par_diastoleranciacobrancajuro: res.data.par_diastoleranciacobrancajuro, par_percjuromesparcelavencida: res.data.par_percjuromesparcelavencida, par_multaparcelavencida: res.data.par_multaparcelavencida } });
                    setParametrosEmpresa(res.data);
                }
            }
        ).catch(
            (erro) => {
                console.error(erro);
            }
        );
    }

    async function empresaUsuario() {
        let dadosEmpresa = (await api.get(`Empresa/Buscar/${getEmpresa()}`)).data
        dispatch({ type: tributacaoActions.CHANGE, data: { empRegimeTributario: dadosEmpresa.emp_regimetributario, empEnquadramentoFiscal: dadosEmpresa.emp_enquadramentofiscal } });
    }

    useEffect(() => {
        empresaUsuario();
        resetarNav();
        buscarParametros();
        buscarPermissoesUsuario();
        buscarStatusPlano();
        buscarDadosCertificado();
    }, []);

    function resetarNav() {
        if (!!!localStorage.getItem('@GestorWeb-Navegacao')) {
            setNavegacao({ geral: {} });
        }
    }

    function buscarPermissoesUsuario() {
        api.get(`PermissaoUsuario/Listar`).then(
            (res) => {
                setPermissoes(res.data);
            }
        ).catch(
            error => {
                if (error.response.status === 426) {
                    Modal.warn({
                        title: error.response.data
                    });
                    navigation("semAcesso");
                    //window.location = 'Home#/semAcesso'
                } else {
                    console.log(error);
                }
            }
        )
    }
    async function retornaConfiguracaoCertificado() {
        try {
            const res = await api.get('/LocalConfigNfe/RetornaConfiguracaoNfe');
            if (res.status === 200 && res.data.lcn_validadecertificado) {
                console.log("Retorno certificado:", res.data);
                var clickNaoExibir = {
                    dataValidade: res.data.lcn_validadecertificado,
                    naoMostrarNovamente: false,
                    diferencaDias: [15, 10, 5, 1, 0],
                };
                setValidadeCertificado(clickNaoExibir);
            } else {
                console.error("Resposta inesperada da API:", res);
            }
        } catch (erro) {
            console.error("Erro ao buscar configuração do certificado:", erro);
        }
    }
    function naoExibirMensagem() { //quando chamada função joga "naoMostrarNovamente"=true e nao exibe a mensagem até o proximo logout
        let dados = getValidadeCertificado();
        Modal.destroyAll();
        dados.naoMostrarNovamente = true;  
        setValidadeCertificado(dados);
    }
    function exibirModalAviso(diasParaVencimento, dataValidade) { // Aviso exibido sempre que o valor de dif estiver dentro de [15, 10, 5, 1,]
        let formattedDate = moment(dataValidade).format("DD/MM/YYYY");
        const diaOuDias = diasParaVencimento === 1 ? "dia" : "dias"; //trata plural ou singular
        Modal.confirm({
            title: 'Aviso!',
            content:  ( 
                <div style={{}}> 
                    <p>O certificado expira em: {diasParaVencimento} {diaOuDias}!</p>
                    <p>Vencimento em: {formattedDate}</p>
                </div>
                
            ),
            footer: (
                <div style={{ textAlign: "center"}}>
                    <Button style={{marginRight:"5px"}} onClick={() => exibirModalAvisoDiferencaDias(diasParaVencimento)}>
                        Nao exibir novamente
                    </Button>
                    <Button style={{backgroundColor:"#014080", color:"white"}} onClick={() => Modal.destroyAll()}>
                        Ok
                    </Button>      
                </div>
            )
        });
    }
    function exibirModalAvisoDiferencaDias(diferencaDias) { //aviso2, mostra em quantos dias a mensagem sera exibida de novo
        const diaOuDias = diferencaDias === 1 ? "dia" : "dias";
        Modal.info({
            title: 'Atenção!',
            content: (
                <div>
                    <p>
                        A mensagem será exibida novamente em {diferencaDias} {diaOuDias} ou no próximo acesso ao sistema.
                    </p>
                </div>
            ),
            footer: (
                <div style={{ textAlign: "center"}}>
                    <Button style={{backgroundColor:"#014080", color:"white"}} onClick={() => naoExibirMensagem()}>
                        Ok
                    </Button>
                        
                </div>
            )
        });
    }
    function exibirModalVencimentoHoje() { //aviso exibido no dia de vencimento (dif===0)
        Modal.confirm({
            title: 'Certificado Digital vence hoje!',
            content: (
                <div>
                    <p>Após o vencimento, a emissão de notas será bloqueada. Contate seu contador!</p>
                </div>
            ),
            footer: (
                <div style={{ textAlign: "center"}}>
                    <Button style={{marginRight:"5px"}} onClick={() => naoExibirMensagem()}>
                        Nao exibir novamente
                    </Button>
                    <Button style={{backgroundColor:"#014080", color:"white"}} onClick={() => Modal.destroyAll()}>
                        Ok
                    </Button>   
                </div>
            )
        });
    }
    async function buscarDadosCertificado() {
        let dados = getValidadeCertificado();
        if (dados && dados.naoMostrarNovamente === true) {
            return;
        }
        if (!dados || !dados.dataValidade || !dados.diferencaDias) {
            await retornaConfiguracaoCertificado();
            dados = getValidadeCertificado();
        }
        const dif = diferencaDatas(dados.dataValidade);
        if (dif === 0) {
            exibirModalVencimentoHoje();
            return;
        }
        if ([15, 10, 5, 1, 0].includes(dif)) {
            exibirModalAviso(dif, dados.dataValidade);
        }
    }

    function buscarStatusPlano() {
        api.get(`LocalPlano/VerificarStatusPlano`).then(
            res => {

            }
        ).catch(
            error => {
                if (error.response.status === 402) {
                    setStatusPlano('VENCIDO');
                    navigation("renovarPlano");
                    //window.location = '/renovarPlano#'
                }
            }
        )
    }

    return (
        <div className="App">
            <Layout className="vh100">
                <HeaderPage />
                <Layout className="vh100 m-t-55">
                    <MenuPage />
                    <Layout className="site-layout">
                        <Layout.Content>
                            <Routes>
                                <Route path="/login" element={<Login />} />
                                <Route path="/" element={<Resumo />} />
                                <Route path="venda" element={<Venda />} />
                                <Route path="/clientes" element={<Clientes />} />
                                <Route path="finalizarVenda" element={<FinalizarVenda />} />
                                <Route path="/vendaRealizada" element={<VendaRealizada />} />
                                <Route path="/devolucaoVenda" element={<DevolucaoVenda />} />
                                <Route path="/parcelas" element={<Parcelas />} />
                                <Route path="/parametrosEmpresa" element={<ParametrosEmpresa />} />
                                <Route path="/semAcesso" element={<SemAcesso />} />
                            </Routes>
                        </Layout.Content>
                    </Layout>
                </Layout>
            </Layout>
        </div>
    );
}