import { Modal, notification } from "antd";
import moment from "moment";
import { getLocal, linkApi,getToken, getParametrosEmpresa } from "./auth";
import api from "./api";
import React from 'react';
import axios from 'axios';


export const calculaDigitoVerificadorEAN = (numeroEan) => {
    var multiplicador = [1, 3, 1, 3, 1, 3, 1, 3, 1, 3, 1, 3];
    var somaEAN = 0;
    var divisao = 0;
    var divisaoArredondada = 0;
    var resultado = 0;
    var digitoVerificador;
    var Ean = numeroEan.toString();
    for (var i = 0; i < 12; i++) {
        somaEAN += Ean[i] * multiplicador[i];
    };

    divisao = somaEAN / 10;
    divisaoArredondada = Math.floor(divisao) + 1;
    resultado = divisaoArredondada * 10;
    digitoVerificador = resultado - somaEAN;
    if (digitoVerificador > 9) {
        digitoVerificador = 0;
    }
    return digitoVerificador;
};

export const trataRetornoFormularioDinamico = (values, elemento) => {
    if (!!Date.parse(values[elemento.cap_nomeelemento]) && typeof values[elemento.cap_nomeelemento] === "object" && values[elemento.cap_nomeelemento].toString().length > 30) {
        values[elemento.cap_nomeelemento] = moment(values[elemento.cap_nomeelemento]).format("YYYY/MM/DD")
    } else if (typeof values[elemento.cap_nomeelemento] === "object") {
        values[elemento.cap_nomeelemento] = values[elemento.cap_nomeelemento].join(',')
    }
    return values;
};


export const trataSetaDadosFormularioDinamico = (formulario, elemento, valor) => {
    switch (elemento.cap_tipoelemento) {
        case 'dataPicker':
            valor = moment(valor, "YYYY/MM/DD");
            break;
        case 'checkbox':
            valor = JSON.parse(valor);
            break;
        default:
            break;
    };
    formulario.setFieldsValue({ [elemento.cap_nomeelemento]: valor });
    return true;
};

export const encodeBase64 = (data) => {
    return Buffer.from(data).toString('base64');
}
export const decodeBase64 = (data) => {
    return Buffer.from(data, 'base64').toString('ascii');
}

export const parseToken = (token) => {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(decodeBase64(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};

export const validarEAN = (eanCodigo) => {
    if (!!eanCodigo) {
        var fator = 3;
        var sum = 0;
        var cc = 0;
        var ca = 0;
        var numlen = eanCodigo.length;
        if (numlen === 13) {
            var index = 0;
            for (index = numlen; index > 0; --index) {
                if (index !== 13) {
                    sum = sum + eanCodigo.substring(index - 1, index) * fator;
                    fator = 4 - fator;
                }
            }
            cc = ((1000 - sum) % 10);
            ca = eanCodigo.substring(12);
            if (cc === ca) {
                return true;
            }
            else {
                return false;
            }
        }
        if (numlen === 14) {
            for (index = eanCodigo.length; index > 0; --index) {
                if (index !== 14) {
                    sum = sum + eanCodigo.substring(index - 1, index) * fator;
                    fator = 4 - fator;
                }
            }
            cc = ((1000 - sum) % 10);
            ca = eanCodigo.substring(13);
            if (cc === ca) {
                return true;
            }
            else {
                return false;
            }
        }
        if (numlen === 8) {
            for (index = eanCodigo.length; index > 0; --index) {
                if (index !== 8) {
                    sum = sum + eanCodigo.substring(index - 1, index) * fator;
                    fator = 4 - fator;
                }
            }
            cc = ((1000 - sum) % 10);
            ca = eanCodigo.substring(7);
            if (cc === ca) {
                return true
            }
            else {
                return false;
            }
        }
        if (((numlen !== 8) && (numlen !== 13) && (numlen !== 14) && (numlen !== 0))) {
            return false;
        }
    } else {
        return true;
    }
};

export const validaForm = (formulario, listaValidacoes) => {
    let retorno = false;
    if (!!formulario && !!listaValidacoes) {
        let formObj = formulario.getFieldValue();
        let msgRetorno = [];
        listaValidacoes.forEach((validacao) => {
            if (validacao.obrigatorio && (!!formObj[validacao.nome] === false && formObj[validacao.nome] !== 0)) {
                msgRetorno.push('O campo ' + validacao.label + ' é obrigatório!');
            }
        });
        if (msgRetorno.length > 0) {
            notification.warning({ message: 'Aviso', description: msgRetorno.join('\n') })
        } else {
            retorno = true;
        }
    }
    return retorno;
};

export function diferencaDatas(dataInicial, dataFinal = new Date()) {
    var d1 = new Date(dataInicial).getTime();
    var d2 = new Date(dataFinal).getTime();
    var df = Math.abs(d1 - d2);
    return Math.round(df / (24 * 60 * 60 * 1000));
};

export function compararDatas(dataInicial, dataFinal) {
    dataFinal = (!!dataFinal) ? dataFinal : new Date();
    dataInicial = new Date(dataInicial);
    dataFinal = new Date(dataFinal);
    if (dataInicial >= dataFinal) {
        return false;
    } else {
        return true;
    }
};
export const isDate = (date) => {
    const _regExp = new RegExp('^(-?(?:[1-9][0-9]*)?[0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(.[0-9]+)?(Z)?$');
    return _regExp.test(date);
};

export function validarNumeros(valor) {
    let numero = 0;

    if (!!valor && !isNaN(parseFloat(valor))) {
        numero = parseFloat(valor);
    }
    return numero;
};

export const retornaSoLetras = (valor) => {
    return valor.replace(/[^a-zA-Z]/g, '');
};

export function validarDadosObj(dados) {
    let retorno = {};
    let valido = false;
    if (!!dados) {
        for (let key in dados) {
            if (!!dados[key]) {
                valido = true;
            }
        }
        if (valido) {
            retorno = dados;
        }
    }
    return retorno;
};

export const retornaSoNumeros = (valor) => {
    if (valor !== '')
        return valor.replace(/\D/g, "");
};

export async function consultaCNPJ(cnpj) {
    cnpj = cnpj.replace(/\D/g, '');

    return jsonp(`https://www.receitaws.com.br/v1/cnpj/${encodeURI(cnpj)}`, 60000)
        .then((json) => {
            if (json['status'] === 'ERROR') {
                return Promise.reject(json['message']);
            } else {
                return Promise.resolve(json);
            }
        });
};

export function validarCpf(cpf) {
    if (cpf !== '') {
        cpf = cpf.replace(/[^\d]+/g, '');

        if (cpf.length !== 11 ||
            cpf === "00000000000" ||
            cpf === "11111111111" ||
            cpf === "22222222222" ||
            cpf === "33333333333" ||
            cpf === "44444444444" ||
            cpf === "55555555555" ||
            cpf === "66666666666" ||
            cpf === "77777777777" ||
            cpf === "88888888888" ||
            cpf === "99999999999") {
            return false;
        }

        let add = 0;
        let i;
        for (i = 0; i < 9; i++)
            add += parseInt(cpf.charAt(i)) * (10 - i);
        let rev = 11 - (add % 11);
        if (rev === 10 || rev === 11)
            rev = 0;
        if (rev !== parseInt(cpf.charAt(9))) {
            return false;
        }

        add = 0;
        for (i = 0; i < 10; i++)
            add += parseInt(cpf.charAt(i)) * (11 - i);
        rev = 11 - (add % 11);
        if (rev === 10 || rev === 11)
            rev = 0;
        if (rev !== parseInt(cpf.charAt(10))) {
            return false;
        }
        return true;
    }
};

export function validarCnpj(cnpj) {
    if (cnpj !== '') {
        cnpj = cnpj.replace(/[^\d]+/g, '');

        if (cnpj.length !== 14) {
            return false;
        }

        // Elimina CNPJs invalidos conhecidos
        if (cnpj === "00000000000000" ||
            cnpj === "11111111111111" ||
            cnpj === "22222222222222" ||
            cnpj === "33333333333333" ||
            cnpj === "44444444444444" ||
            cnpj === "55555555555555" ||
            cnpj === "66666666666666" ||
            cnpj === "77777777777777" ||
            cnpj === "88888888888888" ||
            cnpj === "99999999999999") {
            return false;
        }

        // Valida DVs
        var tamanho = cnpj.length - 2
        var numeros = cnpj.substring(0, tamanho);
        var digitos = cnpj.substring(tamanho);
        var soma = 0;
        var pos = tamanho - 7;
        var i;
        for (i = tamanho; i >= 1; i--) {
            soma += numeros.charAt(tamanho - i) * pos--;
            if (pos < 2)
                pos = 9;
        }

        var resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
        if (resultado != digitos.charAt(0)) {
            return false;
        }

        tamanho = tamanho + 1;
        numeros = cnpj.substring(0, tamanho);
        soma = 0;
        pos = tamanho - 7;
        for (i = tamanho; i >= 1; i--) {
            soma += numeros.charAt(tamanho - i) * pos--;
            if (pos < 2)
                pos = 9;
        }

        resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
        if (resultado != digitos.charAt(1)) {
            return false;
        }

        return true;
    }
};

export const openPdfNewTab = async (_url) => {
    try {
      let token = getToken();
      const response = await axios.get(linkApi + _url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: 'arraybuffer',
      });
      const blob = new Blob([response.data], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);
      window.open(url, '_blank');
    } catch (error) {
      console.error('Erro ao buscar o PDF:', error);
    }
  };

export function formatarCpfCnpj(valor) {
    if (HasValue(valor)) {
        if (valor.length == 11) {
            return formatarCpf(valor);
        } else {
            return formatarCnpj(valor);
        }
    }
}

export function formatarCpf(cpf) {
    let valor = '';
    if (!!cpf && cpf !== '') {
        valor = cpf.slice(0, 3) + '.' + cpf.slice(3, 6) + '.' + cpf.slice(6, 9) + '-' + cpf.slice(9, 11);
    }
    return valor;
};

export function formatarCnpj(cnpj) {
    let valor = '';
    if (!!cnpj && cnpj !== '') {
        valor = cnpj.slice(0, 2) + '.' + cnpj.slice(2, 5) + '.' + cnpj.slice(5, 8) + '/' + cnpj.slice(8, 12) + '-' + cnpj.slice(12, 15);
    }
    return valor;
};

export const getBase64 = file => {
    return new Promise(resolve => {
        let baseURL = "";
        let reader = new FileReader();

        reader.readAsDataURL(file);

        reader.onload = () => {
            baseURL = reader.result;
            resolve(baseURL);
        };
    });
};

export function novaAbaNavegador(caminho) {
    return window.open(linkApi + caminho, '_blank');
};

export function isObjetoDiffVazio(obj = {}) {
    let valido = false;

    if (typeof obj === 'object') {
        if (!!obj && JSON.stringify(obj) !== '{}') {
            valido = true;
        }
    }
    return valido;
};

export function jsonp(url, timeout) {
    const func = 'jsonp_' + Math.random().toString(36).substr(2, 5);

    return new Promise(function (resolve, reject) {
        let script = document.createElement('script');

        let timer = setTimeout(() => {
            reject('Tempo limite atingido');
            document.body.removeChild(script);
        }, timeout);

        window[func] = (json) => {
            clearTimeout(timer);
            resolve(json);
            document.body.removeChild(script);
            delete window[func];
        };
        script.src = url + '?callback=' + encodeURI(func);
        document.body.appendChild(script);
    });
};

export async function carregarDadosPdv() {
    let busca = (await api.get(`Pdv/BuscarPorLocal/${getLocal()}`)).data;
    if (!!busca) {
        return busca;
    }
}

export const arredonda = (valor, casasDec = 2, retorno) => {
    if (typeof valor === 'number') {
        valor = valor.toFixed(casasDec);
    } else if (typeof valor === 'string') {
        valor = parseFloat(valor).toFixed(casasDec);
    } else {
        console.log('Valor informado não identificado! Favor informar um valor valido (Number,String)');
        return '';
    }
    if (retorno === 'number') {
        valor = parseFloat(valor);
    }

    return valor;
};


export const isNotNullOrEmpty = (registro) => {
    let retorno = false;
    if (!!registro || registro === 0) {
        retorno = true;
    }
    return retorno;
};


export function retornaValorPorExtenso(valor) {

    var ex = [
        ["zero", "um", "dois", "três", "quatro", "cinco", "seis", "sete", "oito", "nove", "dez", "onze", "doze", "treze", "quatorze", "quinze", "dezesseis", "dezessete", "dezoito", "dezenove"],
        ["dez", "vinte", "trinta", "quarenta", "cinquenta", "sessenta", "setenta", "oitenta", "noventa"],
        ["cem", "cento", "duzentos", "trezentos", "quatrocentos", "quinhentos", "seiscentos", "setecentos", "oitocentos", "novecentos"],
        ["mil", "milhão", "bilhão", "trilhão", "quadrilhão", "quintilhão", "sextilhão", "setilhão", "octilhão", "nonilhão", "decilhão", "undecilhão", "dodecilhão", "tredecilhão", "quatrodecilhão", "quindecilhão", "sedecilhão", "septendecilhão", "octencilhão", "nonencilhão"]
    ];
    valor = valor.toString().replace('.', ',');
    var a, n, v, i, n = valor.toString().replace(valor ? /[^,\d]/g : /\D/g, "").split(","), e = " e ", $ = "real", d = "centavo", sl;
    for (var f = n.length - 1, l, j = -1, r = [], s = [], t = ""; ++j <= f; s = []) {
        j && (n[j] = (("." + n[j]) * 1).toFixed(2).slice(2));
        if (!(a = (v = n[j]).slice((l = v.length) % 3).match(/\d{3}/g), v = l % 3 ? [v.slice(0, l % 3)] : [], v = a ? v.concat(a) : v).length) continue;
        for (a = -1, l = v.length; ++a < l; t = "") {
            if (!(i = v[a] * 1)) continue;
            i % 100 < 20 && (t += ex[0][i % 100]) ||
                i % 100 + 1 && (t += ex[1][(i % 100 / 10 >> 0) - 1] + (i % 10 ? e + ex[0][i % 10] : ""));
            s.push((i < 100 ? t : !(i % 100) ? ex[2][i == 100 ? 0 : i / 100 >> 0] : (ex[2][i / 100 >> 0] + e + t)) +
                ((t = l - a - 2) > -1 ? " " + (i > 1 && t > 0 ? ex[3][t].replace("?o", "?es") : ex[3][t]) : ""));
        }
        a = ((sl = s.length) > 1 ? (a = s.pop(), s.join(" ") + e + a) : s.join("") || ((!j && (n[j + 1] * 1 > 0) || r.length) ? "" : ex[0][0]));
        a && r.push(a + (valor ? (" " + (v.join("") * 1 > 1 ? j ? d + "s" : (/0{6,}$/.test(n[0]) ? "de " : "") + $.replace("l", "is") : j ? d : $)) : ""));
    }
    return r.join(e);
};

export function info(mensagem, titulo = null) {
    Modal.info({
        title: (!!titulo ? titulo : 'Atenção!'),
        content: (
            <div>
                <p>
                    {mensagem}
                </p>
            </div>
        ),
    });
};

export const removerCaracterEspecial = (valor) => {
    valor = valor.replaceAll(/[^a-zA-Z 0-9] +/g, '');
    return valor;
};

export function HasValue(value) {
    if (value === undefined || value === "" || value === null)
        return false
    return true
};

export const VericarSeEANProdutoBalanca = (codigoEan) => {
    if (HasValue(codigoEan) && codigoEan.length == 13 && codigoEan.startsWith('2')) {
        let parametrosEmpresa = getParametrosEmpresa();
        if (HasValue(parametrosEmpresa?.par_numerodigitos)) {
            //parametros.par_numerodigitos.Value + 1 é para considerar o 2.
            var _codBarrasProduto = codigoEan.substring(0, parametrosEmpresa.par_numerodigitos + 1);
            if (HasValue(_codBarrasProduto)) {
                return _codBarrasProduto;
            }
        }
    }
    return null;
};

export const RetornaPesoValorBalanca = (codigoEan, valorUnitarioProduto, qtdCasasDecimais = 3) => {
    let parametrosEmpresa = getParametrosEmpresa();
    var retorno = { quantidadeProdutoBalanca: 0, valorProdutoBalanca: 0 };

    let _qtdeZeros = "";
    let _valorCasasDecimais = parseInt("1" + _qtdeZeros.padStart(qtdCasasDecimais, '0'));

    if (parametrosEmpresa.par_pesoouvalor == 1)// Peso
    {
        var _peso = parseFloat(codigoEan.substring(7, 12));
        retorno.quantidadeProdutoBalanca = _peso / _valorCasasDecimais;
        retorno.valorProdutoBalanca = retorno.lpi_valorvenda;
    }
    else // Valor
    {
        var _valor = parseFloat(codigoEan.substring(7, 12));
        retorno.valorProdutoBalanca = _valor / 100;
        retorno.quantidadeProdutoBalanca = (retorno.valorProdutoBalanca / valorUnitarioProduto).toFixed(qtdCasasDecimais);
    }
    return retorno;
};

export function ContemLetrasENumeros(filtro) {
    let regexLetrasENumeros = /^(?=.*[a-zA-Z])(?=.*\d).+$/;
    let regexNumeros = /^\d+$/;

    if (regexLetrasENumeros.test(filtro))
        return 3;// contem letras e numeros
    else if (regexNumeros.test(filtro))
        return 2; // contem so numeros

    return 1; // contem so letras
};

export const arredondar = (numero, casasDecimais = 2, limiteArredondamento = 5) => {
    const fator = Math.pow(10, casasDecimais);
    const valorArredondado = Math.round(numero * fator) / fator;
    const proximaCasaDecimal = Math.round((numero * fator * 10) % 10);

    // Verifica se a próxima casa decimal é maior que o limite
    if (proximaCasaDecimal >= limiteArredondamento) {
        // Se for maior, arredonda para cima
        return Math.ceil(numero * fator) / fator;
    } else {
        // Se for menor ou igual, arredonda para baixo
        return Math.floor(numero * fator) / fator;
    }
};

