import { decodeBase64, encodeBase64 } from "./funcoes";

export const TOKEN_KEY = "@GestorWeb-Token";
export const EMPRESA_KEY = "@GestorWeb-Empresa";
export const LOCAL_KEY = "@GestorWeb-Local";
export const NOMEEMPRESA_KEY = "@GestorWeb-NomeEmpresa";
export const CNPJEMPRESA_KEY = "@GestorWeb-CnpjEmpresa";
export const NOMELOCAL_KEY = "@GestorWeb-NomeLocal";
export const REGIMETRIBUTARIO_KEY = "@GestorWeb-RegimeTributario";
export const NOMEUSUARIO_KEY = "@GestorWeb-NomeUsuario";
export const IDUSUARIO_KEY = "@GestorWeb-IdUsuario";
export const EMAILUSUARIO_KEY = "@GestorWeb-EmailUsuario";
export const VALIDADECERTIFICADO_KEY = "@GestorWeb-ValidadeCertificado";
export const TELA_KEY = "@GestorWeb-Tela";
export const NAVEGACAO_KEY = "@GestorWeb-Navegacao";
export const PERMISSOES_KEY = "@GestorWeb-Permissoes";
export const STATUSPLANO_KEY = "@GestorWeb-StatusPlano";
export const TELAANTERIOR_KEY = "GestorWeb-TelaAnterior";
export const STATUSFILIAL_KEY = "@GestorWeb-StatusFilial";
export const DADOS_NOTA_KEY = "@GestorWeb-DadosNota";
export const IDS_INTEGRA_KEY = "@GestorWeb-IdsIntegra";
export const MODO_BUSCA = "@GestorWeb-ModoBusca";
export const PARAMETROSEMPRESA_KEY = "@GestorWeb-ParametrosEmpresa";
export const linkApi = process.env.REACT_APP_PUBLIC_URL || (window.location.href.indexOf('localhost') > -1 ? 'https://localhost:7205/' : window.location.href.indexOf('homologacao') > -1 ? 'http://apihomologacao.newerp.com.br/api/' : 'https://api.newerp.com.br/api/');

export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;
export const getToken = () => localStorage.getItem(TOKEN_KEY);
export const getEmpresa = () => localStorage.getItem(EMPRESA_KEY);
export const getLocal = () => localStorage.getItem(LOCAL_KEY);
export const getNomeEmpresa = () => localStorage.getItem(NOMEEMPRESA_KEY);
export const getCnpjEmpresa = () => localStorage.getItem(CNPJEMPRESA_KEY);
export const getNomeLocal = () => localStorage.getItem(NOMELOCAL_KEY);
export const getRegimeTributario = () => localStorage.getItem(REGIMETRIBUTARIO_KEY);
export const getNomeUsuario = () => localStorage.getItem(NOMEUSUARIO_KEY);
export const getIdUsuario = () => localStorage.getItem(IDUSUARIO_KEY);
export const getEmailUsuario = () => localStorage.getItem(EMAILUSUARIO_KEY);
export const getTela = () => localStorage.getItem(TELA_KEY);
export const getNavegacao = () => decodeURIComponent(localStorage.getItem(NAVEGACAO_KEY));
export const getPermissao = () => decodeURIComponent(localStorage.getItem(PERMISSOES_KEY));
export const getValidadeCertificado = () =>{ return JSON.parse(localStorage.getItem(VALIDADECERTIFICADO_KEY));}
export const getTelaAnterior = () => localStorage.getItem(TELAANTERIOR_KEY);
export const getStatusPlano = () => localStorage.getItem(STATUSPLANO_KEY);
export const getStatusFilial = () => atob(localStorage.getItem(STATUSFILIAL_KEY)) === 'true';
export const getIdsIntegra = () => localStorage.getItem(IDS_INTEGRA_KEY);
export const getModoBusca = () => localStorage.getItem(MODO_BUSCA);// 1 - Buscar Normal pelas combos, 2 -  Busca pelo Leitor de Codigo de barras...


export const login = token => {
  localStorage.setItem(TOKEN_KEY, token);
};
export const setEmpresa = empresa => {
  localStorage.setItem(EMPRESA_KEY, empresa);
};
export const setLocal = local => {
  localStorage.setItem(LOCAL_KEY, local);
};
export const setNomeEmpresa = nomeEmpresa => {
  localStorage.setItem(NOMEEMPRESA_KEY, nomeEmpresa);
};
export const setCnpjEmpresa = cnpjEmpresa => {
  localStorage.setItem(CNPJEMPRESA_KEY, cnpjEmpresa);
}
export const setNomeLocal = nomeLocal => {
  localStorage.setItem(NOMELOCAL_KEY, nomeLocal);
};
export const setRegimeTributario = regimeTributario => {
  localStorage.setItem(REGIMETRIBUTARIO_KEY, regimeTributario);
};
export const setNomeUsuario = nomeUsuario => {
  localStorage.setItem(NOMEUSUARIO_KEY, nomeUsuario);
};
export const setIdUsuario = idUsuario => {
  localStorage.setItem(IDUSUARIO_KEY, idUsuario);
};
export const setEmailUsuario = emailUsuario => {
  localStorage.setItem(EMAILUSUARIO_KEY, emailUsuario);
};
export const setTela = tela => {
  localStorage.setItem(TELA_KEY, tela);
};
export const setNavegacao = nav => {
  localStorage.setItem(NAVEGACAO_KEY, encodeURIComponent(JSON.stringify(nav)));
};
export const setPermissoes = perm => {
  localStorage.setItem(PERMISSOES_KEY, encodeURIComponent(JSON.stringify(perm)));
}
export const setStatusPlano = stp => {
  localStorage.setItem(STATUSPLANO_KEY, stp);
};
export const setTelaAnterior = tela => {
  localStorage.setItem(TELAANTERIOR_KEY, tela);
};
export const setIdsIntegra = ids => {
  localStorage.setItem(IDS_INTEGRA_KEY, ids);
}
export const setModoBusca = modoBusca => {
  localStorage.setItem(MODO_BUSCA, modoBusca);
}
export const setParametrosEmpresa = par => {
  localStorage.setItem(PARAMETROSEMPRESA_KEY, JSON.stringify(par));
}
export const setValidadeCertificado = dados => {
  localStorage.setItem(VALIDADECERTIFICADO_KEY, JSON.stringify(dados));
}
export const setDadosNota = dadosNota => {
  if (dadosNota != null && dadosNota != 'null') {
    localStorage.setItem(DADOS_NOTA_KEY, JSON.stringify(dadosNota));
  } else {
    localStorage.setItem(DADOS_NOTA_KEY, null);
  }
}
export const removeDadosNota = () => {
  localStorage.removeItem(DADOS_NOTA_KEY);
  localStorage.removeItem(IDS_INTEGRA_KEY);
}
export const removerTelaAnt = () => {
  localStorage.removeItem(TELAANTERIOR_KEY);
}
export const setStatusFilial = ativo => {
  localStorage.setItem(STATUSFILIAL_KEY, btoa(ativo));
};
export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(EMPRESA_KEY);
  localStorage.removeItem(LOCAL_KEY);
  localStorage.removeItem(NOMEEMPRESA_KEY);
  localStorage.removeItem(CNPJEMPRESA_KEY);
  localStorage.removeItem(NOMELOCAL_KEY);
  localStorage.removeItem(REGIMETRIBUTARIO_KEY);
  localStorage.removeItem(NOMEUSUARIO_KEY);
  localStorage.removeItem(IDUSUARIO_KEY);
  localStorage.removeItem(EMAILUSUARIO_KEY);
  localStorage.removeItem(TELA_KEY);
  localStorage.removeItem(NAVEGACAO_KEY);
  localStorage.removeItem(STATUSPLANO_KEY);
  localStorage.removeItem(TELAANTERIOR_KEY);
  localStorage.removeItem(STATUSFILIAL_KEY);
  localStorage.removeItem(DADOS_NOTA_KEY);
  localStorage.removeItem(IDS_INTEGRA_KEY);
  localStorage.removeItem(PARAMETROSEMPRESA_KEY);
  localStorage.removeItem(VALIDADECERTIFICADO_KEY);
  if (window.location.pathname !== '/') window.location = '/';
};

export const getUserInfo = () => {
  let token = localStorage.getItem(TOKEN_KEY);
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}

export const getDadosNota = () => {
  let dadosNotaF5 = (localStorage.getItem(DADOS_NOTA_KEY) != null && localStorage.getItem(DADOS_NOTA_KEY) != 'null') ? JSON.parse(localStorage.getItem(DADOS_NOTA_KEY)) : null;
  if (dadosNotaF5 != null && dadosNotaF5 != 'null') {
    var _dadosNotaF5 = { ...dadosNotaF5 };
    _dadosNotaF5.faturamento = dadosNotaF5?.notaFiscalFaturamento ?? dadosNotaF5.faturamento;
    _dadosNotaF5.itens = dadosNotaF5?.notafiscalitens ?? dadosNotaF5.itens;
    return _dadosNotaF5;
  }
  return null;
}
export const getParametrosEmpresa = () => {
  let dados = localStorage.getItem(PARAMETROSEMPRESA_KEY);
  return JSON.parse(dados);
}
