import React, { useState, useEffect, useDebugValue } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Row, Col, Form, Button, Input, Modal, notification } from "antd";
import { ArrowLeftOutlined, ArrowUpOutlined, ArrowDownOutlined, CloseOutlined, CheckOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import moment from "moment";

import api from "../../services/api";
import apiPayer from "../../services/apiPayer";
import { useStateValue } from "../../state";
import { FormatNumber } from "../../ValueObjects";
import { dadosPdvActions, manutencaoActions } from "../../actions";
import { FormGW, BreadcrumbPage } from "../../components";
import { getCnpjEmpresa, getDadosNota, getEmpresa, getIdsIntegra, getLocal, getParametrosEmpresa, removeDadosNota, setDadosNota, setIdsIntegra } from "../../services/auth";
import { DadosCliente, ResumoVenda, FormaPagamento, FormasPagamento } from "./pages";
import { HasValue, arredondar, carregarDadosPdv, decodeBase64, encodeBase64, isObjetoDiffVazio, novaAbaNavegador, retornaSoNumeros } from "../../services/funcoes";

export default function FinalizarVenda() {

    const [formulario] = Form.useForm();
    const [{ manutencao, dadosPdv }, dispatch] = useStateValue();
    const [observacaoPagamento, setObservacaoPagamento] = useState(false);
    const [clienteDados, setClienteDados] = useState({});
    const [totais, setTotais] = useState({});
    const [listaPagamentos, setListaPagamentos] = useState([]);
    const [pagamentoTotal, setPagamentoTotal] = useState(false);
    const [carregando, setCarregando] = useState(false);
    const [abreModalAntecipacao, setAbreModalAntecipacao] = useState(false);
    const [dadosAntecipacao, setDadosAntecipacao] = useState([]);
    const [possuiAntecipacao, setPossuiAntecipacao] = useState(false);
    const [cliente, setCliente] = useState({});
    const [dadosVendedor, setDadosVendedor] = useState({});
    const [alterou, setAlterou] = useState(false);
    const navigation = useNavigate();
    const [permiteEdicaoDescontoAcrescimo, setPermiteEdicaoDescontoAcrescimo] = useState(true);
    const [frete, setFrete] = useState(null);
    const [switchState, setSwitchState] = useState(false);
    const [enderecoDestino, setEnderecoDestino] = useState({});

    const tipoPagamento = {
        cartaoCredito: 3,
        cartaoDebito: 4,
        pix: 17,
    };
    const statusPagamentoIntegraPagto = {
        Aprovada: 1,
        Rejeitada: 2,
        Pendente: 3,
        Cancelada: 4,
        Abortada: 5,
        Finalizada: 6,
        NaoAutorizada: 7,
        PendenteEstorno: 8,
    }
    const verObservacao = () => {
        setObservacaoPagamento(!observacaoPagamento)
    };

    const handleKeyPress = (event) => {
        if (event.key === 'PageDown') {
            modalImprimirCupom();
        } else if (event.key === 'F8') {
            cancelarVenda();
        }
    } 

    useEffect(() => {
        window.addEventListener('keydown', handleKeyPress);
        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        }
    }, [manutencao.dados, clienteDados, dadosVendedor])

    useEffect(() => {
        if (!HasValue(dadosPdv?.pdv)) {
            verificaPdv();
        }
        let dadosNotaF5 = getDadosNota();
        if (dadosNotaF5 != 'null' && dadosNotaF5 != null && isObjetoDiffVazio(dadosNotaF5)) {
            setCliente(dadosNotaF5.cliente);
            dispatch({ type: manutencaoActions.CHANGE, data: { dados: { ...manutencao.dados, ...dadosNotaF5 } } });
            setAlterou(true);
        }
    }, [])

    useEffect(() => {
        if (!!manutencao?.dados) {
            if (!!manutencao.dados.totais && !isObjetoDiffVazio(totais)) {
                setTotais(manutencao.dados.totais);
            }
            if (!!manutencao.dados?.vendedor) {
                setDadosVendedor(manutencao.dados.vendedor);
            }
            if (!!manutencao.dados?.cliente) {
                if (!!manutencao.dados.cliente.cli_percdescontopadrao && manutencao.dados.cliente.cli_percdescontopadrao > 0) {
                    descontoPadraoCliente();
                }
            }
            formulario.setFieldsValue(manutencao.dados);
        }
    }, [manutencao.dados])


    useEffect(() => {
        if (listaPagamentos.length === 0) {
            setPermiteEdicaoDescontoAcrescimo(true);
        } else {
            setPermiteEdicaoDescontoAcrescimo(false);
        }
    }, [listaPagamentos])

    useEffect(() => {
        if (isObjetoDiffVazio(manutencao.dados) && alterou) {
            modalImprimirCupom()
        }
    }, [alterou])

    function descontoPadraoCliente() {
        const valorTotal = manutencao.dados.totais.valorTotalSub
        const valorDesconto = parseFloat((valorTotal * (manutencao.dados.cliente.cli_percdescontopadrao / 100)).toFixed(2));

        formulario.setFieldsValue({
            valorTotalDesconto: valorDesconto,
            percentualDesconto: manutencao.dados.cliente.cli_percdescontopadrao
        });

        const valorTemp = valorTotal - valorDesconto;
        const totaisTemp = { ...totais };
        totaisTemp.valorTotal = valorTemp;
        totaisTemp.valorTotalSub = valorTotal;
        setTotais(totaisTemp);
    }

    async function imprimirNotas(informacoesNota, parametrosEmpresa) {
        if (informacoesNota.ntf_docfiscal === true) {
            if (parametrosEmpresa.par_impressaonfce === 0) {
                novaAbaNavegador(`Danfe/GerarDanfe?chaveNFe=${informacoesNota.ntf_chavenfe}`);
            }
            if (parametrosEmpresa.par_impressaonfce === 1) {
                modalImprimirDocNFCe(informacoesNota);
            }
            if (parametrosEmpresa.par_impressaonfce === 2) {
                return false;
            }
        } else {
            if (parametrosEmpresa.par_impressaodavls === 0) {
                novaAbaNavegador(`NotaFiscal/Imprimir?id=${informacoesNota.ntf_id}`);
            }
            if (parametrosEmpresa.par_impressaodavls === 1) {
                modalImprimirDocDAVs(informacoesNota);
            }
            if (parametrosEmpresa.par_impressaodavls === 2) {
                return false;
            }
        }
    }

    function modalImprimirDocNFCe(informacoesNota) {
        Modal.confirm({
            title: 'Aviso',
            content: 'Deseja realizar a impressão do comprovante deste Cupom Fiscal',
            okText: 'Sim',
            cancelText: 'Não',
            onOk: () => {
                novaAbaNavegador(`Danfe/GerarDanfe?chaveNFe=${informacoesNota.ntf_chavenfe}`);
            }
        })
    }

    function modalImprimirDocDAVs(informacoesNota) {
        Modal.confirm({
            title: 'Aviso',
            content: 'Deseja realizar a impressão do comprovante deste Cupom Fiscal',
            okText: 'Sim',
            cancelText: 'Não',
            onOk: () => {
                novaAbaNavegador(`NotaFiscal/Imprimir?id=${informacoesNota.ntf_id}`);
            }
        })
    }

    function modalImprimirCupom() {
        let parametrosEmpresa = getParametrosEmpresa();

        if (!!!manutencao.dados.faturamento || manutencao.dados.faturamento.length === 0) {
            notification.warning({ message: 'Aviso!', description: 'Favor preencher a forma de pagamento!' });
            return;
        }
        if (manutencao.dados.totais.restaPagar > 0) {
            notification.warning({ message: 'Aviso!', description: 'Valor das formas de pagamento não fecham com o valor total da nota!' });
            return;
        }
        let docfiscal = true;
        let temFormaComIntegracao = manutencao.dados.faturamento.some(pagamento => 
            pagamento && pagamento.fpg_tipointegracao != null
          );


        if (!parametrosEmpresa.ope_id) {
            Modal.warning({
                title: 'Aviso',
                content: 'Operação não cadastrado nos parametros da empresa!',
                okText: 'Ok',
                onOk: () => {
                    return false;
                }
            })
        } else {
            if (!temFormaComIntegracao && !!parametrosEmpresa.par_69) {
                Modal.confirm({
                    title: 'Aviso',
                    content: 'Deseja emitir o cupom fiscal agora?',
                    okText: 'Sim',
                    cancelText: 'Não',
                    onOk: () => {
                        analisaFormaPagamento(docfiscal);
                    },
                    onCancel: () => {
                        docfiscal = false;
                        analisaFormaPagamento(docfiscal);
                    }
                })
            } else {
                analisaFormaPagamento(docfiscal);
            }
        }
    }

    function tratamentoDadosDestinatario() {
        if (!!manutencao.dados.notaFiscalDestinatario) {
            return manutencao.dados.notaFiscalDestinatario;
        } else {
            let telefone = cliente?.pessoatelefones ? cliente.pessoatelefones.filter(x => x.psc_principal == true && x.psc_ativo === true)[0] : [];
            let endereco = cliente?.enderecos ? cliente.enderecos.filter(x => x.pee_enderecoprincipal === true && x.pee_ativo === true)[0] : [];
            let email = cliente?.pessoaemails ? cliente.pessoaemails.filter(x => x.pem_emailprincipal === true && x.pem_ativo === true)[0] : [];

            if (switchState === false) {
                let destinatario = {
                    pes_id: !!cliente?.pes_id ? cliente.pes_id : null,
                    pes_fisicajuridica: !!cliente?.pes_fisicajuridica ? cliente.pes_fisicajuridica : 0,
                    ntf_nomedest: !!cliente?.pes_nome ? cliente.pes_nome : '',
                    ntf_cnpjcpfdest: retornaSoNumeros(!!cliente.pef_cpf ? cliente.pef_cpf : (!!cliente.pej_cnpj ? cliente.pej_cnpj : (!!cliente.cpfCnpj ? cliente.cpfCnpj : ''))),
                    ntf_iedest: "",
                    ntf_logradourodest: !!endereco ? (!!endereco.log_logradouro ? endereco.log_logradouro : (!!endereco.logradouro ? endereco.logradouro : '')) : '',
                    ntf_numeroenderecodest: !!endereco ? !!endereco.pee_numero ? endereco.pee_numero : '' : '',
                    ntf_complementodest: !!endereco ? (!!endereco.pee_complemento ? endereco.pee_complemento : '') : '',
                    ntf_bairrodest: !!endereco ? (!!endereco.bai_nome ? endereco.bai_nome : (!!endereco.bairro ? endereco.bairro : '')) : '',
                    ntf_cepdest: !!endereco ? (!!endereco.cep ? endereco.cep : (!!endereco.cep_cep ? endereco.cep_cep : '')) : '',
                    ntf_ufdest: !!endereco ? !!endereco.est_sigla ? endereco.est_sigla : '' : '',
                    ntf_telefonedest: !!telefone ? !!telefone.psc_numero ? telefone.psc_numero : '' : '',
                    ntf_emaildest: !!email ? email.pem_email : ""
                };
                return destinatario;
            } else {
                let dados = enderecoDestino;
                let destinatario = {
                    pes_id: !!cliente?.pes_id ? cliente.pes_id : null,
                    pes_fisicajuridica: !!cliente?.pes_fisicajuridica ? cliente.pes_fisicajuridica : 0,
                    ntf_nomedest: !!cliente?.pes_nome ? cliente.pes_nome : '',
                    ntf_cnpjcpfdest: retornaSoNumeros(!!cliente.pef_cpf ? cliente.pef_cpf : (!!cliente.pej_cnpj ? cliente.pej_cnpj : (!!cliente.cpfCnpj ? cliente.cpfCnpj : ''))),
                    ntf_iedest: "",
                    ntf_logradourodest: dados.log_logradouro,
                    ntf_numeroenderecodest: dados.pee_numero ? dados.pee_numero : '',
                    ntf_complementodest: dados.pee_complemento ? dados.pee_complemento : '',
                    ntf_bairrodest: dados.bai_nome,
                    ntf_cepdest: dados.cep_cep,
                    ntf_ufdest: dados.est_sigla ? dados.est_sigla : '',
                    ntf_telefonedest: !!telefone ? !!telefone.psc_numero ? telefone.psc_numero : '' : '',
                    ntf_emaildest: !!email ? email.pem_email : ""
                };
                return destinatario;
            }
        }
    }

    async function analisaFormaPagamento(docfiscal) {
        manutencao.dados.docfiscal = docfiscal;
        let pagamentoMaquininha = false;
        for (var pgto of manutencao.dados.faturamento) {
            if (pgto.fpg_tipopagamento === tipoPagamento.cartaoCredito || pgto.fpg_tipopagamento === tipoPagamento.cartaoDebito || pgto.fpg_tipopagamento === tipoPagamento.pix) {
                if (pgto.fpg_tipointegracao != null && pgto.integrapgtook == false) {
                    if (pgto.fpg_tipointegracao == 1) {// vero
                        pagamentoMaquininha = true;
                        if (HasValue(pgto.imp_id)) { // Testa quando u user der um F5 na tela e já fez a request
                            modalAguardandoPagamento(docfiscal, pgto);
                            break;
                        }
                        pgto.integrapgto = await pagamentoVero(pgto);
                        pgto.imp_id = pgto.integrapgto.imp_id;
                        pgto.integrapgtook = false;

                        modalAguardandoPagamento(docfiscal, pgto);
                        break;
                    } else if (pgto.fpg_tipointegracao == 2) {//payer
                        pagamentoMaquininha = true;
                        if (isObjetoDiffVazio(pgto.integrapgto) && pgto.integrapgto.status === 200) {// Testa quando u user der um F5 na tela e já fez a request
                            modalAguardandoPagamento(docfiscal, pgto);
                            break;
                        }
                        pgto.integrapgto = await pagamentoPayer(pgto);
                        pgto.integrapgtook = false;
                        modalAguardandoPagamento(docfiscal, pgto);
                        break;
                    }
                } else {
                    pagamentoMaquininha = false;
                }
            }
        }
        setDadosNota(manutencao.dados);
        if (pagamentoMaquininha == false) {
            concluirVenda(docfiscal);
        }
    }
    async function pagamentoVero(item) {
        let dadosIntegracaoPos = {};
        let cnpjEmpresa = getCnpjEmpresa();
        let tipopgto = 0;
        let metodopgto = 0;
        let submetodopgto = 0;

        if (item.fpg_tipopagamento === tipoPagamento.cartaoCredito) {
            tipopgto = 2;
            metodopgto = 1;
            submetodopgto = 4;
            if (item.parcelas?.length == 1) {
                submetodopgto = 1
            }
        }
        if (item.fpg_tipopagamento === tipoPagamento.cartaoDebito) {
            tipopgto = 3;
            metodopgto = 1;
            submetodopgto = 1;
            if (item.parcelas?.length >= 1) {
                submetodopgto = 3
            }
        }
        if (item.fpg_tipopagamento === tipoPagamento.pix) {
            tipopgto = 1;
            metodopgto = 3;
            submetodopgto = 1
        }
        dadosIntegracaoPos = {
            imp_cnpjlocal: cnpjEmpresa,
            imp_valor: item.pfp_valor,
            imp_tipopgto: tipopgto,
            imp_metodopgto: metodopgto,
            imp_submetodopgto: submetodopgto,
            imp_qtdeparcelas: item.parcelas?.length,
        }
        if (!isObjetoDiffVazio(item.integrapgto)) {
            return await criaRegistroIntegraPagto(dadosIntegracaoPos);

        }
    }
    async function pagamentoPayer(item) {
        let _paymentType = "";
        let _paymentMethodSubType = "";
        let _installments = "";
        let _paymentMethod = "";
        let _command = "payment"

        if (item.fpg_tipopagamento === tipoPagamento.cartaoCredito) {
            _paymentMethod = "Card";
            _paymentType = "Credit";
            _paymentMethodSubType = "Financed_no_Fees";

            if (item.parcelas?.length == 1) {
                _paymentMethodSubType = "Full_Payment";
            } else {
                _installments = (item.parcelas?.length).toString();
            }
        }
        if (item.fpg_tipopagamento === tipoPagamento.cartaoDebito) {
            if (item.parcelas?.length >= 1) {
                if (item.parcelas.length == 1) {
                    _paymentMethodSubType = "Predated_Debit";
                } else {
                    _paymentMethodSubType = "Financed_Debit";
                }
                _installments = (item.parcelas?.length).toString();
            } else {
                _paymentMethodSubType = "Full_Payment";
            }
            _paymentMethod = "Card";
            _paymentType = "Debit"
        }
        if (item.fpg_tipopagamento === tipoPagamento.pix) {
            _paymentType = "Cash";
            _paymentMethod = "Pix";
        }

        let dadosIntegracaoPos = {
            command: _command,
            value: parseFloat(item.pfp_valor),
            paymentMethod: _paymentMethod,
            paymentType: _paymentType,
            paymentMethodSubType: _paymentMethodSubType,
            installments: _installments
        };
        if (!isObjetoDiffVazio(item.integrapgto)) {
            try {
                return await apiPayer.post('Client/Request', dadosIntegracaoPos);
            } catch (error) {
                notification.warning({ description: 'Verifique se o checkout Payer esteja iniciado, caso contrário inicie o mesmo para continuar !', message: 'AVISO!' });
            }
        }
    };

    function modalAguardandoPagamento(docfiscal, pgto) {
        Modal.confirm({
            title: 'Aviso',
            content: 'Aguardando Pagamento!',
            okText: 'Pagamento Efetuado',
            cancelText: 'Cancelar Pagamento',
            onOk: () => {
                verificarPagamentoMaquina(docfiscal, pgto);
            },
            onCancel: () => {
                cancelarPagamentoMaquina(docfiscal, pgto);
            }
        });
    }

    async function verificarPagamentoMaquina(docfiscal, pgto) {
        if (pgto.fpg_tipopagamento === tipoPagamento.cartaoCredito || pgto.fpg_tipopagamento === tipoPagamento.cartaoDebito || pgto.fpg_tipopagamento === tipoPagamento.pix) {
            if (pgto.fpg_tipointegracao != null && pgto.integrapgtook == false) {
                if (pgto.fpg_tipointegracao == 1)//Vero
                {
                    let retorno = (await api.get(`IntegraPagto/Buscar/${pgto.integrapgto.imp_id}`)).data;
                    if (retorno.imp_statuspgto == statusPagamentoIntegraPagto.Pendente) {
                        modalAguardandoPagamento(docfiscal, pgto);
                    } else if (retorno.imp_statuspgto == statusPagamentoIntegraPagto.Aprovada) {
                        pgto.integrapgtook = true;
                        pgto.integrapgto = retorno;
                        pgto.rcc_nsu = retorno.imp_autorizacao;
                        await verificaTodosPagamentos(docfiscal, pgto);
                    } else if (retorno.imp_statuspgto == statusPagamentoIntegraPagto.NaoAutorizada) {
                        notification.warning({ description: 'Pagamento não Autorizado!', message: 'Aviso!' });
                    } else if (retorno.imp_statuspgto == statusPagamentoIntegraPagto.Cancelada) {
                        notification.warning({ description: 'Pagamento Cancelado!', message: 'Aviso!' });
                    }
                } else if (pgto.fpg_tipointegracao == 2) //Payer
                {
                    let retorno;
                    try {
                        retorno = (await apiPayer.get('Client/Response')).data;
                        console.log(retorno);
                    } catch (error) {
                        notification.warning({ description: 'Verifique se o checkout Payer esteja iniciado, caso contrário inicie o mesmo para continuar !', message: 'AVISO!' });
                        modalAguardandoPagamento(docfiscal, pgto);
                    }
                    if (retorno.statusTransaction == "PENDING") {
                        modalAguardandoPagamento(docfiscal, pgto);
                    } else if (retorno.statusTransaction == "APPROVED") {
                        let ret = (await api.post(`IntegraPagto/IncluirPayer`, retorno)).data;
                        pgto.integrapgtook = true;
                        pgto.integrapgto = ret;
                        pgto.rcc_nsu = ret.authorizerId;
                        pgto.imp_id = ret.imp_id;
                        await verificaTodosPagamentos(docfiscal, pgto);
                    } else if (retorno.statusTransaction == "UNAUTHORIZED") {
                        notification.warning({ description: 'Pagamento não Autorizado, verifique no Checkout Payer e tente novamente!', message: 'Aviso!' });
                        return;
                    } else if (retorno.statusTransaction == "ABORTED") {
                        notification.warning({ description: 'Pagamento Cancelado!', message: 'Aviso!' });
                    }
                }
            }
        }
    }

    async function verificaTodosPagamentos(docfiscal) {
        let finalizarVenda = true;
        let listaFaturamento = manutencao.dados.faturamento;
        listaFaturamento.forEach(item => {
            if (item.fpg_tipopagamento === tipoPagamento.cartaoCredito || item.fpg_tipopagamento === tipoPagamento.cartaoDebito || item.fpg_tipopagamento === tipoPagamento.pix) {
                if (item.fpg_tipointegracao != null && item.integrapgtook == false) {
                    finalizarVenda = false;
                    analisaFormaPagamento(docfiscal);
                }
            }
        });
        if (finalizarVenda == true) {
            await concluirVenda(docfiscal);
        }
    }

    async function cancelarPagamentoMaquina(docfiscal, dadosNotaF5) {
        let listaFaturamento = manutencao?.dados?.faturamento ?? dadosNotaF5?.faturamento;
        for (var item of listaFaturamento) {
            if (item.fpg_tipopagamento === tipoPagamento.cartaoCredito || item.fpg_tipopagamento === tipoPagamento.cartaoDebito || item.fpg_tipopagamento === tipoPagamento.pix) {
                if (item.fpg_tipointegracao == 2) {// payer
                    item = await verificaCancelamentoPagamentoPayer(docfiscal, item)
                }
                let retorno = (await api.put(`IntegraPagto/CancelarPagamento?Id=${item.integrapgto.imp_id}`)).data;
                if (retorno) {
                    item.integrapgtook = false;
                    item.integrapgto = null;
                    notification.warning({ description: 'Pagamento Cancelado!', message: 'Aviso!' });
                } else {
                    notification.warning({ description: 'Erro ao efetuar o cancelamento!', message: 'Aviso!' });
                    modalAguardandoPagamento(docfiscal)
                    break;
                }
            }
        }
    }
    async function verificaCancelamentoPagamentoPayer(docfiscal, pagamento) {
        if (pagamento.fpg_tipointegracao != null && (pagamento.integrapgtook == false) && pagamento.fpg_tipointegracao == 2) {// payer
            let retorno;
            try {
                retorno = (await apiPayer.get('Client/Response')).data;

            } catch (error) {
                notification.warning({ description: 'Verifique se o checkout Payer esteja iniciado, caso contrário inicie o mesmo para continuar !', message: 'AVISO!' });
            }

            if (retorno.statusTransaction == "PENDING") {

            } else if (retorno.statusTransaction == "APPROVED") {
                let ret = (await api.post(`IntegraPagto/IncluirPayer`, retorno)).data;
                pagamento.integrapgtook = true;
                pagamento.integrapgto = ret;
                pagamento.rcc_nsu = ret.authorizerId;
                pagamento.imp_id = ret.imp_id;
                await cancelamentoPayer(pagamento);
            } else if (retorno.statusTransaction == "UNAUTHORIZED") {
                notification.warning({ description: 'Pagamento não Autorizado, verifique no Checkout Payer e tente novamente!', message: 'Aviso!' });
            } else if (retorno.statusTransaction == "ABORTED") {
                notification.warning({ description: 'Cancelamento Cancelado no Checkout Payer, enviando cancelamento novamente!', message: 'Aviso!' });
                await cancelamentoPayer(pagamento);
            }
        }
        return pagamento;
    };
    async function cancelamentoPayer(pagto) {
        let dadosIntegracaoPos = {
            command: "cancellment",
            idPayer: pagto.integrapgto.idPayer,
        };
        let retorno;
        try {
            retorno = (await apiPayer.post('Client/Request', dadosIntegracaoPos)).data;
        } catch (error) {
            notification.warning({ description: 'Verifique se o checkout Payer esteja iniciado, caso contrário inicie o mesmo para continuar !', message: 'AVISO!' });
        }
        let retornoCancelamento;
        try {
            retornoCancelamento = (await apiPayer.get('Client/Response')).data;

            while (retornoCancelamento.statusTransaction === "PENDING") {
                console.log("Status ainda é pendente...");

                await new Promise(resolve => setTimeout(resolve, 1000)); // Espera 1 segundo

                retornoCancelamento = (await apiPayer.get('Client/Response')).data;
            }

            let ret = (await api.post(`IntegraPagto/IncluirPayer`, retornoCancelamento)).data;

        } catch (error) {
            notification.warning({ description: 'Verifique se o checkout Payer esteja iniciado, caso contrário inicie o mesmo para continuar !', message: 'AVISO!' });
        }
    }

    async function concluirVenda(docfiscal) {
        let parametrosEmpresa = getParametrosEmpresa();
        let values = {};
        let valorTotal = 0;
        values.notaFiscalDestinatario = tratamentoDadosDestinatario();
        values.notaFiscalFaturamento = manutencao.dados.faturamento;
        values.notaFiscalFaturamento.forEach((item) => {
            item.nfp_valor = item.pfp_valor;
            valorTotal += parseFloat(item.pfp_valor);
        });

        if (parseFloat(formulario.getFieldValue().valorTotal) > parseFloat(valorTotal.toFixed(2))) {
            notification.warning({ message: 'Aviso!', description: 'Valor do pagamento menor do que o valor da nota!' });
            return false;
        }
        values.pdv_id = dadosPdv.pdv?.pdv_id;
        values.ven_id = dadosVendedor.ven_id;
        values.cli_id = cliente.cli_id;
        values.ope_id = !!parametrosEmpresa.ope_id ? parametrosEmpresa.ope_id : null;
        values.ser_id = null;
        if (docfiscal) {
            let _dadosPdv = null;
            if (!HasValue(dadosPdv?.pdv)) {
                _dadosPdv = await carregarDadosPdv();
            }
            values.ser_id = HasValue(dadosPdv.pdv?.ser_id) ? dadosPdv.pdv?.ser_id : _dadosPdv.ser_id;
        }
        values.ntf_dataemissao = moment(new Date());
        values.ntf_dhsaient = moment(new Date());
        values.valorFrete = formulario.getFieldValue().valorFrete;
        values.ntf_indicadorpresenca = 1;// Operação presencial
        if(HasValue(values.valorFrete) && values.valorFrete > 0)
            
        values.valorSeguro = 0;
        values.ntf_infcomplementar = !!formulario.getFieldValue().observacao ? formulario.getFieldValue().observacao : "";
        values.valorAcrescimo = parseFloat(formulario.getFieldValue().valorTotalAcrescimo).toFixed(2);
        values.valorDesconto = parseFloat(formulario.getFieldValue().valorTotalDesconto).toFixed(2);
        values.ntf_docfiscal = docfiscal;
        values.ntf_modalidadefrete = 9;
        values.notafiscalitens = manutencao?.dados?.itens ?? [];
        values.ntf_consumidorfinal = 1;

        if (switchState === true) {
            values.notaFiscalTransportadora = {
                ntf_modalidadefrete: 1,
                ntf_dhsaient: moment(new Date()),
                trn_id: enderecoDestino.trn_id
            };
            values.ntf_indicadorpresenca = 4;
            values.ntf_modalidadefrete = 1;
        } else {
            values.notaFiscalTransportadora = {
                ntf_modalidadefrete: 9,
                ntf_dhsaient: moment(new Date()),
                trn_id: enderecoDestino.trn_id
            };
        }

        if (values.notaFiscalFaturamento.length > 0) {
            values.notaFiscalFaturamento[0].cheques = (!!values.notaFiscalFaturamento[0].cheques ? values.notaFiscalFaturamento[0].cheques : []);
        }
        if (!!values.antecipacoes) {
            if (values.notaFiscalFaturamento.length === 0) {
                values.notaFiscalFaturamento[0] = { antecipacoes: {} };
            }
        }
        values.totais = manutencao?.dados?.totais ?? {};
        values.cliente = manutencao?.dados?.cliente ?? {};
        setDadosNota(values);
        setCarregando(true);
        api.post(`NotaFiscal/Incluir`, values).then(
            res => {
                let informacoesNota = res.data;
                if (informacoesNota.ntf_status === 1) {
                    notification.success({ message: 'Sucesso', description: 'Venda realizada com sucesso!' });
                    imprimirNotas(informacoesNota, parametrosEmpresa);
                    let formaPagItegrado = informacoesNota.notafiscalformapgtos.filter((item) => (HasValue(item.imp_id)))
                    if (formaPagItegrado.length > 0) {
                        novaAbaNavegador(`Danfe/GerarComprovantePagamentoViaLoja?IdNotaFiscal=${informacoesNota.ntf_id}`);
                    }
                    if (!!informacoesNota.contaspagarreceber && informacoesNota.contaspagarreceber.length > 0) {
                        novaAbaNavegador(`ContasPagarReceber/ImprimirCarnePagamento?IdNotaFiscal=${informacoesNota.ntf_id}`);
                        novaAbaNavegador(`ContasPagarReceber/Imprimir?IdNotaFiscal=${informacoesNota.ntf_id}`);
                    }
                } else if (informacoesNota.ntf_status === 5) {
                    notification.warning({ message: 'Atenção', description: 'Venda emitida em contingência. Por favor, assegure-se de aprová-la posteriormente. !' });
                    if (res.data.ntf_docfiscal === true) {
                        novaAbaNavegador(`Danfe/GerarDanfe?chaveNFe=${informacoesNota.ntf_chavenfe}`);
                    } 
                }else {
                    Modal.warning({
                        title: "Erro ao efetuar a Venda!",
                        content: informacoesNota.ntf_xmotivo,
                        okText: 'Ok',
                        okType: 'danger',
                        centered: true
                    });
                }
                removeDadosNota();
                dispatch({ type: manutencaoActions.CHANGE, data: { dados: null } });
                navigation("../venda");
                //window.location = "/Home#/venda";
            }
        ).catch(
            error => {
                console.log(error);
                Modal.warning({
                    title: "Aviso!",
                    content: error.response.data,
                    okText: 'Ok',
                    okType: 'danger',
                    centered: true,
                    onOk() {
                        dispatch({ type: manutencaoActions.CHANGE, data: { dados: null } });
                        navigation("../vendaRealizada");
                        //window.location = "/Home#/venda";
                    }
                });
            }
        ).finally(() => {
            verificaPdv();
            window.produtoVenda = [];
            setCarregando(false);
        });
    }

    async function verificaPdv() {
        let res = await carregarDadosPdv();
        dispatch({ type: dadosPdvActions.CHANGE, data: { pdv: res } });
    }

    async function criaRegistroIntegraPagto(dadosIntegracaoPos) {
        let retorno;
        try {
            retorno = (await api.post(`IntegraPagto/Incluir`, dadosIntegracaoPos)).data;
        } catch (error) {
            notification.warning({ description: 'Erro ao mandar o pagamento para a máquina!', message: 'AVISO!' });
            console.log(error);
            return false;
        }
        return retorno;
    }

    function cancelarVenda() {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação e ignorar o que foi realizado até o momento?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                removeDadosNota();
                dispatch({ type: manutencaoActions.CHANGE, data: { dados: { vendedor: dadosVendedor, itens: [] }, voltaVenda: true } })
                navigation("../venda");
            }
        });
    }

    function voltarVenda() {
        removeDadosNota();
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: { ...manutencao.dados }, voltaVenda: true } });
    }

    return (
        <FormGW layout="vertical" className="p-b-30" name="finalizarVenda" form={formulario}>
            <BreadcrumbPage pagina="Venda" pagina_seleciona="Finalizar Venda" />
            <Row justify="center" align="middle" gutter={[16, 16]}>
                <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={7} className="hgCard">
                    <DadosCliente cliente={cliente} setCliente={setCliente} dadosCliente={clienteDados} setFrete={setFrete} setEnderecoDestino={setEnderecoDestino} setDadosCliente={setClienteDados} switchState={switchState} setSwitchState={setSwitchState} formulario={formulario} abreModalAntecipacao={abreModalAntecipacao} setAbreModalAntecipacao={setAbreModalAntecipacao} dadosAntecipacao={dadosAntecipacao} setDadosAntecipacao={setDadosAntecipacao} possuiAntecipacao={possuiAntecipacao} setPossuiAntecipacao={setPossuiAntecipacao} />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={7} className="hgCard">
                    <ResumoVenda totais={totais} setTotais={setTotais} formulario={formulario} permiteEditarDescontoAcrescimo={permiteEdicaoDescontoAcrescimo} frete={frete} />
                </Col>
            </Row>
            <FormasPagamento cliente={cliente} totais={totais} setTotais={setTotais} listaPagamentos={listaPagamentos} setListaPagamentos={setListaPagamentos} formulario={formulario} pagamentoTotal={pagamentoTotal} setPagamentoTotal={setPagamentoTotal} abreModalAntecipacao={abreModalAntecipacao} setAbreModalAntecipacao={setAbreModalAntecipacao} dadosAntecipacao={dadosAntecipacao} setDadosAntecipacao={setDadosAntecipacao} possuiAntecipacao={possuiAntecipacao} setPossuiAntecipacao={setPossuiAntecipacao} />
            <Row className="m-t-8" gutter={[0, 8]}>
                <Col>
                    <Button onClick={() => { verObservacao() }}>
                        {observacaoPagamento ? <ArrowUpOutlined /> : <ArrowDownOutlined />}  Para informar uma Observação, Clique Aqui.
                    </Button>
                </Col>
                {!observacaoPagamento === false &&
                    <Col span={24}>
                        <Form.Item label="Observação" name="observacao">
                            <Input.TextArea placeholder="Informe uma Observação" />
                        </Form.Item>
                    </Col>
                }
            </Row>
            {totais.valorPago > 0 &&
                <Row align="middle" justify="end" gutter={[8, 0]} className="col-buttons m-t-16">
                    <Col className="f-16">
                        Valor Pago (R$):
                    </Col>
                    <Col>
                        <b className="f-21">{FormatNumber(!!totais.valorPago ? totais.valorPago : 0, true)}</b>
                    </Col>
                </Row>
            }
            {(parseFloat(totais.valorPago) > parseFloat(totais.valorTotal)) &&
                <Row align="middle" justify="end" gutter={[8, 0]} className="col-buttons">
                    <Col className="f-16">
                        Troco (R$):
                    </Col>
                    <Col>
                        <b className="f-21">{FormatNumber(parseFloat(totais.valorPago) - parseFloat(totais.valorTotal), true)}</b>
                    </Col>
                </Row>
            }
            {totais.restaPagar > 0 &&
                <Row align="middle" justify="end" gutter={[8, 0]} className="col-buttons c-red">
                    <Col className="f-16">
                        Restante a Pagar:
                    </Col>
                    <Col>
                        <b className="f-21">{FormatNumber(!!totais.restaPagar ? totais.restaPagar : !!totais.valorTotal ? totais.valorTotal : 0, true)}</b>
                    </Col>
                </Row>
            }
            <Row justify="space-between" className="col-buttons-fixed">
                <Col span={6}>
                    <Link to="/venda">
                        <Button size="large" icon={<ArrowLeftOutlined />} onClick={() => voltarVenda()} >
                            Voltar<br />
                            <small>para os Itens</small>
                        </Button>
                    </Link>
                </Col>
                <Col span={18}>
                    <Row justify="end" gutter={[8, 0]}>
                        <Col>
                            <Button onClick={() => cancelarVenda()} size="large" icon={<CloseOutlined />}>
                                Cancelar Venda&nbsp;<span style={{ fontSize: '10px' }}>(F8)</span>
                            </Button>
                        </Col>
                        <Col>
                            <Button type="primary" size="large" disabled={totais.restaPagar > 0} loading={carregando} icon={<CheckOutlined />} onClick={() => modalImprimirCupom()}>
                                Concluir Venda&nbsp;<span style={{ fontSize: '10px' }}>(PageDown)</span>
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </FormGW>
    );
}