import React, { useState, useEffect } from "react";
import { Row, Col, Form, Button, Card, Tag, Select, notification, Modal } from "antd";
import { CloseOutlined, CheckOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import CpfCnpj from "@react-br-forms/cpf-cnpj-mask";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";

import api from "../../services/api";
import { useStateValue } from "../../state";
import { FormatNumber } from "../../ValueObjects";
import { Data, BreadcrumbPage, SelectPaginacao } from "../../components";
import DrawerDevolucaoCliente from "./drawerDevolucaoCliente";
import { ExibirMaisInformacoesCliente, TabelaNota, TabelaFormaPagamento } from './pages';
import { carregarDadosPdv, isObjetoDiffVazio, novaAbaNavegador, openPdfNewTab, validarCnpj, validarCpf } from "../../services/funcoes";
import { getLocal } from "../../services/auth";
import { dadosPdvActions } from "../../actions";

export default function DevolucaoVenda() {

    const [{ manutencao, dadosPdv }, dispatch] = useStateValue();
    const [form] = Form.useForm();
    const [formCliente] = Form.useForm();
    const [open, setOpen] = useState(false);
    const [cpfCnpj, setCpfCnpj] = useState(null);
    const [pessoaJuridica, setPessoaJuridica] = useState(false);
    const [carregando, setCarregando] = useState(false);
    const [abrirDrawerDevCliente, setAbrirDrawerDevCliente] = useState(false);
    const [dadosNfceDevolucao, setDadosNfceDevolucao] = useState({});
    const [listaNotaDevolucao, setListaNotaDevolucao] = useState([]);
    const [operacaoFiscal, setOperacaoFiscal] = useState({});
    const [docFiscal, setDocFiscal] = useState(false);
    const [mask, setMask] = useState("");
    const navigation = useNavigate();

    const [dadosNotaDevSt, setDadosNotaDevSt] = useState({
        valorIcmsDev: 0,
        valorBaseIcmsDev: 0,
        valorIcmsStDev: 0,
        valorBaseIcmsStDev: 0
    });

    useEffect(() => {
        if (!!!dadosPdv.pdv) {
            verificaPdv();
        }
    }, [])

    async function verificaPdv() {
        let res = await carregarDadosPdv();
        dispatch({ type: dadosPdvActions.CHANGE, data: { pdv: res } });
    }

    useEffect(() => {
        if (!!manutencao.dados && !!manutencao.dados.dadosCliente) {
            form.setFieldsValue({ ntf_cnpjcpfdest: !!manutencao.dados.dadosCliente.pef_cpf ? manutencao.dados.dadosCliente.pef_cpf : manutencao.dados.dadosCliente.pej_cnpj });
        }
    }, [manutencao])

    useEffect(() => {
        if (!!manutencao.dados && !!manutencao.dados.dadosNfceDevolucao) {
            carregarDados(manutencao.dados.dadosNfceDevolucao);
            form.setFieldsValue({ ntf_cnpjcpfdest: !!manutencao.dados.dadosNfceDevolucao.ntf_cnpjcpfdest ? manutencao.dados.dadosNfceDevolucao.ntf_cnpjcpfdest : null });
            setDocFiscal(manutencao.dados.dadosNfceDevolucao.ntf_docfiscal);
        }
        form.setFieldsValue({ ntf_dataemissao: moment(new Date()) });
    }, [])

    //area carregar listas/dados

    function carregarDados(dados) {
        if (dados.ntf_cnpjcpfdest) {
            let cpfCnpj = dados.ntf_cnpjcpfdest.replaceAll('/', '').replaceAll('.', '').replaceAll('-', '');
            if (cpfCnpj.length === 11) {
                validarCpfExiste(cpfCnpj);
            } else if (cpfCnpj.length === 14) {
                validarCnpjExiste(cpfCnpj)
            }
        }
        let listaNfeSelecionada = [];
        listaNotaDevolucao.forEach((nota) => {
            listaNfeSelecionada.push(nota.ntf_id);
        })
        api.get(`NotaFiscalDevolucao/ListarNotaFiscalDevolucao?idNotaFiscal=${dados.ntf_id}`).then(
            res => {
                popularListaNotaDevolucao(res.data);
            }
        ).catch(
            error => {
                console.log(error);
            }
        )
        if (!!dados.ntf_cnpjcpfdest) {
            form.setFieldsValue({ ntf_cnpjcpfdest: dados.ntf_cnpjcpfdest });
        }
    }

    async function popularOperacaoFiscal(id) {
        if (!!id) {
            api.get(`OperacaoFiscal/Buscar/${id}`).then(
                res => {
                    if (res.status === 200) {
                        setOperacaoFiscal(res.data);
                    }
                }
            ).catch(
                error => { console.log(error); }
            )
        }
    };

    function popularListaNotaDevolucao(dadosTemp) {
        let dadosNfeDevolucao;
        api.get(`dadosContribuinte/Buscar`).then(
            res => {
                dadosNfeDevolucao = { ...dadosTemp };
                dadosNfeDevolucao.dadosContribuinte = res.data[0];
                dadosNfeDevolucao.ntf_status = 1;
                dadosNfeDevolucao.ntf_dataemissao = new Date();
                dadosNfeDevolucao.ntf_datainclusao = new Date();
                dadosNfeDevolucao.ntf_dhsaient = new Date();
                dadosNfeDevolucao.dadosDestinatario = {};
                dadosNfeDevolucao.dadosDestinatario.serieFiscal = {};
                dadosNfeDevolucao.dadosDestinatario.endereco = {};
                dadosNfeDevolucao.dadosDestinatario.endereco.cep = dadosNfeDevolucao.ntf_cepdest;
                dadosNfeDevolucao.dadosDestinatario.endereco.cidade = dadosNfeDevolucao.ntf_municipiodest;
                dadosNfeDevolucao.dadosDestinatario.endereco.bairro = dadosNfeDevolucao.ntf_bairrodest;
                dadosNfeDevolucao.dadosDestinatario.endereco.enderecoDistinatario = dadosNfeDevolucao.ntf_logradourodest;
                dadosNfeDevolucao.dadosDestinatario.endereco.numero = dadosNfeDevolucao.ntf_numeroenderecodest;
                dadosNfeDevolucao.dadosDestinatario.endereco.uf = dadosNfeDevolucao.ntf_ufdest;
                dadosNfeDevolucao.dadosDestinatario.endereco.ibge = dadosNfeDevolucao.ntf_codigoibgemundest;
                dadosNfeDevolucao.dadosDestinatario.endereco.pais = dadosNfeDevolucao.ntf_paisdest;
                dadosNfeDevolucao.dadosDestinatario.endereco.cid_id = dadosNfeDevolucao.cid_id;
                dadosNfeDevolucao.dadosDestinatario.telefone = {};
                dadosNfeDevolucao.dadosDestinatario.telefone.psc_numero = dadosNfeDevolucao.ntf_telefonedest;
                dadosNfeDevolucao.dadosDestinatario.pes_id = {};
                dadosNfeDevolucao.dadosDestinatario.pes_id.pes_id = dadosNfeDevolucao.pes_id;
                dadosNfeDevolucao.dadosDestinatario.pes_id.pes_nome = dadosNfeDevolucao.pes_nome;
                dadosNfeDevolucao.dadosDestinatario.pes_id.cli_regimetributariofiscal = dadosNfeDevolucao.cli_regimetributariofiscal;
                dadosNfeDevolucao.dadosDestinatario.pes_id.for_regimetributariofiscal = dadosNfeDevolucao.for_regimetributariofiscal;
                dadosNfeDevolucao.dadosDestinatario.pes_id.pes_fisicajuridica = dadosNfeDevolucao.for_regimetributariofiscal;
                if (dadosNfeDevolucao.dadosDestinatario.pes_id.pes_fisicajuridica === 2) {
                    if (!!dadosNfeDevolucao.cli_regimetributariofiscal) {
                        dadosNfeDevolucao.dadosDestinatario.pes_id.cli_regimetributariofiscal = !!dadosNfeDevolucao.cli_regimetributariofiscal.key ? dadosNfeDevolucao.cli_regimetributariofiscal.key : dadosNfeDevolucao.cli_regimetributariofiscal;
                    }
                    if (!!dadosNfeDevolucao.for_regimetributariofiscal) {
                        dadosNfeDevolucao.dadosDestinatario.pes_id.for_regimetributariofiscal = !!dadosNfeDevolucao.for_regimetributariofiscal.key ? dadosNfeDevolucao.for_regimetributariofiscal.key : dadosNfeDevolucao.for_regimetributariofiscal;
                    }
                }
                dadosNfeDevolucao.dadosDestinatario.pes_id.pej_inscricaoestadual = dadosNfeDevolucao.ntf_iedest;
                dadosNfeDevolucao.dadosDestinatario.pes_id.pef_cpf = dadosNfeDevolucao.pef_cpf;
                dadosNfeDevolucao.dadosDestinatario.pes_id.pej_cnpj = dadosNfeDevolucao.pej_cnpj;
                setDadosNfceDevolucao(dadosNfeDevolucao);
                carregarDadosNota(dadosNfeDevolucao);
            }
        ).catch(
            error => {
                console.log(error);
            }
        )
    }

    async function carregarDadosNota(dadosNota) {
        let notasReferenciadas = [];
        let dadosNotaDev = { ...dadosNotaDevSt };
        let dadosNotaReferenciada = {};
        dadosNotaReferenciada.ntf_id = dadosNota.ntf_id;
        dadosNotaReferenciada.ntf_numero = dadosNota.ntf_numero;
        dadosNotaReferenciada.pes_nome = dadosNota.pes_nome;
        dadosNotaReferenciada.ntf_chavenfe = dadosNota.ntf_chavenfe;
        dadosNotaReferenciada.ntf_dataemissao = dadosNota.ntf_dataemissao;
        dadosNotaReferenciada.ntf_id = dadosNota.ntf_id;
        dadosNotaReferenciada.ntf_status = dadosNota.ntf_status;
        dadosNotaReferenciada.ntf_tipooperacao = dadosNota.ntf_tipooperacao;
        dadosNotaReferenciada.ntf_finalidade = dadosNota.ntf_finalidade;
        dadosNotaReferenciada.totalNfe = 0;
        dadosNotaReferenciada.ser_id = dadosNota.ser_id;
        dadosNotaReferenciada.ser_serie = dadosNota.ser_serie;

        if (dadosNota.ntf_finalidade === 1) {
            dadosNotaReferenciada.ntf_finalidadeNfe = 'NF-e Normal';
        } else if (dadosNota.ntf_finalidade === 2) {
            dadosNotaReferenciada.ntf_finalidadeNfe = 'NF-e Complementar';
        } else if (dadosNota.ntf_finalidade === 3) {
            dadosNotaReferenciada.ntf_finalidadeNfe = 'NF-e de Ajuste'
        } else if (dadosNota.ntf_finalidade === 4) {
            dadosNotaReferenciada.ntf_finalidadeNfe = 'Devolução'
        } else {
            dadosNotaReferenciada.ntf_finalidadeNfe = '';
        }

        if (dadosNota.ntf_tipooperacao === '0') {
            dadosNotaReferenciada.tip_descricao = 'Entrada'
        } else {
            dadosNotaReferenciada.tip_descricao = 'Saída'
        }
        dadosNotaReferenciada.itensNf = [];
        let referenciada = {};
        for (const item of dadosNota.itensNf) {
            item.remover = false;
            dadosNotaReferenciada.totalNfe += (item.nfi_valorFrete + item.nfi_valorseguro + (item.npi_valoripi + item.nig_valoricmsst) + ((item.nfi_qtde * item.nfi_valorunitario) - item.nfi_valordesconto));
            //item.qtdeOriginal = item.qtdeRestante;
            item.nfectereferenciadaitens = [];
            referenciada = {
                ntf_id: item.ntf_id,
                nfi_id_origem: item.nfi_id
            }
            item.nig_percreducaobc = item.nig_percreducaobc ?? 0;
            item.nig_valorbc = item.nig_valorbc ?? 0;
            item.nig_percicms = item.nig_percicms ?? 0;
            item.nig_valoricms = item.nig_valoricms ?? 0;
            item.nig_percreducaobcst = item.nig_percreducaobcst ?? 0;
            item.nig_valorbcst = item.nig_valorbcst ?? 0;
            item.nig_percmvast = item.nig_percmvast ?? 0;
            item.nig_valoricmsst = item.nig_valoricmsst ?? 0;
            item.nfi_valordesconto = item.nfi_valordesconto ?? 0;
            item.nfi_valorfrete = item.nfi_valorfrete ?? 0;
            item.nfi_valoroutros = item.nfi_valoroutros ?? 0;
            item.npi_valorbcipi = item.npi_valorbcipi ?? 0;
            item.npi_percaliquotaipi = item.npi_percaliquotaipi ?? 0;
            item.npi_valoripi = item.npi_valoripi ?? 0;

            if (item.nig_cst === '10' || item.nig_cst === '30' || item.nig_cst === '70') {
                dadosNotaDev.valorIcmsDev += item.nig_valoricms;
                dadosNotaDev.valorBaseIcmsDev += item.nig_valorbc;
                dadosNotaDev.valorIcmsStDev += item.nig_valoricmsst;
                dadosNotaDev.valorBaseIcmsStDev += item.nig_valorbcst;
            }
            dadosNotaDev.ntf_dataemissao = new Date();
            item.nfectereferenciadaitens.push(referenciada);
            dadosNotaReferenciada.itensNf.push(item);
            setDadosNotaDevSt(dadosNotaDev);
            dadosNotaReferenciada.dadosDestinatario = dadosNota.dadosDestinatario;
            dadosNotaReferenciada.dadosContribuinte = dadosNota.dadosContribuinte;
            notasReferenciadas.push(dadosNotaReferenciada);
            setListaNotaDevolucao(notasReferenciadas);
        }
    };

    //area de verificacao cpf/cnpj

    function validarFisicoJuridico(event) {
        let valorCampo = !!event.currentTarget ? event.currentTarget.value.replace(/\D/g, '') : event.replace(/\D/g, '');
        if (valorCampo.length === 11) {
            if (!validarCpf(valorCampo)) {
                notification.warning({ message: 'Aviso', description: 'CPF informado é inválido' });
                return false;
            }
        } else if (valorCampo.length === 14) {
            if (!validarCnpj(valorCampo)) {
                notification.warning({ message: 'Aviso', description: 'CNPJ informado é inválido' });
                return false;
            }
        }
        setPessoaJuridica(valorCampo.length === 14);
    };

    function validarCpfExiste(cpfCnpj) {
        api.get(`PessoaFisica/VerificarCpfExistente?cpf=${cpfCnpj}`).then(
            res => {
                if (!res.data) {
                    Modal.confirm({
                        title: 'Aviso',
                        icon: <ExclamationCircleOutlined />,
                        content: "O CPF informado não está cadastrado no sistema!\n Deseja cadastrar um novo Cliente?",
                        okText: 'Sim',
                        cancelText: 'Não',
                        okType: 'danger',
                        centered: true,
                        onOk() {
                            let b = manutencao.dados;
                            formCliente.setFieldsValue({ cpfCnpj: cpfCnpj });
                            setAbrirDrawerDevCliente(true)
                        }
                    });
                }
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        )
    };

    function validarCnpjExiste(cpfCnpj) {
        api.get(`PessoaJuridica/VerificarCnpjExistente?cnpj=${cpfCnpj}`).then(
            res => {
                if (!res.data) {
                    Modal.confirm({
                        title: 'Aviso',
                        icon: <ExclamationCircleOutlined />,
                        content: "O CNPJ informado não está cadastrado no sistema!\n Deseja cadastrar um novo Cliente?",
                        okText: 'Sim',
                        cancelText: 'Não',
                        okType: 'danger',
                        centered: true,
                        onOk() {
                            formCliente.setFieldsValue({ ntf_cnpjcpfdest: cpfCnpj });
                            setAbrirDrawerDevCliente(true);
                        }
                    });
                }
            }
        ).catch(
            erro => {
                console.log(erro);
                notification.warning({ message: 'Aviso!', description: 'Erro ao tentar fazer a devolução' });
            }
        )
    };

    async function verificaAntecipacao() {
        let values = { ...dadosNfceDevolucao };
        let dadosCliente = form.getFieldValue();

        const camposObrigatorios = [
            'log_logradouro',
            'bai_nome',
            'cep_cep',
            'tpe_id',
            'pee_numero',
        ]
        const todosCamposPreenchidos = camposObrigatorios.every(campo => !!dadosCliente[campo]);
        if (todosCamposPreenchidos === false) {
            notification.warning({ message: 'Aviso!', description: 'Preencher as informações do Endereço' });
            return;
        }

        dadosCliente.enderecos = dadosCliente.enderecos || [];
        if (dadosCliente.enderecos.length === 0) {
            dadosCliente.enderecos.push({
                log_logradouro: dadosCliente.log_logradouro,
                bai_nome: dadosCliente.bai_nome,
                cep_cep: dadosCliente.cep_cep,
                pee_numero: dadosCliente.pee_numero || '',
                pee_complemento: dadosCliente.pee_complemento || '',
                pse_id: 33,
                tpe_id: dadosCliente.tpe_id,
                pee_enderecoprincipal: dadosCliente.pee_enderecoprincipal !== undefined ? dadosCliente.pee_enderecoprincipal : true,
                pee_ativo: dadosCliente.pee_ativo !== undefined ? dadosCliente.pee_ativo : true,
                cid_descricao: dadosCliente.cid_descricao
            });
        }
        dadosCliente.pessoatelefones = dadosCliente.pessoatelefones || [];
        if (dadosCliente.pessoatelefones.length === 0) {
            dadosCliente.pessoatelefones.push({
                pes_id: dadosCliente.pes_id,
                psc_ativo: dadosCliente.psc_ativo !== undefined ? dadosCliente.psc_ativo : true,
                psc_id: dadosCliente.psc_id,
                psc_numero: dadosCliente.psc_numero,
                psc_observacao: dadosCliente.psc_observacao,
                psc_principal: dadosCliente.psc_principal !== undefined ? dadosCliente.psc_principal : true,
                psc_ramal: dadosCliente.psc_ramal,
                tpt_descricao: dadosCliente.tpt_descricao,
                tpt_icone: dadosCliente.tpt_icone,
                tpt_iconecor: dadosCliente.tpt_iconecor,
                tpt_id: dadosCliente.tpt_id
            })
        }
        dadosCliente.pessoaemails = dadosCliente.pessoaemails || [];
        if (dadosCliente.pessoaemails.length === 0) {
            dadosCliente.pessoaemails.push({
                pem_ativo: dadosCliente.pem_ativo !== undefined ? dadosCliente.pem_ativo : true,
                pem_email: dadosCliente.pem_email,
                pem_emailprincipal: dadosCliente.pem_emailprincipal !== undefined ? dadosCliente.pem_emailprincipal : true,
                pem_enviarboleto: dadosCliente.pem_enviarboleto,
                pem_enviarcobranca: dadosCliente.pem_enviarcobranca,
                pem_enviarnfe: dadosCliente.pem_enviarnfe,
                pem_enviarpromocao: dadosCliente.pem_enviarpromocao,
                pem_enviarvencparcela: dadosCliente.pem_enviarvencparcela,
                pem_id: dadosCliente.pem_id,
                pes_id: dadosCliente.pes_id
            })
        }
        const camposDelet = [
            'log_logradouro', 'bai_nome', 'cep_cep', 'pee_numero', 'pee_complemento', 'tpe_id', 'cid_descricao', 'pes_id', 'psc_ativo', 'psc_id', 'psc_numero',
            'psc_observacao', 'psc_principal', 'psc_ramal', 'tpt_descricao', 'tpt_icone', 'tpt_iconecor', 'tpt_id', 'pem_ativo', 'pem_email', 'pem_emailprincipal', 
            'pem_enviarboleto', 'pem_enviarcobranca', 'pem_enviarnfe', 'pem_enviarpromocao', 'pem_enviarvencparcela', 'pem_id', 'pes_id'
        ];
        camposDelet.forEach(campo => {
            delete dadosCliente[campo];
        });

        let dadosEditar = { ...values, ...dadosCliente}
        api.put(`Cliente/Editar`, dadosEditar)

        Modal.confirm({
            title: 'Deseja gerar uma antecipação/crédito do valor desta venda para o cliente?',
            cancelText: 'Não, vou devolver o dinheiro',
            okText: 'Sim, quero gerar o crédito',
            okType: 'danger',
            centered: true,
            icon: <ExclamationCircleOutlined />,
            onOk: () => {
                values.gerarAntecipacao = true;
                emitirNotaDevolucao(values);
            },
            onCancel: () => {
                values.gerarAntecipacao = false;
                emitirNotaDevolucao(values);
            }
        });
    }

    //fim da area cpf/cnpj

    //area emissão nota 

    async function emitirNotaDevolucao(values) {
        debugger
        let notaDevolucaoFinal = {};
        notaDevolucaoFinal.valorFrete = 0;
        notaDevolucaoFinal.valorSeguro = 0;
        notaDevolucaoFinal.valorDesconto = 0;
        notaDevolucaoFinal.valorAcrescimo = 0;
        notaDevolucaoFinal.dadosContribuinte = !!values.dadosContribuinte ? values.dadosContribuinte : [];
        notaDevolucaoFinal.notaFiscalFaturamento = values.formaPgto ? values.formaPgto : [];
        notaDevolucaoFinal.ntf_numero = values.ntf_numero;
        notaDevolucaoFinal.ntf_dataemissao = form.getFieldValue().ntf_dataemissao;
        notaDevolucaoFinal.ntf_tipooperacao = (values.ntf_tipooperacao === 0 ? 1 : 0);
        notaDevolucaoFinal.pdv_id = dadosPdv.pdv?.pdv_id;
        notaDevolucaoFinal.ctc_id = dadosPdv.pdv?.ctc_id;
        notaDevolucaoFinal.ntf_modalidadefrete = values.ntf_modalidadefrete;
        notaDevolucaoFinal.dadosTransportador = {};
        notaDevolucaoFinal.gerarAntecipacao = values.gerarAntecipacao;
        notaDevolucaoFinal.ntf_docfiscal = !!form.getFieldValue().ser_id ? true : values.ntf_docfiscal;
        notaDevolucaoFinal.ope_id = form.getFieldValue().ope_id;

        notaDevolucaoFinal.notafiscalitens = await tratarDadosItens(values.itensNf, values.ntf_id);
        notaDevolucaoFinal.notaFiscalDestinatario = await tratarDadosDestinatario();

        notaDevolucaoFinal.dadosTransportador = {};
        notaDevolucaoFinal.notaFiscalFaturamento = values.formaPgto ? values.formaPgto : [];
        notaDevolucaoFinal.documentosReferenciados = [];
        let dadosReferenciada = {
            ntf_chavenfe: values.ntf_chavenfe,
            ntf_id: values.ntf_id
        }
        notaDevolucaoFinal.documentosReferenciados.push(dadosReferenciada);

        notaDevolucaoFinal.notaFiscalTransportadora = {
            ntf_modalidadefrete: 9,
            ntf_dhsaient: moment(new Date())
        };
        if (!!form.getFieldValue().ser_id) {
            notaDevolucaoFinal.ser_id = form.getFieldValue().ser_id;
        }

        setCarregando(true);
        api.post(`NotaFiscalDevolucao/IncluirDevolucao`, notaDevolucaoFinal).then(
            res => {
                if (operacaoFiscal?.ope_gerarespelho == true) {
                    openPdfNewTab(`NotaFiscal/GerarEspelhoDanfeNFe?IdNotaFiscal=${res.data.ntf_id}`)
                } else {
                    if (res.data.ntf_status === 1 && !!res.data.ntf_chavenfe) {
                        if (notaDevolucaoFinal.ntf_docfiscal) {
                            novaAbaNavegador(`Danfe/GerarDanfe?chaveNfe=${res.data.ntf_chavenfe}`);
                        }
                    }
                }
                notification.success({ message: 'Sucesso!', description: 'Nota Fiscal devolvida com sucesso!' });
                navigation("../vendaRealizada");
            }
        ).catch(
            error => {
                console.log(error);
                notification.warning({ message: 'Error!!!', description: error.response.data.Message });
            }
        ).finally(
            () => {
                setCarregando(false);
            }
        )
    }

    //#region tratamento de dados

    async function tratarDadosDestinatario() {

        debugger
        let cpfCnpj = form.getFieldValue().ntf_cnpjcpfdest.replaceAll('-', '').replaceAll('.', '').replaceAll('/', '');

        //pesquisa se tem cliente cadastrado com o cpf/cnpj informado, se não gera um novo
        try {
            let dadosCliente = (await api.get(`Cliente/BuscarClienteCpfCnpj/${cpfCnpj}`)).data;
            let endereco = dadosCliente?.enderecos?.filter((item) => (item.pee_enderecoprincipal === true || item.pee_ativo === true))[0] ?? {};
            let telefone = dadosCliente?.telefones?.filter((item) => (item.psc_principal === true || item.psc_ativo))[0] ?? {};
            let email = dadosCliente?.emails?.filter((item) => (item.pem_emailprincipal === true || item.pem_ativo === true))[0] ?? {};
            let notaFiscalDestinatario = {
                pes_id: dadosCliente.pes_id,
                pes_fisicajuridica: cpfCnpj.length > 11 ? 1 : 2,
                ntf_nomedest: dadosCliente.pes_nome,
                ntf_cnpjcpfdest: cpfCnpj,
                ntf_iedest: !!dadosCliente.pes_fisicajuridica === 2 ? dadosCliente.pej_inscricaoestadual : '',
                ntf_idestrangeirodest: dadosCliente.pej_inscricaoestadual,
                ntf_logradourodest: endereco.log_logradouro,
                ntf_numeroenderecodest: endereco.numero,
                ntf_complementodest: endereco.complemento,
                ntf_bairrodest: endereco.bai_nome,
                ntf_cepdest: endereco.cep_cep,
                ntf_ufdest: !!endereco.est_sigla ? endereco.est_sigla : '',
                ntf_telefonedest: telefone?.psc_numero,
                ntf_emaildest: email?.pem_email
            }
            return notaFiscalDestinatario;
        } catch {
            let dadosFormularioCliente = form.getFieldValue();
            let endereco = dadosFormularioCliente?.enderecos[0];
            let telefone = dadosFormularioCliente?.pessoatelefones[0];
            let email = dadosFormularioCliente?.pessoaemails[0];
            let notaFiscalDestinatario = {
                pes_id: 0,
                pse_id: endereco.pse_id,
                pes_fisicajuridica: cpfCnpj.length > 11 ? 1 : 2,
                ntf_nomedest: dadosFormularioCliente.pes_nome,
                ntf_cnpjcpfdest: cpfCnpj,
                ntf_logradourodest: endereco.log_logradouro,
                ntf_numeroenderecodest: endereco.pee_numero,
                ntf_complementodest: endereco.pee_complemento,
                ntf_bairrodest: endereco.bai_nome,
                ntf_cepdest: endereco.cep_cep,
                ntf_ufdest: endereco.est_sigla,
                ntf_telefonedest: telefone.psc_numero,
                ntf_emaildest: email?.pem_email ?? ''
            }
            return notaFiscalDestinatario;
        }
    }

    async function tratarDadosItens(itensNota, idNota) {
        let numeroItem = 1;
        let notafiscalitens = [];
        for (const item of itensNota) {
            item.ntf_id = idNota;
            item.nig_valorbcst = item.nig_valorbcst ?? 0;
            item.nig_valoricmsst = item.nig_valoricmsst ?? 0;
            item.nig_percicmsst = item.nig_percicmsst ?? 0;
            item.nig_percmvast = item.nig_percmvast ?? 0;
            item.nig_percreducaobc = item.nig_percreducaobc ?? 0;
            item.nig_percreducaobcst = item.nig_percreducaobcst ?? 0;
            item.nis_percreducaobcicms = item.nis_percreducaobcicms ?? 0;
            item.nis_valoricms = item.nis_valoricms ?? 0;
            item.nfi_numeroitem = numeroItem;
            item.nfi_qtde = item.qtdeRestante;
            notafiscalitens.push(item);
            numeroItem++;
        }
        return notafiscalitens;
    }

    //#endregion

    //fim area emissão nota


    function fecharDrawerCliente() {
        let valoresForm = formCliente.getFieldsValue();
        let endereco = {};
        let telefone = {};
        let email = {};
        if (!!manutencao.dados && !!manutencao.dados.enderecos && !!manutencao.dados.telefones) {
            endereco = manutencao.dados.enderecos.length > 0 ? manutencao.dados.enderecos.filter((item) => (item.pee_enderecoprincipal === true))[0] : {};
            telefone = manutencao.dados.telefones.length > 0 ? manutencao.dados.telefones.filter((item) => (item.psc_principal === true))[0] : {};
            if (!!manutencao.dados.emails && manutencao.dados.emails.length > 0) {
                email = manutencao.dados.emails.filter((item) => (item.pem_emailprincipal === true))[0];
            }
            form.setFieldsValue({ ...valoresForm, ...endereco, ...telefone, ...email });
        }
        setAbrirDrawerDevCliente(false);
    }


    return (
        <Form layout="vertical" name="formularioDevolucao" form={form} onFinish={verificaAntecipacao}>
            <BreadcrumbPage pagina="Venda" pagina_seleciona="Devolução Venda" />
            <Card className="m-t-16">
                <Row gutter={[8, 0]} justify="space-between">
                    <Col>
                        <Tag color={((dadosNfceDevolucao.status === 0) ? 'default' : (dadosNfceDevolucao.status === 1 ? 'green' : (dadosNfceDevolucao.status === 2 ? 'gold' : (dadosNfceDevolucao.status === 3 ? 'orange' : (dadosNfceDevolucao.status === 4 ? 'red' : (dadosNfceDevolucao.status === 5 ? 'lime' : ''))))))}>
                            <b>{dadosNfceDevolucao.status}Autorizada</b>
                        </Tag>
                    </Col>
                    <Col>
                        <b>{dadosNfceDevolucao.ntf_numero}</b>
                    </Col>
                    <Col>
                        Série: <b>{dadosNfceDevolucao.ser_numero ? dadosNfceDevolucao.ser_numero : 'Não Informado'}</b>
                    </Col>
                    <Col>
                        Emissão: <b>{moment(dadosNfceDevolucao.ntf_dataemissao).format('DD/MM/YYYY')}</b>
                    </Col>
                    <Col>
                        Total da Nota: <b>{FormatNumber(dadosNfceDevolucao.valortotalnota, true)}</b>
                    </Col>
                    <Col>
                        NF-e: <b>{dadosNfceDevolucao?.ntf_chavenfe ?? 'Não Informado'}</b>
                    </Col>
                </Row>
            </Card>
            <Row gutter={[8, 0]} justify="center" className="m-t-16">
                <Col xs={24} sm={24} md={8} lg={6} xl={6} xxl={6}>
                    <Form.Item label="Localize uma Série" name="ser_id" rules={[{ required: docFiscal, message: 'Seleciona uma Série Fiscal!' }]}>
                        <SelectPaginacao url="SerieFiscal/Listar?CodigoModeloDocFiscal=55&EmitenteDocumento=0"
                            nameLabel="ser_serie"
                            nameValue="ser_id"
                            form={form}
                            allowClear={true}
                            placeholder="Selecione uma Série"
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8} lg={5} xl={4} xxl={3}>
                    <Form.Item label="CPF/CNPJ do Cliente" name="ntf_cnpjcpfdest" rules={[{ required: true, message: 'Informe o CPF/CNPJ' }]}>
                        <CpfCnpj className='inputCpfCnpj'
                            placeholder="Informe o CPF ou CNPJ"
                            id="cpf"
                            value={cpfCnpj}
                            onBlur={(event, type) => {
                                setCpfCnpj(event.target.value);
                                setMask(type === "CPF");
                                validarFisicoJuridico(event.target.value);
                            }} />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8} lg={6} xl={6} xxl={6}>
                    <Form.Item label="Localize a Operação Fiscal" name="ope_id" rules={[{ required: true, message: 'Seleciona uma Operacao Fiscal!' }]}>
                        <SelectPaginacao url='OperacaoFiscal/ListarOperacaoFiscal?TipoOperacao=3'
                            nameLabel="ope_descricao"
                            nameValue="ope_id"
                            onChangeFunction={(idOperacao) => popularOperacaoFiscal(idOperacao)}
                            form={form}
                            placeholder="Selecione uma Operacao Fiscal"
                        />
                    </Form.Item>
                </Col>
            </Row>
            <ExibirMaisInformacoesCliente form={form} pesFisJur={pessoaJuridica} clienteCpfCnpj={!!dadosNfceDevolucao.ntf_cnpjcpfdest ? dadosNfceDevolucao.ntf_cnpjcpfdest : !!form.getFieldValue().ntf_cnpjcpfdest ? form.getFieldValue().ntf_cnpjcpfdest : ''} open={open} setOpen={setOpen} />
            <TabelaNota dadosNfceDevolucao={dadosNfceDevolucao} setDadosNfceDevolucao={setDadosNfceDevolucao} />
            <TabelaFormaPagamento formasPagamentos={dadosNfceDevolucao.formaPgto} />
            <Form name="formClienteDev" form={formCliente} layout="vertical">
                <DrawerDevolucaoCliente abrirDrawerDevCliente={abrirDrawerDevCliente} fecharDrawerDevCliente={() => fecharDrawerCliente()} formCliente={formCliente} />
            </Form>
            <Row justify="end" gutter={[8, 0]} className="ant-drawer-footer footer-recebimento">
                <Col>
                    <Link to="/vendaRealizada">
                        <Button size="large" icon={<CloseOutlined />} >
                            Cancelar
                        </Button>
                    </Link>
                </Col>
                <Col>
                    <Button type="primary" size="large" icon={<CheckOutlined />} loading={carregando} onClick={() => form.submit()}>
                        Emitir Nota
                    </Button>
                </Col>
            </Row>
        </Form>
    );
}