import React from 'react';
import { Select } from 'antd';
import { FormatNumber } from "../../../ValueObjects";
import SelectPaginacao from '../selectPaginacao';

export default function SelectProduto(props) {


  return (
    <SelectPaginacao
      url={"Produto/ListaProdutoFiltro"}
      form={props.form}
      nameValue={props.name}
      focus={props.focus}
      defaultOpen={props.defaultOpen}
      onChangeFunction={!!props.onChange ? props.onChange : () => { }}
      onBlurFunction={!!props.onBlur ? props.onBlur : () => { }}
      nameLabel={props.ordem}
      allowClear={!!props.allowClear ? props.allowClear : false}
      disabled={!!props.disabled}
      placeholder={!!props.placeholder ? props.placeholder : "Selecione um produto"}
      filtroPesq={props.filtroPesq}
      atualizaComponente={props.atualizaComponente}
      setListaDados={!!props.setListaDados ? props.setListaDados : null}
      selecionarRegUnico={!!props.selecionarRegUnico ? props.selecionarRegUnico : null}
      conteudo={(produto) => (
        <Select.Option value={!!props.valueKey ? produto.key : produto.pro_id} key={produto.key}
          label={`${produto.pro_codigo} - ${produto.pro_descricao}`}
        >
          <span style={{ width: '65%', maxWidth: '65%', display: 'inline-block', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            <strong>{produto.pro_codigo} - {produto.pro_descricao}</strong>
          </span>
          <span style={{ width: '20%', maxWidth: '20%', textAlign: 'right', display: 'inline-block', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            <strong>Vlr. Unitário:</strong>
          </span>
          <span style={{ width: '10%', maxWidth: '10%', textAlign: 'right', display: 'inline-block', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            <strong>{FormatNumber(produto.lpi_valorvenda, true)}</strong>
          </span>
          <div style={{ marginTop: "-25px"}}>
          {[
            (!!props.detalhes && <br key="br1" />),
            (!!props.detalhes && !!produto.descricaograde && `Grade: ${produto.descricaograde}`),
            (!!props.detalhes && (!!produto.descricaograde) && <br key="br2" />),
            (!!props.detalhes && (!!produto.pro_ean || !!produto.prg_ean) && (
              <>
                EAN/GTIN: <span style={{ width: '20%', display: 'inline-block' }}>{produto.pro_ean || produto.prg_ean}</span>
              </>
            )),
            (!!props.detalhes && ((!!produto.pro_ean || !!produto.prg_ean) && !!produto.pro_codigoetiqueta) && (
              <>
                Cód.Etiqueta: <span style={{ width: '20%', display: 'inline-block' }}>{produto.pro_codigoetiqueta}</span>
              </>
            )),
          ].filter(Boolean)}
          </div>
        </Select.Option>
      )}
    />
  )
}
